export default class MeaViewport {

    constructor(el) {

        this.el = $(el);
        this._init();

    }

    _init(){

        let observer = new IntersectionObserver((entries)=> {
            // isIntersecting is true when element and viewport are overlapping
            // isIntersecting is false when element and viewport don't overlap
            if(entries[0].isIntersecting === true){
                if(this.on_visible_callback){
                    this.on_visible_callback();
                }else{
                    //console.log('MeaViewport visible empty ',this);
                }
            }else{
                if(this.on_hidden_callback)
                    this.on_hidden_callback();
            }

        }, { threshold: [0] });

        observer.observe(this.el[0]);
        this.observer = observer;

    }

    disable(){
        this.observer.disconnect();
    }
    /**
     * @example  data-on-visible="this.el.css('background-color','red')"
     * @example show
     * @param callback
     */
    onVisible(callback){

        // console.log('set onVisible(callback)',this.el.data('id'),callback);
        this.on_visible_callback=callback;

        return this;

    }

    onHidden(callback){
        // console.log('set onHidden(callback)',this.el.data('id'),callback);
        this.on_hidden_callback=callback;

        return this;
    }

    //not work like expected - return not all elements
    onRemoved($el, callback){

        let id = $el.attr('id');

        if (!id){
            console.log('onRemoved fail setup - no id',$el)
            return false;
        }

        console.log('watching onRemoved',id);

        const observer = new MutationObserver((mutations_list)=> {
            mutations_list.forEach((mutation)=> {
                mutation.removedNodes.forEach((removed_node)=> {

                    if($(removed_node).attr('id') && id === $(removed_node).attr('id')){
                        console.log('end watching removing');
                        eval(callback);
                        observer.disconnect();
                    }else{
                         console.log('removed',$(removed_node).attr('id') );
                    }
                });
            });
        });

        observer.observe(document.querySelector("#meaContent"), {
            subtree: true, attributes: true, childList: true
        });

        return this;

    }

    static register(){

        $mea.types.add('viewport_observer',function(el){

             // console.log('viewport_observer',el);

            let viewport = new MeaViewport(el);

            let onVisible = el.data('on-visible');
            if(onVisible){
                switch (onVisible){
                    case 'show':
                        viewport.onVisible(()=>{
                            viewport.el.show();
                            // console.log('is visible ',viewport.el.data('id'));
                        });
                        break;

                    default:
                        viewport.onVisible(function(){
                            // console.log('fire visible ',{
                            //     id: viewport.el.data('id'),
                            //     callback: onVisible,
                            //     self: this
                            // });
                            eval(onVisible);
                        });
                        break;
                }
            }

            let onRemoved = el.data('on-removed');
            if(onRemoved){

                viewport.onRemoved($(el), ()=>{
                    console.log('fire onRemoved ',{
                        id: viewport.el.data('id'),
                        callback: onRemoved,
                        self: this
                    });
                    eval(onRemoved);
                });
            }

            let onHidden = el.data('on-hidden');
            if(onHidden){
                switch (onHidden){
                    case 'show':
                        viewport.onHidden(()=>{
                            // console.log('is hidden ',viewport.el.data('id'));
                        });
                        break;

                    default:
                        viewport.onHidden(()=>{
                            // console.log('fire hidden ',{
                            //     id: viewport.el.data('id'),
                            //     callback: onHidden,
                            //     self: this
                            // });
                            eval(onHidden);
                        });
                        break;
                }
            }


            // console.log(typeof el.data('on-hidden'));
            //
            // if(el.data('on-hidden')){
            //     console.log('on-hidden OK');
            //     let fn = el.data('on-hidden');
            //     eval(fn);
            // }

            // let amount = $(el).data('moneybase');
            // let out_currency = $(el).data('currency-out');
            // if(!amount && !out_currency){
            //     console.log('money_convert read data fail',{amount, out_currency});
            //     return ;
            // }
            // let out = $mea.money.convert(
            //     amount,'PLN',out_currency).toFixed(2)
            //
            // $(el).html(out);

            // console.log('money_convert',$(el).data('moneybase'));
        });

    }

}

$mea.class['MeaViewport'] = MeaViewport;