//todo use meaform when moved
//import MeaForm from "../../../vendor/Mea/CoreBundle/Resources/webpack/form/meaform";

$mea.types.add('mea-contact-input-email',(el)=>{
    let helper = new MeaContactFieldsHelper(
        $(el).closest('form')
    );
    $(el).on('input', e => {
        helper.validEmailAndLoadDataCompany(el);
    });
});
// /extends MeaForm
export class MeaContactFieldsHelper  {

    constructor(container) {
        // super(form );

        this.$container = $(container);

        if($(container).is('form')){
            this.$form = $(container);
        }else{
            this.$form = this.$container.find('form');
        }

        // console.log('MeaContactFieldsHelper');
    }
    getForm(){
        return this.$form;
    }

    find(selector){
        return this.getForm().find(selector);
    }

    validEmailRequireLogin(){

    }

    validEmailAndLoadDataCompany($el) {


        let pattern = /\S+@\S+\.\S+/;
        let uri = Routing.generate('MeaContact_dispatch')+',isEmailTaken';

        if (pattern.test($el.val())) {
            $.ajax({
                url: uri,
                data: {
                    value: $el.val(),
                    fields: 'id,companyName,address,city,postcode,firstLastName,companyPhone, userPhone,nip'
                },
                dataType: 'json',
                async: false,
                success: (data) => {
                    if (!data.valid) {

                        if($mea.messages.confirm('Company data was found by email address. Should I complete the data and assign the contract to an existing company?',()=>{
                            this.fillCustomer(data);
                        },()=>{
                            $mea.messages.add('In this situation, leave the email blank, it has been added to the comment. Our support will send the documents manually.');

                            this.find('[name="contract.comments"]').val($el.val());
                            this.find('[name="contract.signatory.email"]').val(null);

                        })){

                        }


                    }
                }
            });
        }

        return false;
    }

    fillCustomer(data){

        $mea.messages.add('Company details loaded from database');

        this.find('[data-field="companyName"]').val(data.companyName).trigger('change');
        this.find('[data-field="address"]').val(data.address).trigger('change');
        this.find('[data-field="companyPhone"]').val(data.companyPhone).trigger('change');
        this.find('[data-field="firstLastName"]').val(data.firstLastName).trigger('change');
        this.find('[data-field="userPhone"]').val(data.userPhone).trigger('change');
        this.find('[data-field="email"]').val(data.email);
        this.find('[data-field="companyId"]').val(data.id);


        // this.$form.append('<input type="hidden" id="contract.signatory.id" name="contract.signatory.id" value="'+data.id+'">');

    }
}