
import "bootstrap-fileinput";
import "bootstrap-fileinput/themes/explorer/theme.js";
import "uniform.js";

$.fn.fileinputLocales['pl'] = {
    fileSingle: 'plik',
    filePlural: 'pliki',
    browseLabel: 'Lub wybierz z dysku &hellip;',
    removeLabel: 'Usuń',
    removeTitle: 'Usuń zaznaczone pliki',
    cancelLabel: 'Przerwij',
    cancelTitle: 'Anuluj wysyłanie',
    pauseLabel: 'Wstrzymaj',
    pauseTitle: 'Wstrzymaj trwające przesyłanie',
    uploadLabel: 'Wgraj',
    uploadTitle: 'Wgraj zaznaczone pliki',
    msgNo: 'Nie',
    msgNoFilesSelected: 'Brak zaznaczonych plików',
    msgPaused: 'Wstrzymano',
    msgCancelled: 'Odwołany',
    msgPlaceholder: 'Wybierz {files} ...',
    msgZoomModalHeading: 'Szczegółowy podgląd',
    msgFileRequired: 'Musisz wybrać plik do wgrania.',
    msgSizeTooSmall: 'Plik "{name}" (<b>{size} KB</b>) jest zbyt mały i musi być większy niż <b>{minSize} KB</b>.',
    msgSizeTooLarge: 'Plik o nazwie "{name}" (<b>{size} KB</b>) przekroczył maksymalną dopuszczalną wielkość pliku wynoszącą <b>{maxSize} KB</b>.',
    msgFilesTooLess: 'Minimalna liczba plików do wgrania: <b>{n}</b>.',
    msgFilesTooMany: 'Liczba plików wybranych do wgrania w liczbie <b>({n})</b>, przekracza maksymalny dozwolony limit wynoszący <b>{m}</b>.',
    msgTotalFilesTooMany: 'Możesz wgrać maksymalnie <b>{m}</b> plików (wykryto <b>{n}</b>).',
    msgFileNotFound: 'Plik "{name}" nie istnieje!',
    msgFileSecured: 'Ustawienia zabezpieczeń uniemożliwiają odczyt pliku "{name}".',
    msgFileNotReadable: 'Plik "{name}" nie jest plikiem do odczytu.',
    msgFilePreviewAborted: 'Podgląd pliku "{name}" został przerwany.',
    msgFilePreviewError: 'Wystąpił błąd w czasie odczytu pliku "{name}".',
    msgInvalidFileName: 'Nieprawidłowe lub nieobsługiwane znaki w nazwie pliku "{name}".',
    msgInvalidFileType: 'Nieznany typ pliku "{name}". Tylko następujące rodzaje plików są dozwolone: "{types}".',
    msgInvalidFileExtension: 'Złe rozszerzenie dla pliku "{name}". Tylko następujące rozszerzenia plików są dozwolone: "{extensions}".',
    msgUploadAborted: 'Przesyłanie pliku zostało przerwane',
    msgUploadThreshold: 'Przetwarzanie &hellip;',
    msgUploadBegin: 'Rozpoczynanie &hellip;',
    msgUploadEnd: 'Gotowe!',
    msgUploadResume: 'Wznawianie przesyłania &hellip;',
    msgUploadEmpty: 'Brak poprawnych danych do przesłania.',
    msgUploadError: 'Błąd przesyłania',
    msgDeleteError: 'Błąd usuwania',
    msgProgressError: 'Błąd',
    msgValidationError: 'Błąd walidacji',
    msgLoading: 'Wczytywanie pliku {index} z {files} &hellip;',
    msgProgress: 'Wczytywanie pliku {index} z {files} - {name} - {percent}% zakończone.',
    msgSelected: '{n} Plików zaznaczonych',
    msgFoldersNotAllowed: 'Metodą przeciągnij i upuść, można przenosić tylko pliki. Pominięto {n} katalogów.',
    msgImageWidthSmall: 'Szerokość pliku obrazu "{name}" musi być co najmniej {size} px.',
    msgImageHeightSmall: 'Wysokość pliku obrazu "{name}" musi być co najmniej {size} px.',
    msgImageWidthLarge: 'Szerokość pliku obrazu "{name}" nie może przekraczać {size} px.',
    msgImageHeightLarge: 'Wysokość pliku obrazu "{name}" nie może przekraczać {size} px.',
    msgImageResizeError: 'Nie udało się uzyskać wymiaru obrazu, aby zmienić rozmiar.',
    msgImageResizeException: 'Błąd podczas zmiany rozmiaru obrazu.<pre>{errors}</pre>',
    msgAjaxError: 'Coś poczło nie tak podczas {operation}. Spróbuj ponownie!',
    msgAjaxProgressError: '{operation} nie powiodło się',
    msgDuplicateFile: 'Plik "{name}" o identycznym rozmiarze "{size} KB" został wgrany wcześniej. Pomijanie zduplikowanego pliku.',
    msgResumableUploadRetriesExceeded:  'Przekroczono limit <b>{max}</b> prób wgrania pliku <b>{file}</b>! Szczegóły błędu: <pre>{error}</pre>',
    msgPendingTime: 'Pozostało {time}',
    msgCalculatingTime: 'obliczanie pozostałego czasu',
    ajaxOperations: {
        deleteThumb: 'usuwanie pliku',
        uploadThumb: 'przesyłanie pliku',
        uploadBatch: 'masowe przesyłanie plików',
        uploadExtra: 'przesyłanie danych formularza'
    },
    dropZoneTitle: 'Przeciągnij i upuść pliki tutaj &hellip;',
    dropZoneClickTitle: '<br>(lub kliknij tutaj i wybierz {files} z komputera)',
    fileActionSettings: {
        removeTitle: 'Usuń plik',
        uploadTitle: 'Przesyłanie pliku',
        uploadRetryTitle: 'Ponów',
        downloadTitle: 'Pobierz plik',
        zoomTitle: 'Pokaż szczegóły',
        dragTitle: 'Przenies / Ponownie zaaranżuj',
        indicatorNewTitle: 'Jeszcze nie przesłany',
        indicatorSuccessTitle: 'Dodane',
        indicatorErrorTitle: 'Błąd',
        indicatorPausedTitle: 'Przesyłanie zatrzymane',
        indicatorLoadingTitle:  'Przesyłanie &hellip;'
    },
    previewZoomButtonTitles: {
        prev: 'Pokaż poprzedni plik',
        next: 'Pokaż następny plik',
        toggleheader: 'Włącz / wyłącz nagłówek',
        fullscreen: 'Włącz / wyłącz pełny ekran',
        borderless: 'Włącz / wyłącz tryb bez ramek',
        close: 'Zamknij szczegółowy widok'
    }
};


//poniższe nie działą
// import "bootstrap-fileinput/scss/fileinput.scss";
// import "bootstrap-fileinput/themes/explorer/theme.css";



$mea.types.add('upl-file-dnd',async (element)=>{


    await import(/* webpackChunkName: "fileinput-chunk" */ 'bootstrap-fileinput');
    await import(/* webpackChunkName: "fileinput-explorer-chunk" */ 'bootstrap-fileinput/themes/explorer/theme.js');
    await import(/* webpackChunkName: "uniform-chunk" */ 'uniform.js');

    if(element.attr('multiple')){
        $.fn.fileinputLocales['pl']['dropZoneTitle']= 'Przeciągnij i upuść pliki tutaj &hellip;';
    }else{
        $.fn.fileinputLocales['pl']['dropZoneTitle']= 'Przeciągnij i upuść plik tutaj &hellip;';
    }

    let container = $(element).closest('[data-mea-selector=mea-file]');
    let subform = container.data('subform');
    // console.log('uploadInit',{
    //     container,
    //     subform
    // });
    if(!subform) subform = '';

    console.log('upl-file-dnd found',{
        el: element,
        container: container,
        subform: subform,
    });

    $('.kv-fileinput-error').css("max-width", "90%");

    let input= $(element).fileinput({
        theme: "explorer",
        language : $('html').attr('lang'),
        uploadUrl: $('[data-mea-type=upl-file-dnd]').data('url'),
        // uploadUrl: $("#input-file").data('url'),
        //allowedFileExtensions: ['jpg', 'png', 'gif'],
        overwriteInitial: false,
        showCancel: false,
        uploadExtraData: function (previewId, index) {
            if(!previewId){
                return false;
            }
            let extraData =  $mea.forms.formToArray('#'+previewId);
            if($('#'+previewId).closest('form').find('[name*="meaFileData"]')){
                // extraData.meaFileData2 = $mea.forms.toArray('[name*="meaFileData"]');

                let formValue = $('[name*="meaFileData"]').serializeArray();
                formValue.forEach(function(v, i){
                    extraData[v.name]=v.value;
                });

                //send as object
                //extraData.meaFileData = JSON.parse($('[name*="meaFileData"]').serializeJSON()).form.meaFileData;
            }

            extraData.parent = $('input[type=radio].parentSelector:checked').closest('.file-preview-frame').attr('title');
            // let extraData = {
            //     'parent': $('input[type=radio].parentSelector:checked').closest('.file-preview-frame').attr('title'),
            //     'extraData': $mea.forms.formToArray('#'+previewId)
            // };

            // console.log('uploadExtraData previewId',previewId, extraData);
            return extraData;
        },

        // initialPreviewAsData: true,
        //he piexif.js library was not detected. Either load piexif.js on your page OR set `autoOrientImage` to `false`.
        autoOrientImage: false,
        uploadAsync: true,
        captionClass: 'd-none',
        browseClass: 'btn btn-primary noHpl',
        removeClass: 'btn btn-default noHpl',
        uploadClass: 'btn btn-primary noHpl',
        browseIcon: '<i class="fa fa-folder-open"></i>',
        previewFileIcon: '<i class="fa fa-file"></i>',
        removeIcon: '<i class="fa fa-trash"></i>',
        uploadIcon: '<i class="fa fa-upload"></i>',
        layoutTemplates: {
            footer: '<td class="file-details-cell">' +
                '<div class="explorer-caption" title="{caption}">' +
                '<input class="form-control" style="width: 100%" name="title" value="{caption}">' +
                subform +
                '</div> {size}{progress}' +
                '</td>' +
                '<td class="file-actions-cell">{indicator} {actions}</td>'
            // footer: '<div class="file-thumbnail-footer" style ="height:94px">\n' +
            //     '  <input class="kv-input kv-new form-control input-sm form-control-sm text-center {TAG_CSS_NEW}" value="{caption}" placeholder="Enter caption...">\n' +
            //     '  <input class="kv-input kv-init form-control input-sm form-control-sm text-center {TAG_CSS_INIT}" value="{TAG_VALUE}" placeholder="Enter caption...">\n' +
            //     '   <div class="small" style="margin:15px 0 2px 0">{size}</div> {progress}\n{indicator}\n{actions}\n' +
            //     '</div>'
        },
        previewZoomButtonIcons: {
            prev: '<i class="fa fa-caret-left"></i>',
            next: '<i class="fa fa-caret-right"></i>',
            toggleheader: '<i class="fa fa fa-arrows-v"></i>',
            fullscreen: '<i class="fa fa fa-arrows-alt"></i>',
            borderless: '<i class="fa fa-expand"></i>',
            close: '<i class="fa fa-times-circle-o"></i>'
        },
        fileActionSettings: {
            showZoom: false,
            removeIcon: '<i class="fa fa-trash"></i>',
            removeClass: 'btn btn-sm btn-kv btn-default',
            uploadIcon: '<i class="fa fa-upload"></i>',
            uploadClass: 'btn btn-sm btn-kv btn-default',
            uploadRetryIcon: '<i class="fa fa-repeat"></i>',
            downloadIcon: '<i class="fa fa-download"></i>',
            downloadClass: 'btn btn-sm btn-kv btn-default',
            zoomIcon: '<i class="fa fa-search-plus"></i>',
            zoomClass: 'btn btn-sm btn-kv btn-default',
            dragIcon: '<i class="fa fa-arrows"></i>',
            previewFileIcon: '<i class="fa fa-file"></i>',
            indicatorNew: '<i class="fa fa-plus-circle text-warning"></i>',
            indicatorSuccess: '<i class="fa fa-check-circle text-success"></i>',
            indicatorError: '<i class="fa fa-exclamation-circle text-danger"></i>',
            indicatorLoading: '<i class="fa fa-hourglass text-muted"></i>',
            cancelIcon: '<i class="fa fa-ban"></i>',
            //     removeClass: "btn btn-xs btn-icon",
            //     // uploadClass: "btn btn-xs btn-icon",
            //     zoomClass: "btn btn-xs btn-icon"
            // },
            // previewTemplates: {
            //     generic: '<div class="file-preview-frame" id="{previewId}" data-fileindex="{fileindex}" data-template="{template}">OK\n' +
            //     '   {content}\n' +
            //     '   {footer}\n' +
            //     '</div>\n'
            // },
            // layoutTemplates: {
                // actions: '<div class="file-actions">\n' +
                //     '    <div class="file-footer-buttons">\n' +
                //     '   {delete} {zoom} {other}' +
                //     '    </div>\n' +
                //     '    <div class="file-upload-indicator" title="Set as main document">' +
                //     'asas<div class="radio"><label>' +
                //     '<input type="radio" name="parent" class="parentSelector control-primary" onclick="ctrackerUploader.parentSelect(this)"></label></div></div>\n' +
                //     '    <div class="clearfix"></div>\n' +
                //     '</div>'
            // }
        },
        preferIconicPreview: false, // this will force thumbnails to display icons for following file extensions
        previewFileIconSettings: { // configure your icon file extensions
            'doc': '<i class="fa fa-file-word-o text-primary"></i>',
            'xls': '<i class="fa fa-file-excel-o text-success"></i>',
            'ppt': '<i class="fa fa-file-powerpoint-o text-danger"></i>',
            'pdf': '<i class="fa fa-file-pdf-o text-danger"></i>',
            'zip': '<i class="fa fa-file-archive-o text-muted"></i>',
            'htm': '<i class="fa fa-file-code-o text-info"></i>',
            'txt': '<i class="fa fa-file-text-o text-info"></i>',
            'mov': '<i class="fa fa-file-movie-o text-warning"></i>',
            'mp3': '<i class="fa fa-file-audio-o text-warning"></i>',
            // note for these file types below no extension determination logic
            // has been configured (the keys itself will be used as extensions)
            'jpg': '<i class="fa fa-file-photo-o text-danger"></i>',
            'gif': '<i class="fa fa-file-photo-o text-muted"></i>',
            'png': '<i class="fa fa-file-photo-o text-primary"></i>'
        },
        previewFileExtSettings: { // configure the logic for determining icon file extensions
            'doc': function (ext) {
                return ext.match(/(doc|docx)$/i);
            },
            'xls': function (ext) {
                return ext.match(/(xls|xlsx)$/i);
            },
            'ppt': function (ext) {
                return ext.match(/(ppt|pptx)$/i);
            },
            'zip': function (ext) {
                return ext.match(/(zip|rar|tar|gzip|gz|7z)$/i);
            },
            'htm': function (ext) {
                return ext.match(/(htm|html)$/i);
            },
            'txt': function (ext) {
                return ext.match(/(txt|ini|csv|java|php|js|css)$/i);
            },
            'mov': function (ext) {
                return ext.match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i);
            },
            'mp3': function (ext) {
                return ext.match(/(mp3|wav)$/i);
            }
        }

    })
    .on('filebatchuploadcomplete', function(event, previewId, index, fileId) {

        console.log('filebatchuploadcomplete',{
                'stack': $(element).fileinput('getFileStack'),
                'stackLen': $(element).fileinput('getFileStack').length,
                'getFilesCount': $(element).fileinput('getFilesCount'),
            event, previewId, index, fileId
        });

    }) .on('filebatchuploadsuccess', function(event, previewId, index, fileId) {

        // console.log('filebatchuploadsuccess',event, file, previewId, index, reader);
        $('.gotoManageBlock').show();

        // console.log('filebatchuploadsuccess',{
        //         'stack': $(element).fileinput('getFileStack'),
        //         'stackLen': $(element).fileinput('getFileStack').length,
        //         'getFilesCount': $(element).fileinput('getFilesCount'),
        //     event, previewId, index, fileId
        // });

    })
    .on('fileuploaded', function(event, previewId, index, fileId) {

       let data_response;

       try{
            data_response = previewId.jqXHR.responseJSON;
            // console.log('uploadResponse',data_response);
       }catch (e) {
           console.error('uploadResponseException',e);
       }

        let list_target = $(element).data('list-target');
        if(list_target){
            let listTargetObject = $('#sonata_list_'+list_target);
            if(listTargetObject)
                listTargetObject.reload();
        }

        let onUPload = $(element).data('on-uploaded');

        if(onUPload)
        try{
            console.log('File uploaded execute',onUPload);
            eval(onUPload);
        }catch (e){
            console.log('File uploaded execute exception',e);
        }

        $(element).trigger('mea_fileuploaded',[
            {
                data: data_response,
                previewId,
                index,
                fileId
            }
        ]);


        setTimeout(()=>{

            console.log('fileUploaded',{
                event, previewId, index, fileId,
                'stack': $(element).fileinput('getFileStack'),
                'stackLen': $(element).fileinput('getFileStack').length,
                'getFilesCount': $(element).fileinput('getFilesCount'),
            });

            if($(element).fileinput('getFileStack').length == 0){
                $(element).fileinput('clear');
            }

        }, 1000);



        // console.log('File uploaded', previewId, index, fileId);
    })
    //    selected
    .on('fileloaded', function(event, file, previewId, fileId, index, reader) {

        // console.log('fileloaded',{
        //     'stack': $(element).fileinput('getFileStack'),
        //     'stackLen': $(element).fileinput('getFileStack').length,
        //     'getFilesCount': $(element).fileinput('getFilesCount'),
        //     event, file, previewId, fileId, index, reader
        // });

        //Add additional text field
        // var referanceComponentProgressbar = $("#" + previewId).find(".file-thumbnail-footer .file-thumb-progress");
        // referanceComponentProgressbar.before($('<input type="text" id="' + previewId
        //     + '_additionaltextfield" class="form-control"  >'));
        //
        // console.log('referanceComponentProgressbar',referanceComponentProgressbar);

        //Do not display broken images
        // $(".kv-preview-data.file-preview-image").error(function() {
        //     this.src="static/customer/img/no_preview.jpg";
        // });
    })
    .on('fileclear', function(event) {

        $('.mea-uploader-alert').hide();

    })
    .on('filebatchselected', function (event, data, previewId, index) {

        if ($('.parentSelector:checked').length > 0) {
            $('.mea-uploader-alert').hide();
        } else {
            $('.mea-uploader-alert').show();
        }

        $('.parentSelector').click(function () {
            $('.mea-uploader-alert').hide();
        });


        // Checkboxes/radios (Uniform)
        $(".styled, .multiselect-container input").uniform({
            radioClass: 'choice'
        });

        // File input
        $(".file-styled").uniform({
            wrapperClass: 'bg-blue',
            fileButtonHtml: '<i class="icon-file-plus"></i>'
        });

        $(".control-primary").uniform({
            radioClass: 'choice',
            wrapperClass: 'border-primary text-primary'
        });

        //end // Checkboxes/radios (Uniform)


    })
    .on('filepreajax', function (event, data, previewId, index) {

        $(".kv-upload-progress").show();

    })
    ;


    let labelField = $(element).closest('.custom-file').find('.custom-file-label').hide();
    $('label[for="'+labelField.attr('for')+'"]').hide();

    $("#input-ke-1").on('fileuploaded', function (event, file, previewId, index, reader) {
        $('#single .gotoManageBlock').show();
    });




});


