import Cookies from "js-cookie";

export default class FilterBoxVisibilitySwitcher
{
    constructor(idFilterContainer)
    {


        let boxTop = $('[data-mea-filter-container="'+idFilterContainer+'"]');
        this.idFilterContainer = idFilterContainer;
        this.listContainer = $('#'+idFilterContainer).closest('[data-controller="list-filters"]');
        this.adminCode = this.listContainer.data('list-filters-admin-code-value'); //$('[data-admin-code]').data('admin-code');
        this.dataUser = {};
        this.$boxString = boxTop.find('#stringFilters');
        this.$boxFilter = this.$boxString.closest('.box');
        this.$boxHeader = this.$boxFilter.find('.box-header');

        if (this.$boxFilter.data('mea-filter-container') !== undefined) {
            this.$boxBody = $('#'+this.$boxFilter.data('mea-filter-container')).find('.box');

        }else {
            this.$boxBody = this.$boxFilter.find('.box');
        }

        this.$selectBoxCriteria = this.listContainer.find('.mea-box-select-criteria');

        this.log(this);

        this.initEvents();
        //this.readData();

    }
    log(...vars){
       // console.log('FilterBoxVisibilitySwitcher',...vars);
    }


    initEvents(){

        // this.setInfo(
        //     // $mea.forms.formToString('#'+this.idFilterContainer+' form', '.advanced-filter, .mea-box-select-filter-criteria, .not-to-string')
        //     $mea.forms.formToString('#'+this.idFilterContainer+' form', '.mea-box-select-filter-criteria, .not-to-string')
        // );

        //removed - admin.listTableFiltersOpened dont allow hide and this hide by default
        // if (this.isVisible()) {
        //     $('.sonata-filters-box').slideDown();
        //     this.$selectBoxCriteria.css('display', 'none');
        // }else {
        //
        //     this.$boxHeader.find('[data-widget="box-filter"]').find('.fa-angle-up').removeClass('fa-angle-up').addClass('fa-angle-down');
        //     this.setInfo(
        //         // $mea.forms.formToString('#'+this.idFilterContainer+' form', '.advanced-filter, .mea-box-select-filter-criteria, .not-to-string')
        //         $mea.forms.formToString('#'+this.idFilterContainer+' form', '.mea-box-select-filter-criteria, .not-to-string')
        //     );
        //
        //     this.$boxBody.css('display', 'none');
        //     this.$selectBoxCriteria.css('display', 'inline-flex');
        // }

        this.$boxHeader.find('[data-widget="box-filter"]').on('click', event => {

            if(!$(event.currentTarget).closest('.sonata-filters-box').hasClass('show')) {
                $(event.currentTarget).closest('.sonata-filters-box').addClass('show');
            }

            if (this.isVisible()) { //hidden filter box
                this.dataUser[this.adminCode] = 0;
                this.$boxHeader.find('[data-widget="box-filter"]').find('.fa-angle-up').removeClass('fa-angle-up').addClass('fa-angle-down');
                this.$selectBoxCriteria.css('display', 'inline-flex');
                // this.$boxString.text($mea.forms.formToString('#'+this.idFilterContainer+' form', '.advanced-filter, .mea-box-select-filter-criteria, .not-to-string'));
                this.$boxString.text($mea.forms.formToString('#'+this.idFilterContainer+' form', '.mea-box-select-filter-criteria, .not-to-string'));
                this.$boxBody.css('display', 'none');
            }else {
                this.$boxBody.css('display', 'block');
                $('#'+this.idFilterContainer).css('display', 'block');

                this.listContainer.find('.sonata-filters-box').slideDown();
                this.dataUser[this.adminCode] = 1;
                this.$boxHeader.find('[data-widget="box-filter"]').find('.fa-angle-down').removeClass('fa-angle-down').addClass('fa-angle-up');
                this.$selectBoxCriteria.css('display', 'none');
                this.$boxString.text('');

            }

            // this.saveData();
        });

        this.$boxString.on('click', event => {
            this.$boxHeader.find('[data-widget="collapse"]').trigger('click');
        });


    }

    init(settings)
    {

        this.dataUser = JSON.parse(settings);

        if (this.dataUser[this.adminCode] === undefined) {
            this.dataUser[this.adminCode] = 0; //default hidden filter
        }


    }


    setInfo(content){
        this.$boxString.html(
            '<span title="Active filters"> '+content+'</span>'
        );
    }


    isVisible()
    {
        return parseInt(this.dataUser[this.adminCode]) === 1;
    }



    saveData() {
        this.log('saveData disabled', this.dataUser);
        // let url = Routing.generate('mea_public_action', {action: 'saveUserDataSettings'});
        // $.post(url, {data: JSON.stringify(this.dataUser), dataType: 'visibilityFilter'});
    }

}
