/**
 *
 import {MeaShop, MeaShoppingBasket} from "./../../../vendor/Mea/ShopBundle/Resources/webpack/mea_shop";
 MeaShoppingBasket.setMinimumValueToBePaidWithPrepaid(5);
 MeaShop.registerMeaReleases();
 *
 */

import {MeaShoppingBasket} from "./mea_shopping_basket";

class MeaShop{

    constructor(container,config) {
        this.orders = [];
        this.config = {
            url_dispatch: null,
            url_basket: null,
            events: {
                basketProductInfoGet: 'basketProductInfoGet',
                basketProductAdd: 'basketProductAdd',
                basketProductRemoved: 'basketProductRemoved',
            },
            selector:{
                meaShopProductsList: '.meaShopProductsList',
                meaShopProduct: '[data-mea-type=meaShopProduct]',
                meaShopBasketWidget: '.meaShopBasketWidget',
                meaShopAddProductBtn: '.meaShopAddProductBtn',
                meaShopBuyProductBtn: '.meaShopBuyProductBtn',
                dataMeaShopProductId: 'meashop-product',
                dataMeaShopProductExtras: '#meashop-product-extra',
            },
        }

        this.$container = $(container);
        this.$basketWidget = $(container).find(this.config.selector.meaShopBasketWidget);
        this.$productsList = $(container).find(this.config.selector.meaShopProductsList);

        this.config = $.extend(this.config, config );

        this.init();

        // console.log('shop fired',this);

    }

    static registerMeaReleases(){

        $mea.types.add('mea_shop',function(el){
            let meaShop = new MeaShop(el,JSON.parse($(el).data('shop-config')));
            window.meaShop = meaShop;
        });

        $mea.types.add('meaShopProduct',(el)=>{
            window.meaShop.initProduct(el);
        });

        $mea.forms.add('mea_shopping_basket', (el)=>{
            new MeaShoppingBasket(el)
        });



    }

    getExtraOptions(productId){

        let productContainer = $('[data-meashop-product='+productId+']');

        return productContainer.find(this.config.selector.dataMeaShopProductExtras).val();

    }

    init(){

        this.$container.data('mea_shop',this);

        this.initEvents();


        this.initInfo();

        this.$basketWidget.click((e)=>{
            e.preventDefault();
            if(this.orders && this.orders.length > 0){
                let href =  $(e.currentTarget);
                if($(href).data('href')){
                    $mea.ajax.locationChange(
                        $(href).data('href')
                    )
                    return;
                }else{}
                    window.location.href = $(href).attr('href');
            }
        });

        // $('body').on('click','[data-meashop-product]',(e)=>{
        //
        // });


    }

    initEvents(){

        $('body').on(this.config.events.basketProductAdd, (e)=>{

            console.log('event ',this.config.events.basketProductAdd,e);

            this.updateBasketWidget(e.basket);

            this.updateProduct(this.getProductListContainer(e.productId), e.product)


        }).on(this.config.events.basketProductRemoved, (e)=>{

            console.log('removedProductEvent captured',e);

            this.updateBasketWidget(e.basket);

            this.updateProduct(this.getProductListContainer(e.productId),  this.getProductData(e.productId))

            // console.log('event '.this.config.events.basketProductAdd,e.basket);
        });

    }

    initInfo(){

        // console.log('initInfo',typeof $mea.ajax.load);

        $mea.ajax.load(
            this.getUrl('basketInfo')
        ).on('onSuccess',(e,parsed,c)=>{

            // console.log('onSuccess',e,parsed);

            if(parsed && parsed.data){

                let data = parsed.data;

                this.orders = parsed.data.orders;

                if(data.amount > 0)
                    this.$basketWidget.show().attr('role','button');

                this.$basketWidget.find('.amount').html(
                    ' '+data.amount
                );

                $.each(this.orders,(k,orderData)=>{

                    let prodEl = this.getProductListContainer(orderData.product_id);

                    this.updateProduct(prodEl,orderData);

                });



            }else{
                this.$basketWidget.find('.amount').html(
                   0
                )
            }
        });

    }

    addItemToBasket(productData,callback){

        let productId = productData.id;

        $mea.ajax.load(
            this.getUrl('addProduct',{
                product: productId,
            }),
            {
                options: this.getExtraOptions(productId),
                config: productData.config
            }
        )
            .on('onSuccess',(event,parsed)=>{

                console.log('parsed.data',parsed.data);
                this.orders = parsed.data.basket.orders;

                let actionEvent = $.Event(this.config.events.basketProductAdd, {
                    basket: parsed.data.basket,
                    product: parsed.data.product,
                    productId: productId
                });
                $('body').trigger(actionEvent);

                callback(event,parsed);




            });


    }

    updateProduct($productContainer, data){

        // console.log('updateProduct',data,this.isProductInBasket(data.product_id));

        let btn =  $($productContainer).find(this.config.selector.meaShopAddProductBtn);

        if(this.isProductInBasket(data.product_id)){

            btn
                .addClass('text-success')
            ;
            btn.attr('title',btn.data('in-basket-title'));

        }else{

            btn
                .removeClass('text-success')
            ;

            btn.attr('title',btn.data('empty-title'));

        }

        btn
            .find('.quantity')
            .html(data.quantity);


    }

    initProduct(el){

        console.log('initProduct',el);

        let productId = $(el).data(this.config.selector.dataMeaShopProductId);

        $.each(this.orders,(k,order)=>{
            if(productId === order.product_id){
                this.updateProduct(
                    this.getProductListContainer(productId),order
                )
            }
        });

        $(el).find(this.config.selector.meaShopBuyProductBtn).click((e)=>{

            e.preventDefault();
            e.stopPropagation();

            let productData = this.getProductInfoForEl(e.currentTarget);
            if(productData === false){
                return ;
            }

            if(this.isProductInBasket(productId)){
                // this.$basketWidget.click();
            }

            this.addItemToBasket(productData,()=>{
                this.$basketWidget.click();
            });

        });

        $(el).find(this.config.selector.meaShopAddProductBtn).click((e)=>{

            e.preventDefault();
            e.stopPropagation();

           let productData = this.getProductInfoForEl(e.currentTarget);

           if(productData === false){
               return ;
           }

            if(this.isProductInBasket(productId)){
                // this.$basketWidget.click();
            }


            this.addItemToBasket(productData,(event,parsed)=>{

                $mea.messages.confirm(
                    $mea.translator.trans('basket.add.success',{
                        product_name: parsed.data.product.name
                    }),
                    () => {
                        if (this.config.url_basket){
                            window.location.href=this.config.url_basket;
                        }else{
                            console.log('config.url_basket empty',this.config);
                        }

                    },
                    null,
                    {
                        title: $mea.translator.trans('basket.added.title'),
                        labelConfirm: $mea.translator.trans('basket.goto.cart'),
                        labelCancel: $mea.translator.trans('basket.continue.shopping'),
                    })

            });



        });

    }

    updateBasketWidget(data){

        console.log('updateBasketWidget',data);

        if(data.amount){

            console.log('data.amount',data.amount);
            this.$basketWidget.show();

        }else{

            this.$basketWidget.hide();

            // if(window.location.pathname === this.config.url_basket)
            //     window.location.href='/';

        }
        this.$basketWidget.find('.amount').html(data.amount);

    }

    getProductData(id){
        let out = false;
        $.each(this.orders,(k,product)=>{
            if(product.product_id ===id ) out = product;
        });
        return out;
    }
    getProductListContainer(id){

        return this.$productsList.find(
            '[data-meashop-product='+id+']'
        );
    }

    isProductInBasket(id){

        let out = false;
        $.each(this.orders,(k,product)=>{
            if(product.product_id ===id ) out = true;
        });

        return out;
    }

    getProductInfoForEl(el){

        let $prContainer =  $(el).closest('[data-mea-type=meaShopProduct]');

        /**
         * allow app push more data to product config
         */
        let event = jQuery.Event( this.config.events.basketProductInfoGet, {
            $prContainer: $prContainer
        } );

        $('html').trigger(event);

        if(event.isPropagationStopped()){
            return false;
        }

        // console.log('getProductInfoForEl',el,$prContainer.data('meashop-product'));


        return {
            $container: $prContainer,
            id: $prContainer.data('meashop-product'),
            // options: this.getExtraOptions($prContainer.data('data-meashop-product')),
            config: $prContainer.data('meashop-product-config')
        };
    }

    getUrl(type, params){

        if(!this.config.url_dispatch){
            console.log('MeaShop Empty baseUrl');
            return ;
        }

        switch (type){

            case 'changeQuantity':
            case 'basketInfo':
            case 'removeProduct':
            case 'addProduct':
            case 'buyProduct':

                let url = this.config.url_dispatch.replace('action',type);

                // console.log(';url', {
                //     orig: this.config.url_dispatch,
                //     url
                // });

                return $mea.ajax.urlAdd(url,params);


            default:
                console.log('meaShop unregistered action ',type);
                break;

        }



    }

    test(){
        alert('as');
    }


}

export {MeaShop, MeaShoppingBasket}
