let MeaForm = require('./meaform').default;

export default class MeaSonataForm extends MeaForm{


    constructor(form,uniq)
    {
       super(form,uniq);
    }

    getInputContainer(name){
        return this.getInput(name).closest('.md-form');
    }

    getInput(name){
        return $('#'+this.uniq+'_'+name);
    }

}