
export class InlineAdmin  {

    initReleases(){

        $mea.ajax.registerRelase('mea-cms-block','.cms-block',(el)=>{

            $.fn.showElement = function(){
                $('.mea-el-higlight').removeClass('red mea-el-higlight');
                $(this).addClass("red mea-el-higlight");
                setTimeout( ()=> {
                    $(this).removeClass('red mea-el-higlight');
                }, 4000);
            }

            // console.log('len',$(el).html().trim().length);
            if($(el).attr('data-id') && $(el).data('type') !== 'sonata.page.block.container' ){

                let href = '/panel/app/sonata-sonatapageblock/'+$(el).attr('data-id')+'/edit';

                let btn = $('<span class="meaInlineAdminBtn " style="z-index: 1000"><a data-rel="dialog" href="'+href+'">Block'+$(el).attr('data-id')+' <i class="fa fa-edit"></i></a></span>');

                $(el).prepend(btn);

                btn.hover(()=>{
                    $(el).closest('.cms-block').showElement();
                });

            }

            if($('body').find('#mea-page-edit').length===0 && $(el).attr('data-page-id')){

                let href = '/panel/app/sonata-sonatapagepage/'+$(el).attr('data-page-id')+'/edit';

                $('body').prepend('<span class="meaInlineAdminBtn "  title="Enable/Disable edit helpers" style="z-index: 1000" id="mea-page-edit"><a class="noHpl" href="'+href+'">Page'+$(el).attr('data-id')+' <i class="fa fa-edit"></i></a></span>');

                let $toggleBtn = $('<span draggable="true" class="btn btn-default" style="z-index: 1000; position: fixed; top:50vh" id="mea-page-edit-switcher">' +
                    '<a class="noHpl">' +
                    '<i class="fa fa-edit"></i>' +
                    '</a>' +
                    '</span>');

                $('body').prepend($toggleBtn);
                $toggleBtn.on('click',()=>{
                    this.toggleHelpers();
                });


            }

        });

        $mea.types.add('article',(el)=>{

            let href = '/panel/app/article/'+$(el).attr('data-id')+'/edit';

            let btn = $('<span class="meaInlineAdminBtn "  style="z-index: 1000"><a data-rel="dialog" data-dialog-id="editBlock'+$(el).attr('data-id')+'" href="'+href+'">ARTICLE '+$(el).attr('data-id')+' <i class="fa fa-edit"></i></a></span>');

            $(el).prepend(btn);

            btn.hover(()=>{
                $(el).closest('[data-mea-type=article]').showElement();
            });

        });


        $('html')[0].addEventListener('event_user_login', (e)=> {

            // console.log('user login captured',window.$mea.user);

            if($mea.user && $mea.user.isEditor()){

                let editor = new InlineAdmin();
                editor.initReleases();
                // console.log('user login editor enabling inline');


            }

        }, false);

    }

    toggleHelpers(){
        $('.meaInlineAdminBtn').toggle();
    }

}




