export class MeaUser  {

    /**
     * webpack error
     * Add @babel/plugin-proposal-class-properties (https://git.io/vb4SL) to the 'plugins' section of your Babel config to enable transformation
     * @param config_site
     */
    // static event_user_login = 'event_user_login';

    constructor(config_site) {

        //console.log('user init ',config_site);

        let config = {
            id:null,
            email:'Anonymous',
            fullname:'Anonymous',
            is_admin: false,
            // groups: {},
            // roles: {},
        }
        this.config = config;
    }

    login(config_site){

        $.extend(this.config , config_site   );

        const event = new Event('event_user_login');

        $('html')[0].dispatchEvent(event);

    }

    getFullName(){
        return this.config.fullname;
    }
    isEditor(){
        return this.config.is_admin === 1;
    }

    /**
     * $mea.user.hasRole('ROLE_SELLER'):bool
     * @param role
     * @returns {boolean}
     */
    hasRole(role){
        if(!this.config.groups) return false;

        let out = false;
        $.each(this.config.groups,(k,v)=>{
            if(v === role) {
                out = true;
            }
        });
        return out;
    }

}
window.MeaUser=MeaUser;
// $mea.user = new MeaUser();