// import Handlebars from 'handlebars/dist/cjs/handlebars'
import Handlebars from 'handlebars/dist/handlebars.min.js'
/**
 * Fullscrencss
 .mea-fullscreen{
  width: 100%!important;
  height: 100%!important;
  .ui-dialog-titlebar{
    width: 100%;
  }
}
 :fullscreen{
  .mea-fullscreen;
}
 :-moz-full-screen{
  .mea-fullscreen;
}
 :-ms-fullscreen{
  .mea-fullscreen;
}
 :-webkit-full-screen{
  .mea-fullscreen;
}

 .mea-maximized{
  width: 100%!important;
  height: 100%!important;
  left: 0px!important;
  top:0px!important;
}
 .mea-dialog-header-btn{
  float: right;
  margin: 5px;
  display: inline-block;
}
 */

//https://stackoverflow.com/questions/19305821/multiple-modals-overlay
$(document).on('show.bs.modal', '.modal', function() {
    const zIndex = 1040 + 10 * $('.modal:visible').length;
    $(this).css('z-index', zIndex);
    setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
});

// require('./mea.core');
// import 'jquery-ui/ui/widgets/dialog';
// require('webpack-jquery-ui/dialog');
let d_dialog = function(params){

    if(params.dialog_id && !params.id )params.id = params.dialog_id;

    let settings = $.extend({}, $mea.dialog.defaults ,params  );

    // console.log('settings',settings);

    let dialog = $mea.dialog.createDialogContainer(settings);

    settings.open = (ui,el)=>{

        $('.ui-widget-overlay').bind('click', function()
        {
            $("#"+settings.id).dialog('close');
        });

        if(typeof params.onShow ==='function'){
            params.onShow()
        }

        $mea.dialog.createContent(settings);

        $mea.dialog.fluidDialog();

        if(!$mea.dialog.onOpen(settings)){
            return ;
        }
        if(settings.noclose){

            $('#'+params.id).closest('.ui-dialog').find('.ui-dialog-titlebar-close').hide();

        }else{

            $( '.ui-dialog-titlebar-close').addClass( 'btn-default' );

        }

        $('#'+settings.id).scrollTop(1);


        let zindex ;

        if(settings.zIndex === 'force'){
            let maxZ = 0;
            $('*').not(".bootstrap-datetimepicker-widget").not(".cke").each(function ()
            {
                var thisZ = $(this).css('zIndex');
                thisZ = (thisZ === 'auto' ? (Number(maxZ) + 1) : thisZ);
                if (thisZ > maxZ) {
                    //console.log('zindex computing',thisZ,$(this));
                    maxZ = thisZ;
                }
            });

            zindex = maxZ;

        }else{

            zindex = settings.zIndex;

            $mea.dialog.defaults.zIndex++;

        }

        if(params.modal)
            $(".ui-widget-overlay").css("zIndex", (zindex + 1));

        dialog.parent().css("zIndex", (zindex + 2));

    };

    if(settings.noclose) {

        settings.closeOnEscape = false ;

    }

    if(!settings.maxHeight)
        settings.maxHeight = $( window ).height()-($( window ).height()*0.1);

    // if(settings.height =='auto'){
    //     settings.height = $( window ).height()-($( window ).height()*0.1);
    // }

     // $mea.log('dialogSettings123',settings);

    // settings.classes= {
    //     'ui-icon-closethick': 'class'
    // };

    dialog.dialog(settings)
        .dialog('widget')
        .find('.ui-dialog-content')
        .attr('id', settings.id)
        .addClass('meaDialog meaDialogContent '+params.class)
        .data('engine','ui')
    ;


    //$mea.log(settings);
    if(settings.modal || settings.centered){

        $(window).scroll(function() {
            if(!settings.position) settings.position = { my: "center", at: "center", of: window};
            // $('#'+settings.id).dialog("option", "position", "center");
            // $('#'+settings.id).dialog("option", "position", { my: "center", at: "center", of: window });
            $('#'+settings.id).dialog("option", "position", settings.position );
        });


    }

    $(window).trigger($mea.dialog.events.afterDialogShow);

    if(typeof params.show ==='function')
        params.show(dialog);

};

let mea_bs_modal = {

    init:function(params){

        let titleFromContent = false;

         params = $.extend({}, $mea.dialog.defaults ,params  );

//         console.log(params);

         if(params.contentFromUrl){
             $mea.ajax.loadVars(params.contentFromUrl,params.contentFromUrlData,{
                     type: params.contentFromUrlMethod
                 }
             ).then((itemResponse) =>{

                     params.contentFromUrl = false;

                    if(itemResponse && itemResponse.search('"type":"hpl"') === -1){
                        params.content = itemResponse;
                        // console.log('itemResponse',params);
                        this.init(params);

                    }else{
                        //todo: test it more
                        //console.log('itemResponse',itemResponse);
                        $mea.ajax.parseResponse(itemResponse,params);
                    }


                 },
                 (error)=> {
                     console.log('contentFromUrl err',error);
                 });

             return ;
         }

        if(params.contentFromUrlIframe) {

            params.content = '<iframe id="iframe_'+params.id+'" style="width: 100%; min-height:400px;"  height="100%" frameborder="0" src="' + params.contentFromUrlIframe + '"></iframe>';


        }else if(params.content){


        }else if(params.contentFromId) {

            if($(params.contentFromId).attr('type')==='text/x-handlebars-template'){
                let template = Handlebars.compile($(params.contentFromId)[0].innerHTML);
                let vars = {};
                // let vars = {
                //     value: value,
                // };
                params.content = template(vars);
            /**
             * Duplicated id if content has id elements
             */
            }else{
                params.content = $(params.contentFromId).html();
            }

            // $(params.contentFromId).remove();
        }

        if(typeof params.title ==='undefined'){

            if($mea.dialog.getTitleFromContentHeader){

                // fix for search in first element - default ignored
                let $content = $('<div/>').append(params.content);

                if($content.find($mea.dialog.defaults.title_forced_selector).length>0){

                    titleFromContent = true;

                    params.title = $content.find($mea.dialog.defaults.title_forced_selector).text().trim();

                }else if($content.find($mea.ajax.dialogTitleSelector).first().html()){

                    titleFromContent = true;

                    params.title = $content.find($mea.ajax.dialogTitleSelector).first().text().trim();


                    //this remove part of form - bralion-taskfinish
                    // $content.find($mea.ajax.dialogTitleSelector).remove();
                    // $(params.content).find($mea.ajax.dialogTitleSelector).hide();
                    // console.log('$content.html()',$content.html());
                    // params.content = $content.html();

                }else{
                    // console.log('title not found',{
                    //     selector: $mea.ajax.dialogTitleSelector,
                    //     el: $content.find($mea.ajax.dialogTitleSelector).text(),
                    //     content: params.content
                    // });
                }

            }else{

                params.title = "";
            }

        }

         // console.log('modal',params);
        /**
         *   'body',
         params.title,
         params.content
         */

        params.selectorPlacement = 'body';

        let dialog;

        $mea.translator.onLoadedLibrary(()=>{

            dialog = this.doModal(params);
        });

        if(titleFromContent)
        $(dialog).find($mea.ajax.dialogTitleSelector).hide();

        if(typeof params.show ==='function')
            params.show(dialog);

        // $("#myModal").modal()

    },

    doModal: function (params)
    {

        // selectorPlacement, heading, formContent, strSubmitFunc, btnText
        //  console.log('doModal',{
        //      selectorPlacement: params.selectorPlacement,
        //      id: params.id,
        //      p: params
        //  });

        if(params.class===''){ //data-dialog-class
            params.class = 'modal-lg'; //modal-xxl modal-xl modal-fluid modal-fullscreen
        }

        if(typeof params.closeBottomBtnClass === 'undefined' ||  params.closeBottomBtnClass ===''){
            params.closeBottomBtnClass = 'btn-danger';
        }

        //$('#'+params.id).remove();
        let $modal = $('#'+params.id);

        // console.log('doModal',{
        //     'old': $modal,
        // });

        if ($modal.length >0 ) {
             // console.log('removeOldDialog', {
             //     'old': $modal,
             //     'len': $modal.length,
             //     '$': $('#'+params.id),
             //     'id': params.id
             // });
             $mea.dialog.remove('#'+params.id);
             $modal.remove();
            // $('.modal-backdrop').first().remove();
        }else{
            // $('.modal-backdrop').remove();
        }

        //aria-hidden="true"> - envio dont show content
        //data-backdrop="static" - disable close on click fade
        let html =  '<div id="'+params.id+'" data-engine="bs"  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal">';
        html += '<div class="modal-dialog meaDialog '+params.class+'">';
        html += '<div class="modal-content meaDialogContent">';
        html += '<div class="modal-header meaDialogHeader">';
        if(params.title)
            html += '<h5 class="modal-title">'+ params.title+'</h5>';

        if (!params.noCloseIconTop) {
            html += '<a class="close" role="button" data-mdb-dismiss="modal" data-bs-dismiss="modal" data-dismiss="modal">×</a>';
        }

        html += '</div>';
        html += '<div class="modal-body meaDialogBody">';

        html += params.content;

        html += '</div>';
        html += '<div class="modal-footer meaDialogFooter">';

        html += '<span onclick="$mea.dialog.remove(\''+params.id+'\')" class="btn '+params.closeBottomBtnClass+'" data-dismiss="modal">';
        // html += $mea.translator.trans('close');
        html += '<span data-mea-selector="closeBottomBtn" data-mea-type="translator">'+
            (params.closeBottomBtnLabel ? params.closeBottomBtnLabel: 'close')
            +'</span>'; // close button
        html += '</span>'; // close button
        html += '</div>';  // footer
        html += '</div>';  // content
        html += '</div>';  // dialog
        html += '</div>';  // modalWindow

        $(params.selectorPlacement).append(html);

        let $site_html = $('html');

        //bs 5
        if(typeof bootstrap ==='object' && typeof bootstrap.Modal ==='function'){
            let modal = new bootstrap.Modal(document.getElementById(params.id), {}).show();
        }else{
            let modal = $("#"+params.id).modal();
        }

        $("#"+params.id)
            .on('show.bs.modal shown.bs.modal', ()=>{
              //  console.log('open', $('.meaDialog').lenght);
                if(typeof params.onShow ==='function'){
                    params.onShow()
                }

                $("#"+params.id).trigger($.Event( "meaDialogOpen", {
                    dialog_id: params.id,
                } ));
                $site_html.trigger($.Event( "meaDialogOpen", {
                    dialog_id: params.id,
                } ));

                $('body').addClass('modal-open');
                if(!$site_html.data('data-overflow-orig'))
                    $site_html.data('data-overflow-orig',$('html').css('overflow'));

                $site_html.css('overflow','hidden');


            })
            .on('hide.bs.modal hidden.bs.modal', ()=> {
                $('body').removeClass('modal-open');
                $( "html").trigger( "meaDialogClose" );
                // console.log('close dialog',{
                //     back: $('.modal-backdrop').length
                // });
                //sdd
                if($site_html.data('data-overflow-orig'))
                    $site_html.css('overflow', $( 'html' ).data('data-overflow-orig'));

            })

        ;



       //not work for meaImageEditor @postingcentre
        // $(""+selectorPlacement).find('.modal-body').html(formContent);

        // console.log('ActiveateContent on dialog show',{
        //     length: $('#'+params.id).length,
        //     relases: $mea.ajax.releases,
        //     params, html
        // });

       $mea.ajax.activateContent('#'+params.id,{
            //debug: true
        });

       $(window).trigger($mea.dialog.events.afterDialogShow);

        return $("#"+params.id);

        // console.log('bsDialog selectorPlacement',selectorPlacement,  $(""+selectorPlacement).length);
        // console.log('bsDialog formContent',formContent);

        // $("#modalWindow")
        // .on('show.bs.modal', function () {
        //     console.log('show modal');
        //     $(this).find('.modal-body').css({
        //         width:'auto', //probably not needed
        //         height:'auto', //probably not needed
        //         'max-height':'90%'
        //     });
        // });

        // $("#dynamicModal")
        //     .modal('show')



    },
    hideModal: function()
    {
        console.log('hideModal');
        // Using a very general selector - this is because $('#modalDiv').hide
        // will remove the modal window but not the mask
        $('.modal.in').modal('hide');
    }



};

$mea.dialog = {

    events: {
      'afterDialogShow':'afterDialogShow'
    },

    defaults: {
        id: 'dialog',
        // engine: 'ui', //bs
        engine: 'bs', //bs
        class: '', //bs any class: modal-xxl
        // width: '80%',
        width: '950px',
        height: 'auto',
        maxWidth: '100vw',
        autoOpen: true,
        // position: 'center',
        modal: false,
        allow_fullscreen: false,
        allow_maximize: false,
        centered: false,
        content: false,
        title_forced_selector: '.mea_dialog_title',
        //show: 'slideDown', hide: 'slideUp',
        fluid: true,
        noclose: false,
        noCloseIconTop: false,
        // position: { my: "top", at: "top+20px", of: window }, //{ my: "center", at: "center", of: window }
        footerSelector: '.mea-dialog-footer',
        zIndex: 1111, //int or force
        iframe:{
            css: ''
        },
        locale: {
            toogle_fullscreen: 'Toggle full screen mode',
            toogle_maximize: 'Toogle maximize'
        },
        closeBottomBtnLabel: 'close',

        //dialogresize: function(event, ui) { $mea.log('resize'); },
        close: function(event){

            // $( "html").trigger( "modalDialogClose", {
            //     '$dialog': $dialog
            // } );

            $( "html").trigger( "meaDialogClose" );

            if($( 'html' ).data('data-overflow-orig'))
                $('html').css('overflow', $( 'html' ).data('data-overflow-orig')); //.css('height','auto');

            $( '#'+this.id ).dialog( "close").remove();

        },

        beforeClose: function(event){

            var params = $(event.target).dialog('option',"meaParams");

            $( "html").trigger( "meaDialogBeforeClose", params );



            if($( '#'+this.id ).data('create-from-content')===1) {

                $( '#'+this.id).detach().appendTo('body').css('style','display:none');


            }


            if($( '#'+this.id ).attr('mea-protect-allow-close')===1) return ;

            if($( '#'+this.id ).find('[mea-reload-protect]').length > 0){

                var forms = $( '#'+this.id ).find('form[mea-reload-protect]');

                var dialogId = this.id ;

                $(forms).each(function(){

                    if($mea.forms.isChanged('#'+$(this).attr('id'))){

                        $mea.messages.confirm($mea.forms.config.message_changes_found,function(){

                            $( '#'+dialogId ).attr('mea-protect-allow-close',1).dialog('close');
                        });

                        event.preventDefault();
                    }
                });



            }
        },
    },

    create: function(params){
        if(!params) params = {};

        if(!params.id) params.id = 'dialog';

        $mea.messages.indicator(1);
        if(!params.onShow)
            params.onShow = ()=>{
                $mea.messages.indicator(0);
            }

        if(!params.engine) params.engine = $mea.dialog.defaults.engine;

        if(params.engine ==='ui'){
            d_dialog(params);
        } else{
            mea_bs_modal.init(params);
        }
    },

    showFromSelector: function(selector,params){

        if(!params) params = {};

        $(selector).dialog();

    },

    createFromSelector: function(selector,params){

        if(!params) params = {};
        params.contentFromId = selector;

        this.create(params);

    },

    createFromUrlIframe: function(url,params){

        if(!url){
            console.log('createFromUrlIframe empty URL');
            return;
        }

        if(!params) params = {};

        params.contentFromUrlIframe = url;

        this.create(params);
    },

    createFromUrl: function(url,params){

        // console.log('dialog createFromUrl',{
        //     url,params
        // });
        // return ;

        if(!url){
            console.log('createFromUrl empty URL');
            return;
        }

        if(!params) params = {};

        url = $mea.ajax.urlAdd(url,'urltarget=dialog');

        params.contentFromUrl = url;

        this.create(params);
    },

    createFromString: function(string,params){

        if(!params) params = {};
        params.content = string; //jQuery.parseHTML(string);

        this.create(params);
    },

    remove: function(id){

        let $dialog;

        switch (typeof id) {

            case "object":
                 $dialog = $(id);
                this.removeDialogObject($dialog);
                break;

            case "string":
                 let removed = false;
                 try{
                     $dialog = $('#'+id);
                     if($dialog.length >0){
                         this.removeDialogObject($dialog);
                         removed = true;
                     }
                     return ;
                 }catch (e) {

                 }

                 $dialog = $(id);
                 if($dialog.length >0){
                     this.removeDialogObject($dialog);
                     removed = true;
                 }

                if(!removed)
                    console.warn('removeDialog not exist ',id);

                break;

            default:
                //console.log('remove any meaDialog');
                $('.meaDialog').each((k,el)=>{
                    this.removeDialogObject($(el));
                });
                $('[data-dismiss="modal"]').click();
                break;

        }


    },

    removeDialogObject: function($dialog){
        // console.log('removeDialogObject',
        //     $dialog.data('bs.modal')?._isShown,
        //     $dialog.parent()
        // );
        // $dialog.modal('hide');
        //
        // return ;

        try{

            if($( 'html' ).data('data-overflow-orig'))
                $('html').css('overflow', $( 'html' ).data('data-overflow-orig')); //.css('height','auto');

            if($dialog.data('engine')==='ui'){

                $dialog.remove();
                $dialog.dialog( "destroy" );
                $dialog.remove();

            }else{

                $dialog.on('hide.bs.modal',  ()=>{
                    $dialog.remove();
                });

                if($dialog.hasClass('meaDialog')){
                    // console.log('bs meaDialog remove',$dialog);
                    $dialog.parent().modal('hide').data( 'bs.modal', null );
                }else{

                    // console.log('bs NotMeaDialog remove',$dialog);
                    $dialog.modal('hide').data('bs.modal', null );
                    //
                }

                setTimeout(()=>{
                    if($('.meaDialog').length == 0){
                        $('.modal-backdrop').remove();
                    }
                },500);


                //todo count modal dialogs remove if empty. (disable hide bs)
                // $('.modal-backdrop').remove();
                // let id = $dialog.attr('id');
                // $dialog.remove();
                // // console.log('removed dialog id ',id, $('#'+id).length);
                // //?? why its not removed ?
                // if($('#'+id).length>0){
                //     $('#'+id).remove();
                //     // console.log('removed 2 dialog id ',id, $('#'+id).length);
                // }
            }

        }catch (e) {
            console.error('remove dialog exception',e);
        }
    },

    toggleFullscreen: function(id){
        let dialog = $('#'+id).closest('.ui-dialog');
        let elem = dialog[0];

        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }

            $('#'+id)
                .css('height','100%')
                .css('overflow-y','hidden')
            ;

        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    },

    getTitleFromContentHeader: true,

    fluidDialog: function fluidDialog() {

        let $visible = $(".ui-dialog:visible");
        // each open dialog
        $visible.each(function () {
            var $this = $(this);
            var dialog = $this.find(".ui-dialog-content").data("ui-dialog");
            // if fluid option == true
            if (dialog.options.fluid) {

                var wWidth = $(window).width();
                // check window width against dialog width
                if (wWidth < (parseInt(dialog.options.maxWidth) + 50))  {
                    // keep dialog from filling entire screen
                    $this.css("max-width", "99%");
                } else {
                    // fix maxWidth bug
                    $this.css("max-width", dialog.options.maxWidth);
                }
                //reposition dialog
                dialog.option("position", dialog.options.position);
            }
        });

    },

    createContent: function(params){

        let $dialog = $('#'+params.id);

        // console.log('dialog params',params);

        if(params.contentFromUrl){
            $mea.ajax.loadVars(
                params.contentFromUrl
            ).then((itemResponse) =>{

                    params.contentFromUrl = false;

                    if(itemResponse && itemResponse.search('"type":"hpl"') === -1){
                        params.content = itemResponse;
                    }else{
                        //todo: this work ?
                        $mea.ajax.parseResponse(itemResponse);
                    }
                    // console.log('itemResponse',params);
                    this.createContent(params);

                },
                (error)=> {
                    console.log('contentFromUrl err',res);
                });

            return ;
        }


        if(params.contentFromUrlIframe) {

            $dialog.html('<iframe id="iframe_'+params.id+'" style="width: 100%; max-height: 100vh; min-height:600px; height:100%;"  height="100%" frameborder="0" src="' + params.contentFromUrlIframe + '"></iframe>');


        // }else if(params.contentFromUrl){
        //
        //     params.content = $mea.ajax.loadVars(
        //         params.contentFromUrl
        //     );
        //
        //     $dialog.html(params.content);
        //
        //     if(!params.title && $mea.dialog.getTitleFromContentHeader)
        //         $mea.dialog.parseTitle(params.id);
        //
        //     // console.log('contentFromUrl:'+params.contentFromUrl,params.content );
        //
        //     // $mea.ajax.load(
        //     //     $mea.ajax.urlAdd(params.contentFromUrl,'urltarget='+params.id)
        //     // );

        }else if(params.content){

            $dialog.html(params.content);

            if(!params.title && $mea.dialog.getTitleFromContentHeader)
                $mea.dialog.parseTitle(params.id);

        }else if(params.contentFromId){

            return ; //nadpisane wczesniej

        }else {

            $dialog.remove();
            $dialog.dialog( "destroy" );
            $dialog.remove();

            $mea.log('empty dialog',params);


            return false;
        }

        $mea.dialog.parseFooter($dialog, params);

    },

    parseFooter: function ($dialog,params) {

        var $dialogContainer = $dialog.closest('.ui-dialog');

        if(params.footer){
            $dialogContainer.append('<div class="mea-dialog-footer">'+params.footer+'</div>');
            return ;
        }

        var $footer = $dialog.find(this.defaults.footerSelector);



        if($footer.length ==1){

            $footer.detach();
            $dialogContainer.append('<div class="mea-dialog-footer">'+$footer.html()+'</div>');
            //$dialog.css('margin-bottom',$dialogContainer.find('.mea-dialog-footer').height()+10);
        }

    },

    createTitle: function (id) {

        return $('#' + id).find($mea.ajax.dialogTitleSelector).first().text();

    },

    //title ui
    parseTitle: function (id) {


        let $dialog = $('#' + id);

        if(!$dialog.find($mea.ajax.dialogTitleSelector).first()){

            console.log("Title Tag not found", $mea.ajax.dialogTitleSelector);

            return ;

        }

        let title= this.createTitle(id);


        if(title){

            $dialog.closest('.ui-dialog').find('.ui-dialog-title').html(title);

            $dialog.find($mea.ajax.dialogTitleSelector).hide();


        }


    },

    onOpen: function(params){

        $('#'+params.id).dialog("option",{meaParams: params});

        let $dialog = $('#'+params.id).closest('.ui-dialog');

        $( "html").trigger( "meaDialogOpen", params );

        if(params.modal){

            if(!$( 'html' ).data('data-overflow-orig'))
                $('html').data('data-overflow-orig',$('html').css('overflow'));


            $('html').css('overflow','hidden');

            /**
             * example :
             $( "html" ).on( "meaDialogOpen", function( event, params ) {
                if(params.modal===true)  $("html").getNiceScroll().remove();
            });
             */
            // $('body').css('overflow','hidden').css('height','100vh');
        }

        if(params.allow_fullscreen)
            $dialog.find('.ui-dialog-titlebar').append(
                $('<a class="btn-maximize btn btn-default" title="'+params.locale.toogle_fullscreen+'" data-placement="bottom"><i class="glyphicon glyphicon-fullscreen"></i></a>').click(function(){
                    $mea.dialog.toggleFullscreen(params.id);
                })
            );
        if(params.allow_maximize)
            $dialog.find('.ui-dialog-titlebar').append(
                $('<a class="btn-maximize btn btn-default" title="'+params.locale.toogle_maximize+'" data-placement="bottom"><i class="glyphicon glyphicon-resize-full"></i></a>').click(function(){
                    $('#'+params.id).closest('.ui-dialog').toggleClass('mea-maximized');
                })
            );

        if(params.fullscreen)
            $mea.dialog.toggleFullscreen(params.id);

        $(window).resize(function () {
            $mea.dialog.fluidDialog();
        });



        dispatchEvent(new Event('load'));

        setTimeout(function(){

            console.log('activate content ','#'+params.id,$('.meaDialog .form-actions button[type=submit]').length);

            $mea.ajax.activateContent(
                $('#'+params.id).closest('.ui-dialog')
            );

        }, 500);



        return true;
    },

    createDialogContainer: function(params){

        var dialog;


        if(params.contentFromId && $(params.contentFromId).length>0){

            dialog = $(params.contentFromId).attr('data-create-from-content',1);


        }else if($('#'+params.id).length===0){


            $('<div id="'+params.id+'" style="display:hidden"></div>').appendTo('body');

            dialog = $('#'+params.id);

        }else {
            if($( '#'+params.id ).data('engine')==='ui')
                $( '#'+params.id ).dialog( "close").remove();
            else
                $( '#'+params.id ).remove();
            //$('#'+params.id).remove();

            $('<div id="'+params.id+'" style="display:hidden"></div>').appendTo('body');

            dialog = $('#'+params.id);
        }

        return dialog;
    }

};


