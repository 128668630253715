import {MeaShoppingBasket} from "../../../vendor/Mea/ShopBundle/Resources/webpack/mea_shopping_basket";


function isMobile() {
    if (
        /Mobi/.test(navigator.userAgent)
        || (/Android/i.test(navigator.userAgent))
        || (/iPhone/i.test(navigator.userAgent))
    ) {
        return true;
    }
    return false;
}

window.isMobile = isMobile;

$mea.ajax.registerRelase('select2mdb', 'select:not(.select2):not(.mea-manual-select):not([data-type="autocomplete"])', (el, selector) => {
    console.log('materialSelect');
    //filter dont work as mdb
    if (el.closest('.sonata-filter-form').length > 0) {
        el.select2({
            // width: 'resolve',
            dropdownAutoWidth : true
        });
        return;
    }

    if($(el).data('mea-selector')==='batch_type'){
        el.select2({
            // width: 'resolve',
            dropdownAutoWidth : true
        });
        return;
    }
    if (el.hasClass('select-initialized')) {
        return;
    }

    if(!$(el).val() && $(el).data('default')){
        // $(el).val($(el).data('default'));
        $(el).find('option[value="'+$(el).data('default')+'"]').attr('selected','selected');
        // $(el).closest('.form_row').find()
        //console.log('set def',$(el).data('default'), $(el).val() ,  $(el).find('option[value="'+$(el).data('default')+'"]'));
    }

    if (typeof $(el).material_select === "function") {
        $(el).material_select();
        $(el).hide();
    }else if (typeof $(el).materialSelect === "function") {
        $(el).materialSelect({

        });
        $(el).hide();
    }else{
        console.log('material_select and materialSelect is not function');
    }


});



$mea.types.register();

$mea.forms.init();

$mea.types.add('listProduct', (element) => {

    let $containerList = $(element);
    $containerList.find('.navigation').hide();
    let infinite = new Waypoint.Infinite({
        element: $containerList.find('[data-mea-selector="containerList"]')[0],
        more: '.navigation a[rel="next"]',
        // offset: '10%',
        items: '[data-mea-type="meaShopProduct"]',
        onBeforePageLoad: () => {
            let url = $('.navigation a[rel="next"]').attr('href');
            url = $mea.ajax.urlReplace(url, 'gettype', null);
            $('.navigation a[rel="next"]').attr('href', url);

            //$el.find('.fa-road').addClass('fa-beat text-danger');
            $mea.messages.indicator(1);
            return false;
        },
        onAfterPageLoad: items => {
            $mea.messages.indicator(0);
            //$el.find('.fa-road').removeClass('fa-beat text-danger');
            $mea.ajax.activateContent(items);
        }
    });

    $containerList.data('infinitie',infinite);

});


$mea.ajax.registerRelase('meaHelpPopover', '.mea-help-btn',
    function(el, selector) {
        $(el).popover({
            trigger: 'manual',
            html: true,
            content:function(){

                if($(this).data('contentwrapper')) return  $($(this).data('contentwrapper')).html();
                return  $(this).data('content');

                let content  = '';
                if($(this).data('contentwrapper')) content =  $($(this).data('contentwrapper')).html();
                content =  $(this).data('content');
                $mea.ajax.activateContent(content);
                return content;

            }
        }).on('mouseenter', function () {
            var _this = this;
            $(this).popover('show');
            $('.popover').on('mouseleave', function () {
                $(_this).popover('hide');
            });
        }).on('mouseleave', function () {
            var _this = this;
            setTimeout(function () {
                if (!$('.popover:hover').length) {
                    $(_this).popover('hide');
                }
            }, 300);
        });
    }
);

$mea.ajax.registerRelase('datepicker', '.datepicker:not([readonly])',
    function(el, selector) {
        $(el).pickadate({
            format: 'yyyy-mm-dd',
        });
    }
);

$mea.ajax.registerRelase('timepicker', '.timepicker:not([readonly])',
    function(el, selector) {
        $(el).pickatime({
            twelvehour: true,
        });
    }
);

$mea.ajax.registerRelase('popoverToogle', '[data-toggle="popover"]',
    function(el, selector) {

        $(el).popover({
            trigger: 'manual',
            html: true,
            content:function(){

                if($(this).data('contentwrapper')) {
                    console.log($(this).data('contentwrapper'),$($(this).data('contentwrapper')).html());
                    return  $($(this).data('contentwrapper')).html();
                }

                return  $(this).data('content');


            }
        }).on('click', function (e) {
            var _this = this;
            $(this).popover('show');
            e.preventDefault();
            $('.popover').on('mouseleave', function () {
                $(_this).popover('hide');
            });
        })
        .on('mouseleave', function () {
            var _this = this;
            setTimeout(function () {
                if (!$('.popover:hover').length) {
                    $(_this).popover('hide');
                }
            }, 300);
        })
        ;
    }
);

