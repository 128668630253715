/**
 * wymaga w szablonie zdefiniowania elementow
 * .mea-btn-filter-criteria-save - wywolanie akcji zapisu
 * .mea-btn-filter-criteria-trash - akcja usuniecia filtru z cookies
 * .mea-select-filter-criteria - select z lista zapisanych kryteriow
 */

export default class FilterCriteria {

    log(...vars){
  // /     console.log('FilterCriteria',...vars);
    }

    constructor(filterBoxVisibilitySwitcher, restoreFilter) {

        this.restoreFilter = restoreFilter;

        this.log('FilterCriteria start', this.restoreFilter, filterBoxVisibilitySwitcher);

        this.filterBoxVisibilitySwitcher = filterBoxVisibilitySwitcher;

        this.dataFilter = {};
        // this.adminCode = filterBoxVisibilitySwitcher.adminCode;

        this.$form = $(filterBoxVisibilitySwitcher.listContainer).find('.sonata-filter-form');
        this.$btnSave = $(filterBoxVisibilitySwitcher.listContainer).find('.mea-btn-filter-criteria-save');
        this.$btnTrash = $(filterBoxVisibilitySwitcher.listContainer).find('.mea-btn-filter-criteria-trash');
        this.$selectList = $(filterBoxVisibilitySwitcher.listContainer).find('.mea-select-filter-criteria');
        this.$container = $('#'+filterBoxVisibilitySwitcher.idFilterContainer);

        const form = this.$form.closest('[data-controller="list-filters"]');

        this.listFiltersController = $mea.application.getControllerForElementAndIdentifier(form[0], "list-filters");

        if(this.listFiltersController){

            this.adminCode = this.listFiltersController.adminCodeValue;

            this.$list = $(this.listFiltersController.element);

        }else{
            console.warn('FilterCriteria listFiltersController empty. giving up', {
                form: this.$form,
                listContainer: filterBoxVisibilitySwitcher.listContainer,
                filterBoxVisibilitySwitcher: filterBoxVisibilitySwitcher,
            })
            return ;
        }

        // this.readData();



        if($mea.user && $mea.user.settings_list && $mea.user.settings_list['filterCriteria']){
            this.log('FilterCriteria ready el',filterBoxVisibilitySwitcher, this);
            this.initSettings($mea.user.settings_list['filterCriteria']);
        }else{
            this.dataFilter[this.adminCode] = {};
            this.log('FilterCriteria ready empty settings',filterBoxVisibilitySwitcher, this);
        }

        $mea.translator.onLoadedLibrary(()=>{
            this.start();
        });



    }

    start(){

        this.log('start' )
        this.$btnSave.on('click', event => this.saveNew('clickSave'));
        this.$btnTrash.on('click', event => this.removeCriteria())
        this.$selectList.on('change', event => this.load(event));
        this.showList();

        if($('[data-toggle="advanced-filter"]').length >0)
            $('[data-toggle="advanced-filter"]').click();


        // this.$selectList.select2({
        //     dropdownAutoWidth: true,
        //     //dropdownParent: this.$selectList.closest('.mea-box-select-filter-criteria')  //$('#filterParent')
        // });

        this.$form.on('click','.mea-btn-reset-filter', e => this.clearSelectedItmeListCriteria());

        this.$form.on('change', 'input, select, textarea', event => {

            this.log('change',event)

            if (!$(event.target).hasClass('mea-select-filter-criteria')) {
                let data = this._getDataFormJson();
                let selected = false;
                for (const name in this.dataFilter[this.adminCode]) {

                    if (this.dataFilter[this.adminCode][name].fields === data) {
                        selected = true;
                        this.$selectList.find('option').prop('selected', false);
                        this.$selectList.find('option[value="' + name + '"]').prop('selected', true);
                        this.$selectList.trigger('change.select2');

                        this.dataFilter[this.adminCode][name].selected = true;
                    }
                }

                if (!selected) {
                    this.$selectList.find('option').prop('selected', false);
                    this.$selectList.trigger('change.select2');

                    this.clearSelectedItmeListCriteria();
                }
            }

        });

        // this.$list.on('itbFilterEvent', e => {
        //
        //     if (_this.getSelectedValue() === 'meaSelectedItem') {
        //         this.dataFilter[this.adminCode]['last used'] = {
        //             selected: true,
        //             fields: this._getDataFormJson()
        //         };
        //
        //         this.saveData('last used');
        //     }
        //
        //     return true;
        // });


    }

    initSettings(settings) {

        let _this = this;

        this.log(' init settings',{
            settings: settings
        });

        $mea.translator.onLoadedLibrary(()=>{

            if(settings)
                this.dataFilter = JSON.parse(settings);


            if (this.dataFilter[this.adminCode] === undefined) {
                this.dataFilter[this.adminCode] = {};
            }

            this.log('settings',{
                'filters':this.dataFilter,
                'adminCode':this.adminCode,
                'current':  this.dataFilter[this.adminCode],
                'form': this.$form})

            // console.log('FilterCriteria settings',this.adminCode,this.dataFilter[this.adminCode]);

            this.$selectList.each((i, e) => {

                let params = {
                    dropdownAutoWidth: true
                };

                if ($(e).data('select-filter') == 'top') {
                    params['dropdownParent'] = $('#filterParent');
                }else {
                    params['dropdownParent'] = $('#filterParent2');
                }

                params['dropdownParent'] = $('body');

                if($('body').hasClass('mdb') && $.fn.materialSelect)
                    $(e).hide().addClass('mdb-select ').materialSelect(params);
                else $(e).select2(params);

                _this.log('filter criteria params',i, e, params);


            });



        });


    }

    clearSelectedItmeListCriteria() {
        for (const name in this.dataFilter[this.adminCode]) {
            this.dataFilter[this.adminCode][name].selected = false;
        }
        
        // this.saveData('clearSelectedItmeListCriteria');
    }

    removeCriteria(event) {


        this.log('removeCriteria');

        let select = this.$selectList.filter(':visible');
        let selectetCriteria = this.getSelectedValue();

        if(!selectetCriteria){
            this.log('removeCriteria selectetCriteria not found',
                {
                    select,
                    selectetCriteria,
                    select2: this.$selectList.select2('data'),
                    selected: this.$selectList.find('option').prop('selected', false)
                } );
            return  false;
        }

        this.log('removeCriteria',select, selectetCriteria, this.$selectList.select2('data') );


        if (selectetCriteria !== 'meaSelectedItem') {

            delete this.dataFilter[this.adminCode][selectetCriteria];
            select.find('option').filter(':selected').remove();
            this.saveData('removeCriteria');
            this.showList();

        }else{
            this.log('removeCriteria fail - meaSelectedItem');
        }

        return false;

    }

    getSelectedValue(){
        // return  this.$selectList.find('option').select2('data');
        return this.selectetCriteria;
    }

    load(event) {

        let selectetCriteria = $(event.currentTarget).find('option').filter(':selected').val();

        this.log('FilterCriteria load',selectetCriteria, event);

        this.selectetCriteria = selectetCriteria;

        if(selectetCriteria==='meaSelectedItem')
            return ;

        this.log('load',selectetCriteria);

        //selected item criteria
        for (const nameCriteria in this.dataFilter[this.adminCode]) {
            this.dataFilter[this.adminCode][nameCriteria].selected = false;
        }

        if (selectetCriteria !== 'meaSelectedItem') {
            this.dataFilter[this.adminCode][selectetCriteria].selected = true;
        }

        this.log('selectedCriteria',this.adminCode,selectetCriteria, this.dataFilter[this.adminCode]);

        this.saveData('load');

        if (selectetCriteria !== 'meaSelectedItem') {
            const fields = this.dataFilter[this.adminCode][selectetCriteria].fields;
            let obj = JSON.parse(fields);
            // this.loadUpdateFormFields(selectetCriteria, fields);
            // this.$form.trigger('submit', [{ forcedValues: fields }]);

            this.log('selectedCriteria load',fields, obj);

            this.listFiltersController.sendFormData(obj, true);

        } else {

            this.log('selectetCriteria reset');

            window.location.href = $('a[href*="list?filters=reset"]').attr('href');
        }


    }


    reset(fieldsCriteria) {
        let fieldsNameCriteria = [];
        for (const nameAttr in fieldsCriteria) {
            if (Array.isArray(fieldsCriteria[nameAttr])) {
                fieldsNameCriteria.push(nameAttr + '[]');
            } else {
                fieldsNameCriteria.push(nameAttr);
            }

        }

        this.$form.serializeArray().forEach(item => {

            if (fieldsNameCriteria.indexOf(item.name) == -1) {
                $('[name="' + item.name + '"]').val('');
            }

        });
    }


    _getDataFormJson(removeEmpty = false) {

        // let dataForm = $mea.forms.formToMultiDimensionObject(this.$form);

        let dataForm = this.listFiltersController.getData();

        this.log('dataForm',removeEmpty,dataForm);

        dataForm = this.nestObject(dataForm);

        delete dataForm.filter._page;
        delete dataForm.filter._per_page;

        return JSON.stringify(dataForm);

    }


    nestObject(flatObject) {
        let result = {};

        for (const key in flatObject) {
            const value = flatObject[key];

            // Remove closing brackets and split by '['
            const path = key.replace(/\]/g, '').split(/\[/);

            let current = result;
            for (let i = 0; i < path.length; i++) {
                const segment = path[i];
                if (i === path.length - 1) {
                    // Assign value in the deepest level
                    current[segment] = value;
                } else {
                    // Create nested object if not exists
                    if (!current[segment]) {
                        current[segment] = {};
                    }
                    current = current[segment];
                }
            }
        }

        return result;
    }



    showList() {

        this._generateList();

        if($(this.$selectList).find('option').length === 1){
            this.$container.find('.mea-box-select-filter-criteria').hide();
            this.$container.find('.mea-btn-filter-criteria-trash').hide();
        }else{
            this.$container.find('.mea-box-select-filter-criteria').show();
            this.$container.find('.mea-btn-filter-criteria-trash').show();
        }


    }

    _generateList() {
        this.$selectList.empty();

        let selectedDefaultItem = true;
        // this.$selectList.append(
        //     '<option disabled title="Select saved criteria" value="meaSelectedItem">'+$mea.translator.trans('list.filters.select_saved')+'</option>'
        // );

        this.$selectList.append(
            '<option value="meaSelectedItem" selected>default</option>'
        );

        for (const name in this.dataFilter[this.adminCode]) {
            let title = name == 'last used' ? $mea.translator.trans('list.filters.last_used') : name;
            let selected = '';
            //load this settings
            if(this.dataFilter[this.adminCode][name].selected && this.restoreFilter !== 'false'){
                 selected = 'selected';
                this.selectetCriteria = name;
                this.log('_generateList set selectetCriteria:', this.selectetCriteria );
            }else{
                this.log('_generateList',name, this.dataFilter[this.adminCode][name]);
            }

            this.$selectList.append('<option value="' + name + '" ' + selected + '>' + title + '</option>');

            if (this.dataFilter[this.adminCode][name].selected) {
                selectedDefaultItem = false;
            }
        }
    }

    saveNew(reason) {
        this.log('save',reason);
        const name = prompt('Enter the name.');

        if (name == null || name === '') {
            alert("You didn't enter the name or canceled the action.");
            return;
        }

        //reset selected filter
        for (const name in this.dataFilter[this.adminCode]) {
            this.dataFilter[this.adminCode][name].selected = false;
        }

        if(!this.dataFilter[this.adminCode])
            this.dataFilter[this.adminCode] = {};

        this.dataFilter[this.adminCode][name] = {
            selected: true,
            fields: this._getDataFormJson()
        };

        this.saveData(reason);
        this.showList();

    }


    saveData(reason) {

        console.log('filterCriteria saveData', this.getSelectedValue(), this.adminCode, reason, this.dataFilter[ this.adminCode]);

        let s = JSON.stringify(this.dataFilter);
        $mea.user.settings_list['filterCriteria'] = s;
        let url = Routing.generate('mea_public_action', {action: 'saveUserDataSettings'});
        $.post(url, {data: s, dataType: 'filterCriteria'});
    }
}


