
//bs 5 import
// import * as bootstrap from 'bootstrap';
// window.bootstrap = bootstrap;

import "./mea.core";
import "./mea.messages";
import "./mea.ajax";
import "./mea.forms";
import "./mea.dialog";
import "./mea.translator";
// import "./../../webpack/MeaHtmlBuilder";
import "./../../webpack/MeaPlatform";
// import "./../../webpack/form/meaMonacoEditor";

import "./../../webpack/MeaHinclude";

import MeaViewport from "./../../webpack/MeaViewport";

MeaViewport.register();

import './../../webpack/inline-admin/InlineAdmin'
import './../../webpack/user/mea.user'
import './../../webpack/user/mea.contact.fields.helper'

// import 'mea-core-js/dist/mea/InlineAdmin/InlineAdmin'
// import 'mea-core-js/dist/mea/user/mea.user'
// import 'mea-core-js/dist/mea/user/mea.contact.fields.helper'

window.$mea = $mea;
//Add if required
// import "./../../../vendor/Mea/CoreBundle/Resources/public/js2/relases_hpl";
// import "./../../../vendor/Mea/CoreBundle/Resources/public/js2/relases_sonata";
// import "./../../../vendor/Mea/CoreBundle/Resources/public/js2/relases_mdb";
// import "./../../../vendor/Mea/CoreBundle/Resources/public/js2/mea_types";
// $mea.forms.init();

window.MeaForm = require('./../../webpack/form/meaform').default;
// import MeaForm from "./../../webpack/form/meaform";

