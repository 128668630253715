/**
 * List form
 * data-mea-type='sonata_list'
 * data-admin-code='mea.task.task.admin'
 *
 * todo
 * data-mea-type='sonata_edit'
 * data-admin-code='mea.task.task.admin'
 *
 */

export default class ListColumnsVisibilitySwitcher {

    constructor(el)
    {
        this.dataSettings = {};
        this.$container = $(el);
        this.uniqid = $(el).data('uniqid');
        this.$table = $('#sonata_list_'+this.uniqid).find('table.sonata-ba-list');
        this.columns = [];
        this.adminCode = $('#sonata_list_'+this.uniqid).data('admin-code');

        if (this.$table.length === 1) {
            // console.log([
            //     $mea.user,
            //     $mea.user.config,
            //     $mea.user.settings_list,
            //     //$mea.user.settings_list['listColumnsVisibilitySwitcher']
            // ]);


            setTimeout(() => {
                if($mea.user && $mea.user.settings_list && $mea.user.settings_list['listColumnsVisibilitySwitcher']){
                    this.init($mea.user.settings_list['listColumnsVisibilitySwitcher']);
                }else{
                    // console.log('ListColumnsVisibilitySwitcher init fail');
                }
            }, 1000);
        } else {
            // console.log('ListColumnsVisibilitySwitcher hide',this);
            this.$container.hide();
        }
    }

    init(settings)
    {


        this.dataSettings = JSON.parse(settings);
        // console.log('ListColumnsVisibilitySwitcher init',settings);

        if (this.dataSettings[this.adminCode] === undefined) {
            this.dataSettings[this.adminCode] = [];
        }

        this.$table.on('change',()=>{
            // console.log('ListColumnsVisibilitySwitcher change');
            this.readColumns();
            this.$container.find('.sonata-filter-count').text(this.dataSettings[this.adminCode].length);
            this.generateList();
            this.$container.show();
        });
        this.readColumns();
        this.$container.find('.sonata-filter-count').text(this.dataSettings[this.adminCode].length);
        this.generateList();
        this.$container.show();

    }

    readDataSettings()
    {
        let url = Routing.generate('mea_public_action', {
            action: 'getUserDataSettings',
            dataType: 'listColumnsVisibilitySwitcher'});
        $.post(url, {}, res => {
            // if (res.status === true && res.listColumnsVisibilitySwitcher != '') {
            //     this.dataSettings = JSON.parse(res.listColumnsVisibilitySwitcher);
            // }
            //
            // if (this.dataSettings[this.adminCode] === undefined) {
            //     this.dataSettings[this.adminCode] = [];
            // }

            this.init(res.listColumnsVisibilitySwitcher);
        });

    }

    saveDataSettings()
    {
        let url = Routing.generate('mea_public_action', {action: 'saveUserDataSettings'});
        for (let i=0; i < this.dataSettings[this.adminCode].length; i++ ) {
            if (this.dataSettings[this.adminCode][i] == null) {
                this.dataSettings[this.adminCode].splice(i, 1);
                i--;
            }
        }
        let s = JSON.stringify(this.dataSettings);
        $mea.user.settings_list['listColumnsVisibilitySwitcher'] = s;
        $.post(url, {data: s, dataType: 'listColumnsVisibilitySwitcher'});
    }

    readColumns()
    {
        this.$table.find('thead > tr > th').each((index, value) => {
            let $element  = $(value);
            this.columns.push($element);
        });
    }

    generateList()
    {
        this.$container.find('ul.dropdown-menu').empty();

        this.columns.forEach(($value, index) => {
            if (!$value.hasClass('sonata-ba-list-field-header-batch')) {
                let li = $('<li />');
                let a = $('<a href="#" class="sonata-toggle-filter sonata-ba-action dropdown-item" data-mea-index-column="'+index+'" />');

                if (this.isHidden(index)) {
                    a.html('<i class="fa fa-check-square-o"></i>'+$value.text().trim());
                    this._visibilityColumn(index, false);
                }else {
                    a.html('<i class="fa fa-square-o"></i>'+$value.text().trim());
                }

                a.on('click', (event) => this.toogleVisibility($(event.currentTarget)));

                li.append(a);
                this.$container.find('ul.dropdown-menu').append(li);
            }

        });
    }

    _visibilityColumn(indexColumn, show=true)
    {
        let th = this.$table.find('tr > th').eq(indexColumn);

        if (show)
            th.show();
        else
            th.hide();

        this.$table.find('tbody > tr').each((index, value) => {
            $(value).find('td').each((index, value) => {
                if (index === indexColumn) {
                    if (show)
                        $(value).show();
                    else
                        $(value).hide();
                }
            });
        });

        this.$table.find('tfoot > tr').each((index, value) => {
            $(value).find('th').each((index, value) => {
                if (index === indexColumn) {
                    if (show)
                        $(value).show();
                    else
                        $(value).hide();
                }
            });
        });
    }

    toogleVisibility($item)
    {
        let $fa = $item.find('.fa');
        let indexColumn = parseInt($item.data('mea-index-column'));

        if (this.isHidden(indexColumn)){
            $fa.removeClass('fa-check-square-o').addClass('fa-square-o');
            this.removeFromDataSettings(indexColumn);

            this._visibilityColumn(indexColumn, true);

        }else {
            $fa.removeClass('fa-square-o').addClass('fa-check-square-o');
            this.addToDataSettings(indexColumn);

            this._visibilityColumn(indexColumn, false);
        }

        this.$container.find('.sonata-filter-count').text(this.dataSettings[this.adminCode].length);


        this.saveDataSettings();
    }

    isHidden(item)
    {
        if(!this.dataSettings[this.adminCode]) return false;
        return this.dataSettings[this.adminCode].indexOf(item) != -1;
    }

    removeFromDataSettings(item)
    {
        this.dataSettings[this.adminCode] = this.dataSettings[this.adminCode].filter((value) => {
            return value != item;
        });
    }

    addToDataSettings(item)
    {
        this.dataSettings[this.adminCode].push(item);
    }

}
