export default class Tools {
    static formatMoney(price)
    {
        return (Tools.parseFloat(price)).toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
        })
    }

    static generateUniqId()
    {
        return Tools.parseInt(Date.now() + Math.random());
    }

    static formatNumber(number, scale=2)
    {
        return Tools.parseFloat(number).toFixed(scale).replace('.', ',');
    }

    static priceInt(price)
    {
        return Tools.parseInt(price * 100);
    }

    static getInput(container, name)
    {
        return container.find('[name*="['+name+']"]');
    }

    static parseFloat(string)
    {
        let out = parseFloat(String(string).replace(',', '.'));
        return Number.isNaN(out) ? 0 : out;
    }

    static parseInt(string)
    {
        let out = parseInt(string);
        return Number.isNaN(out) ? 0 : out;
    }


    /**
     * convert url params to object
     * https://www.sitepoint.com/get-url-parameters-with-javascript/
     * var url = 'aa?product=test' -> mea.ajax.getAllUrlParams(url).product give test
     * @param url
     */
    static getAllUrlParams(url) {

        // get query string from url (optional) or window
        var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

        // we'll store the parameters here
        var obj = {};

        // if query string exists
        if (queryString) {

            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0];

            // split our query string into its component parts
            var arr = queryString.split('&');

            for (var i=0; i<arr.length; i++) {
                // separate the keys and the values
                var a = arr[i].split('=');

                // in case params look like: list[]=thing1&list[]=thing2
                var paramNum = undefined;
                var paramName = a[0].replace(/\[\d*\]/, function(v) {
                    paramNum = v.slice(1,-1);
                    return '';
                });

                // set parameter value (use 'true' if empty)
                var paramValue = typeof(a[1])==='undefined' ? true : a[1];

                // (optional) keep case consistent
                paramName = paramName.toLowerCase();
                paramValue = paramValue.toLowerCase();

                // if parameter name already exists
                if (obj[paramName]) {
                    // convert value to array (if still string)
                    if (typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                    }
                    // if no array index number specified...
                    if (typeof paramNum === 'undefined') {
                        // put the value on the end of the array
                        obj[paramName].push(paramValue);
                    }
                    // if array index number specified...
                    else {
                        // put the value at that index number
                        obj[paramName][paramNum] = paramValue;
                    }
                }
                // if param name doesn't exist yet, set it
                else {
                    obj[paramName] = paramValue;
                }
            }
        }

        return obj;
    }

    static urlAdd(url, add) {

        if(typeof url == "undefined") url = '';
        if (url.indexOf('?') == -1)
            url += '?' + add;
        else
            url += '&' + add;
        return url;
    }

    static round(number, x) {
        var x = (!x ? 2 : x);
        return Math.round(number * Math.pow(10, x)) / Math.pow(10, x);
    }

    static number_format(number, decimals, dec_point, t_sep)
    {
        var intstr;
        var output = '';

        if (typeof(decimals) == 'undefined')
            decimals = 2;

        if (typeof(dec_point) == 'undefined')
            dec_point = ' ';

        if (typeof(t_sep) == 'undefined')
            t_sep = '';

        // czesc calkowita
        int = parseInt(number);

        // czesc ulamkowa
        float = parseInt((parseFloat(number) - int) * Math.pow(10, decimals));

        intstr = int + '';

        j = intstr.length;

        if ((i = j % 3) != 0)
            output += intstr.substring(0, i);

        while (i < j)
        {
            output += dec_point + '' + intstr.substring(i, i + 3);
            i += 3;
        }

        if (float > 0)
            output += t_sep + '' + float;

        return(output);
    }

    static getElementMeaSelector(selectorName) {
        return $('[mea-selector="'+selectorName+'"]');
    }

}
