/**
 * https://www.npmjs.com/package/raty-js
 * npm i raty-js --save
 *
 * app.js import 'raty-js'
 * app.js import 'raty-js'
 *
 */

$mea.types.add('email',(el)=>{
    $(el).attr('data-controller','email-widget');
    // let email = atob($(el).data('value'));
    // $(el)
    //     .html(email)
    //     .attr('title',$mea.translator.trans('Click to send email'))
    //     .data('value',null)
    //     .attr('href','mailto:'+email);
});

$mea.types.add('rating_star_widget',function(el){

    let score = $(el).data('score');
    if(!score){
        score = $(el).find('input[type=hidden]').val();
    }

    let config = {
        starType: 'i' ,
        score: score ,
        number: '5' ,
        // hints: ['Zła', 'Słaba', 'Średnia', 'Dobra', 'Wspaniała'],
        hints: [' ', ' ', ' ', ' ', ' '],
        readOnly: $(el).data('readonly') ,
        half: $(el).data('half'),
        precision  : $(el).data('half'),
        round      : { down: .25, full: .6, up: .76 },
        click: function(score, evt) {
            score = Math.round(score*2)/2;
            $('#'+$(el).data('id')).val(score);
        }
    };
    console.log('rating_star_widget',config);

    $(el).find('.ratting_bar').raty(config);
});

import 'readmore-js'
$mea.types.add('readmore',function(el){
    $mea.translator.onLoadedLibrary(()=>{
        $(el).readmore({
            lessLink: '<a class="read_more_btn pt-2" title="'+$mea.translator.trans('Collapse')+'" href="#"><i class="fa fa-angle-up"></i></a>',
            moreLink: '<a class="read_less_btn pt-2" title="'+$mea.translator.trans('Expand')+'" href="#"><i class="fa fa-angle-down"></i> </a>',
            embedCSS: true,
        }).css('overflow-y','hidden');


    });
});