// require('jquery');
// require('./mea.core');
// require('./mea.dialog');

$mea.ajax = {
    _v:20200927,
    events: {
        beforeLocationChange: 'beforeLocationChange' ,
        afterLocationChange: 'afterLocationChange' ,
        afterBodyReady: 'afterBodyReady' ,
        afterContentChanged: 'afterContentChanged' ,
        afterMeaContentChanged: 'afterMeaContentChanged' ,
    },
    selector_default: '#meaContent',
    cacheDefault: 'noCache', //cache  | noCache = $mea.ajax.cacheDefault='cache';
    dialogTitleSelector: 'div.title',//'.ui-widget-header',
    cachelist: {}, // [url]='allow' | 'noAllow'
    cache: [],
    validating: 0, //liczba aktywnych requestów podczas ładowania ajax - do limitowania wykonywanych procesów
    writecapture: false, //writeCapture2 powoduje zniszczenie albo podrówje przekodowanie encji textarea zawierający tagi html zostaje zniszczone
    curentAdress: '',
    // disableElementBody: '<div class="disableElementMask" style="display: flex; align-items: center; justify-content: center; position: absolute;top:0;left:0;width: 100%;height:100%;"><div  style="background-color: white;position: absolute;top:0;left:0;width: 100%;height:100%; z-index:2;opacity:0.6;filter: alpha(opacity = 50)"></div><p style="z-index: 3; margin: auto" class="meaindicator"><i class="fa fa-spinner fa-3x fa-spin" aria-hidden="true"></i></p></div>',
    disableElementBody: ()=>{
        return '<div class="disableElementContainer" style="min-height: 200px"><div class="disableElementMask" style="display: flex; align-items: center; justify-content: center; position: absolute;top:0;left:0;width: 100%;height:100%;"><div  style="background-color: white;position: absolute;top:0;left:0;width: 100%;height:100%; z-index:2;opacity:0.6;filter: alpha(opacity = 50)"></div><div style="z-index: 3; margin: auto" class="meaindicator">'+
            $($mea.messages.indicator_html).html()+
            '</div></div></div>';
    },
    hashlocations:{}, // /noclegi -> #divNoclegi itd- do ustawiania pozycji przy powtornych klikach w link
    releases: {},
    releasesDef: false,
    contentisActivatedOnce : false, //1 pętla akywacji - za pierwszym razem ustawia się na true i tak pozostaje
    contentisActivated : false, //czy aktywowano już treść (jak dodaje się wyzwalacz trzeba ponownie aktywowac)
    contentisActivating: false, //prevent infinity loop
    contentisActivatingReloadRequest: false, //requeue
    evalNow: 1, //zezwolenie na natychmiastowe wykonane - blokowane jeżeli są doczytywane js - pierwsze one potem eval
    eval_loaded: '', // kod js do wykonania po załadowaniu
    finishedCalls: 0, // limitowanie ?
    debug: false,
    contentChange: false, //true po dodaniu treści na stronie - do wykrywania czy potrzeba scrolować
    scroolDisableForThisResponse : false, //zablokowane scrolowanie tylko dla tej odpowiedzi
    animateToContent : true, // czy scrolować do nowo wczytanej treści
    locationChangeFadeOut : true, //animate location change
    headerStartScrol: 40, //wysokość pikseli - jeżeli strona jest powyżej tej wartości po doładowaniu - zostanie doscrolowana do tej wartości
    topPositionMargin :20, //przesuniecie do tresci o tyle px wyzej
    jsStack : [], //kod js do wykonania - po umieszczniu bloków
    /**
     *default overrided in meaVars.js form core template loading
     */
    template_loading: '<i class="fas fa-sync fa-spin"></i>',

    log: function(){
        if(this.debug)
        console.log('mea.ajax',arguments)
    },

    registerMeaHplLinks: function(){
        $(()=>{
            $mea.ajax.initAddress();
            //use relases_hpl
            // $mea.ajax.registerRelase('href_mea_hpl', 'a:not(.noHpl)',
            //     function(el, selector) {
            //         return $mea.ajax.hrefMeaHplInit(el);
            //     }
            // );
        });

    },

    hrefMeaHplInit: function($el){
        let url = $el.attr('href');
        if(!url) url = $el.data('href');
        if(!url
            || url ==='#'
            || url.charAt(0)==='#'
            || (url.charAt(0)!=='/' && !url.includes(window.location.host) )) {
            // console.log('ignored ',url, $el);
            //$el.addClass('noHpl');
            this.log('meaHpl ignore - url not acceptable',url, $el);
            $el.data('hpl-ignored',1);
            return false;
        }

        // $el.addClass('DDD');
       // console.log('hpl ',url, $el);


        let onClick = '';
        if($el.attr('onClick') && $el.data('hpl-allow-onlick')!='1' ){
            onClick = $el.attr('onClick');
            this.log('meaHpl ignore - found onclick',$el,onClick);
            return false;
            //example return start_field_dialog_form_list_sa63c28033b_worker(this) - not work
        }

        if($el.attr('target')==='_blank'){
            this.log('meaHpl ignore - target _blank',$el);
            return false;
        }


        if($el.data('rel')==='stay-in-dialog'){
            let $parentDialog = $el.closest('.meaDialog');
            if($parentDialog.length > 0){

                $el.data('rel','dialog');
                $el.data('dialog-id',$parentDialog.closest('[role=dialog]').attr('id'));
                // console.log('stay-in-dialog set dialog',$el.data('dialog-id'));
            }
        }else if($el.data('rel')==='dialog-if-in-dialog'){
            $el.data('rel','dialog');
            // console.log('dialog-if-in-dialog set dialog',$el);
        }


        $el.click((event)=> {

            this.log('hpl click event',$el);

            if($el.hasClass('noHpl')){
                this.log('has hpl and noHpl',$el);
                return ;
            }

            switch($el.data('rel')){
                case 'dialog':
                    return this.hrefDialogClick($el,event);

                case 'ajax':
                    return this.hrefAjaxClick($el,event);

                default:
                    return this.hrefHistoryClick($el,event);

            }



        });

        $el.addClass('hpl_initialised');

    },

    hrefAjax: function(el){

        $(el).addClass('ajaxed');

        // let click = $(el).prop('click');

        $(el).click((event)=> {
            return this.hrefAjaxClick(el,event);
        });

        return true;

    },

    hrefAjaxClick: function(el,event){

        event.preventDefault();

        let params = {};

        params.el = el;

        let url = $(el).attr('data-href');

        if(!url) url = $(el).attr('href');

        if($(el).attr('data-message') && $(el).attr('data-time-message')){
            let countNumMessage = $mea.messages.add($(el).attr('data-message'), $(el).attr('data-time-message'));
            url = $mea.ajax.urlAdd(url,'countNum='+countNumMessage);
        } else if ($(el).attr('data-time-message')) {
            let countNumMessage = $mea.messages.add($(el).attr('data-message'));
            url = $mea.ajax.urlAdd(url,'countNum='+countNumMessage);
        }

        if($(el).attr('data-urltarget')){

            // console.log('ajax click',$(el).attr('data-urltarget'));

            if($(el).attr('data-urltarget') ==='dialog'){

                $mea.dialog.createFromUrl(url);
                // console.log('ajax dialog');

                return false;
            }else{
                url = $mea.ajax.urlAdd(url,'urltarget='+$(el).attr('data-urltarget'));
            }


        }

        if ($(el).data('prompt')) {

            let question = prompt($(el).data('prompt'));

            console.log('prompt', question);

            if (question != null) {
                $mea.ajax.load(url, {
                    prompt: question
                },params);
            }

        }else if ($(el).hasClass('confirm')) {
            let mess;
            if($(el).attr('data-confirm'))
                mess = $(el).attr('data-confirm');


            if ($mea.messages.confirm(mess, ()=>{
                $mea.ajax.load(url,false,params);
            }));

        } else {
            $mea.ajax.load(url,false,params);
        }

        $("body").trigger("click");

    },

    hrefDialog: function(el){

        $(el).addClass('dialoged');

        $(el).click((e) =>{

            return this.hrefDialogClick(el,e);

        });

        return true;

    },

    hrefDialogClick: function(el,e){

        e.stopPropagation();

        let url ='';

        if($(el).attr('data-href'))
            url = $(el).attr('data-href');
        else url = $(el).attr('href');

        let dialogParams = {};

        let dialogHtml = '';
        let dialogWidth = '800';
        let dialogheight = '600';


        if ($(el).attr('dialogW')){

            dialogParams.width=$(el).attr('dialogW');
            dialogWidth = $(el).attr('dialogW');

        }

        if ($(el).attr('dialogH')){
            dialogParams.height=$(el).attr('dialogW');
            dialogheight = $(el).attr('dialogH');
        }

        if ($(el).data('dialog-class')){
            dialogParams.class=$(el).data('dialog-class');
         }

        if ($(el).data('dialog-id')){
            dialogParams.id=$(el).data('dialog-id');
        }else{
            dialogParams.id ='dialog';
        }

        if ($(el).data('dialog-title')){
            dialogParams.title=$(el).data('dialog-title');
        }

        if ($(el).data('dialog-closebottombtnlabel')){
            dialogParams.closeBottomBtnLabel=$(el).data('dialog-closebottombtnlabel');
        }

        if ($(el).data('dialog-no-close-icon-top')){
            dialogParams.noCloseIconTop = $(el).data('dialog-no-close-icon-top');
        }


        if($(el).closest('#'+dialogParams.id).length===1){

            console.log('oldDialog',{
                oldDialog: $(el).closest('#'+dialogParams.id)
            });

            $mea.dialog.remove(dialogParams.id);

            // let oldDialog = $(el).closest('#'+dialogParams.id).find('.meaDialog');
            //
            // oldDialog.closest('[role="dialog"]').remove();
            //not work - new shadow duplicated
            // oldDialog.slideUp("slow",()=>{
            //     oldDialog.closest('[role="dialog"]').remove();
            // });
        }


        if ($(el).data('dialog-iframe')) {
            $mea.dialog.createFromUrlIframe(url, dialogParams);
            return false;
        }

       // console.log('dialogParams',dialogParams);

        if ($(el).hasClass('confirm')) {
            let mess;
            if($(el).attr('data-confirm'))
                mess = $(el).attr('data-confirm');


            if ($mea.messages.confirm(mess, ()=>{

                //  console.log('dialogParams',dialogParams);
                $mea.dialog.createFromUrl(url, dialogParams);
                // $mea.ajax.load(url);

            }));

        }else{

            $mea.dialog.createFromUrl(url, dialogParams);

        }

        return false;

    },

    hrefHistory: function(el) {

        let url = $(el).attr('href');
        if(!url
            || url ==='#'
            || url.charAt(0)==='#'
            || (url.charAt(0)!=='/' && !url.includes(window.location.host) )) {
            return false;
        }


        let onClick = '';
        if($(el).attr('onClick')){
            onClick = $(el).attr('onClick');
            // console.log('meaHpl ignore - found onclick',onClick);
            return false;
            //example return start_field_dialog_form_list_sa63c28033b_worker(this) - not work
        }

        if($(el).attr('target')==='_blank')
            return false;

        //prevent from click in checkbox indside a
        let elFor = $(el).attr('for');
        if(elFor && $('#'+elFor).attr('type')==='checkbox'){
            $('#'+elFor).prop('checked',!$('#'+elFor).prop('checked')).change();
            e.stopPropagation();
            return false;
        }

        $(el).click((e)=>{

            return this.hrefHistoryClick(el,e);

        });

        return true;
    },

    hrefHistoryClick: function(el,e) {
        e.preventDefault();
        let url = $(el).attr('href');

        if (typeof url === "undefined" || typeof url === null){
            return;
        }

        if ($(el).attr('data-urltarget')) {
            url = $mea.ajax.urlAdd(url, 'urltarget=' + $(el).attr('data-urltarget'));
        }

        //allow, not-allow
        if ($(el).attr('data-cache')) {
            $mea.ajax.cachelist[url] = cache;
        }

        if ($(el).hasClass('confirm')) {

            let mess;
            if($(el).attr('data-confirm'))
                mess = $(el).attr('data-confirm');

            $mea.messages.confirm(mess,()=>{
                $mea.ajax.locationChange( url );
            });

            return false;

        } else {

            //$(el).attr('onClick', 'window.location="#' + url + '"; return false;');

            // $(el).attr('onClick', onClick+'$mea.ajax.locationChange("' + url + '"); return false;');

            $(el).addClass('historied');

        }

        $("body").trigger("click");

        e.preventDefault();

        $mea.ajax.locationChange( url );


        return false;

    },

    onChangeAddress: function(hash){
        this.log('location change onChangeAddress ',hash);
        return false
    },

    locationChange : function(href,options){

        if(!options) options = {};

        let tagetContainerSelector;
        if(options.headers && options.headers.urltarget)
            tagetContainerSelector= '#'+options.headers.urltarget;
        else tagetContainerSelector = '#meaContent'

        this.log('location change',href,tagetContainerSelector, options);

        $('body').trigger(jQuery.Event( $mea.ajax.events.beforeLocationChange ,{
            newUrl: href,
        }));
        let loaded = false;

        // console.log('locationChangeFadeOut', tagetContainerSelector);
        $mea.messages.indicator(1);

        if($mea.ajax.locationChangeFadeOut){
            $(tagetContainerSelector).fadeOut(400, ()=> {
                 // console.log('locationChangeFadeOut complete', tagetContainerSelector);
                if(false === loaded){
                    $mea.ajax.loadingElement(tagetContainerSelector);
                    // $(tagetContainerSelector).fadeIn();
                     // $('#meaContent').html($(this.template_loading)).fadeIn();
                }

            });;
        }

        // console.log('location change');

        if(href === '/' && this.cache["/"]){
            console.log('restored from cache');
            $( tagetContainerSelector ).html($mea.ajax.cache["/"]);
            return ;
        }

        if(window.location.pathname === '/' && !this.cache["/"]){
            //cache disabled for refactor
            // this.cache["/"] = $('#meaContent').clone();
        }

        if(href && href.substring(0, 4)==='http' && !this.isSameDomainHost(href)){
            this.log('location change absolute ',href);
            window.location.href = href;
            return ;
        }

        //Failed to execute 'pushState' on 'History' error when using window.history.pushState function when many params task list with filters

        $mea.ajax.previousUrl = href;
        window.history.pushState({
            url: href
        }, null, href);

        if(href==='/'){
            window.location.reload();
        }else{
            //console.log('pushState',href);
            $($mea.ajax.load(href,null,{
                headers: options.headers
            })).on('onSuccess',()=>{

                // console.log('onSuccess');
                //sometimes - monnti company edit tabs after load content is not visible - need .show

                $mea.messages.indicator(0);

                if($mea.ajax.locationChangeFadeOut){
                    loaded = true;
                    $(tagetContainerSelector).addClass('show').fadeIn();
                    $mea.ajax.loadingElementEnd(tagetContainerSelector);
                }


                $('body').trigger(jQuery.Event( $mea.ajax.events.afterLocationChange ,{
                    newUrl: href,
                }));
            });
        }

    },

    initAddress: function(){
        // console.log('initAddress');

        $mea.ajax.previousUrl = location.pathname;

        window.addEventListener('locationchange', (e)=>{
            this.log('location changed!', document.location, e);
        })
        let meaAjax = this;

        history.pushState = ( f => function pushState(){

            if(arguments[2].split('#')[0] ===  location.href){
                $mea.ajax.locationChangeHash = true;
            }else{
                $mea.ajax.locationChangeHash = false;
                window.dispatchEvent(new Event('locationchange'));
            }

            meaAjax.log('auto history.pushState', location.href, arguments[2] , arguments);

            let ret = f.apply(this, arguments);
            // meaAjax.log('auto history.pushState', location.href, arguments, location.pathname );

            window.dispatchEvent(new Event('pushstate'));


            return ret;


        })(history.pushState);

        history.replaceState = ( f => function replaceState(){
            meaAjax.log('history.replaceState');
            let ret = f.apply(this, arguments);
            window.dispatchEvent(new Event('replacestate'));
            window.dispatchEvent(new Event('locationchange'));
            return ret;
        })(history.replaceState);

        window.addEventListener('popstate',(e)=>{
            // this.log('history.popstate',e);
            // window.dispatchEvent(new Event('locationchange'))
        });

        $(window).on("popstate", (e)=> {

            if($mea.ajax.locationChangeHash === true){
                this.log('locationChangeHash ignoring');
                return ;
            }

            let popped = ('state' in window.history && window.history.state !== null), initialURL = location.href;

            if(e.originalEvent.state){
                if(!e.originalEvent.state.url)
                    e.originalEvent.state.url = document.location.href;
            }


            this.log('onPopstate',{
                meaPreviousUrl: $mea.ajax.previousUrl,
                locationPathname: location.pathname,
                 e_originalEvent_state: e.originalEvent.state,
                initialURL: initialURL,
                 loc: "popstate location: " + document.location + ", state: " + JSON.stringify(event.state),
                 popped: popped,
                history: window.history,
                e: e
            });

            //|| window.history.state === null
            if(e.originalEvent.state !== null || location.pathname!==$mea.ajax.previousUrl ){

                // console.log('asa',e.originalEvent.state , window.history.state);

                $mea.ajax.locationRestore(e.originalEvent.state);

            }else{

                this.log('popstate null ', window.location.pathname, history.state, e);

            }

        });

        // window.addEventListener("beforeunload", function (event) {
        //     console.log('beforeunload',window.location,event);
        // });
        //

    },

    locationRestore: function(state){

        if(typeof state === 'undefined' ){
            this.log('locationRestore state undefined');
            return ;
        }

        if(state === null) { // initial page

             let fullpath = window.location.pathname + window.location.search;

            if(this.cache[fullpath]){

                //console.log('location restore cache ',fullpath);
                $( '#meaContent' ).html($mea.ajax.cache[fullpath]);

            }else{

                this.log('cache location restore empty state ',fullpath);

                $('#meaContent').fadeOut();

                $mea.ajax.load(fullpath).on('onSuccess',()=>{
                    $('#meaContent').fadeIn();
                    $('body').trigger(jQuery.Event( $mea.ajax.events.afterLocationChange ,{
                        newUrl: fullpath,
                    }));
                });

            }
            // window.location.reload();

        } else { // page added with pushState

            let  url = state.url;

            $('body').trigger(jQuery.Event( $mea.ajax.events.beforeLocationChange ,{
                newUrl: url,
            }));

            if(url==='/'){

                if(this.cache["/"]){
                    $( '#meaContent' ).html($mea.ajax.cache["/"]);
                }else{
                    window.location.reload();
                }
                return ;
            }
            if(!url){
                this.log("locationRestore empty url",state);
                return ;
            }
            // console.log('load 2',url);
            $('#meaContent').fadeOut();

            $mea.ajax.load(url).on('onSuccess',()=>{
                $('#meaContent').fadeIn();
                $('body').trigger(jQuery.Event( $mea.ajax.events.afterLocationChange ,{
                    newUrl: url,
                }));
            });

        }
    },

    //get param from url aaa=1 urlParam('aaa')
    urlParam: function(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results==null) {
            return null;
        }
        return decodeURI(results[1]) || 0;
    },

    urlAdd: function (url,add){
        if(!add) return url;
        if(typeof url === "undefined"){
            this.log('mea.ajax.urlAdd empty url',add);
            return;
        }
        if(typeof add == "object") add = new URLSearchParams(add).toString();

        if(url.indexOf('#') !== -1 ) {
            url = url.substring(0, url.indexOf("#"));
        }

        if(add.indexOf('?') !== -1) add = add.replace('?','');


        if(url.indexOf('?') === -1 ) url += '?'+add;
        else {

            url += '&'+add;
        }
        return url;
    },

    urlRemove: function(url, paramName, paramValue){

        if(!url || url==='')
            url = window.location.href;

            // paramValue = '';

        if(paramValue === null)
            paramValue = '';

        let pattern = new RegExp('\\b('+paramName+'=).*?(&|$)')

        if(url.search(pattern)>=0){
            this.log('url found s',paramName,url);
            return url.replace(pattern,'$1' + paramValue + '$2');
        }
        url = url.replace(/\?$/,'');

        return url;
    },

    urlReplace: function(url, paramName, paramValue){

        if(!url || url==='')
            url = window.location.href;

        if(paramValue == null)
            paramValue = '';

        let pattern = new RegExp('\\b('+paramName+'=).*?(&|$)')

        // console.log('replae',paramName,paramValue);

        if(url.search(pattern)>=0){
            // console.log('url found s',paramName,url);
            return url.replace(pattern,'$1' + paramValue + '$2');
        }
        url = url.replace(/\?$/,'');

        url = this.urlAdd(url,paramName + '=' + paramValue);
        //url =  url + paramName + '=' + paramValue
        // url =  url + (url.indexOf('?')>0 ? '&' : '') + paramName + '=' + paramValue

        //$mea.log('replace',vas,paramName, paramValue,url);
        //console.log('url not found s',paramName,url);

        return url;

    },

    isSameDomainHost(urlString){
        let url = new URL(urlString);
        if(
            url.protocol !== window.location.protocol
            || url.hostname !== window.location.hostname
        ){
            return false
        }

        return true
    },

    getStringUrlDomain(urlString) {

        let url = urlString;
        let { hostname } = new URL(url);
        return hostname;
        // let a= document.createElement('a');
        // a.href = urlString;
        // return a.hostname;
    },

    randomString: function (){

        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        let noCache ='';
        for( let i=0; i < 7; i++ )
            noCache += possible.charAt(Math.floor(Math.random() * possible.length));
        return noCache;
    },

    locationHashToArray: function(){

        let hash = window.location.hash.slice(1);
        let array = hash.split("&");

        let values, form_data = {};

        for (let i = 0; i < array.length; i += 1) {
            values = array[i].split("=");
            form_data[values[0]] = values[1];
        }
        return form_data;
    },

    /**
     * https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
     * available multidimension arrays with keys
     * @param query
     * @returns {{}}
     * @constructor
     */
    QueryStringToArray: function(query){

        query = query.substring(query.indexOf('?') + 1);

        var re = /([^&=]+)=?([^&]*)/g;
        var decodeRE = /\+/g;

        var decode = function (str) {
            return decodeURIComponent(str.replace(decodeRE, " "));
        };

        var params = {}, e;
        while (e = re.exec(query)) {
            var k = decode(e[1]), v = decode(e[2]);
            if (k.substring(k.length - 2) === '[]') {
                k = k.substring(0, k.length - 2);
                (params[k] || (params[k] = [])).push(v);
            }
            else params[k] = v;
        }

        var assign = function (obj, keyPath, value) {
            var lastKeyIndex = keyPath.length - 1;
            for (var i = 0; i < lastKeyIndex; ++i) {
                var key = keyPath[i];
                if (!(key in obj))
                    obj[key] = {}
                obj = obj[key];
            }
            obj[keyPath[lastKeyIndex]] = value;
        }

        for (var prop in params) {
            var structure = prop.split('[');
            if (structure.length > 1) {
                var levels = [];
                structure.forEach(function (item, i) {
                    var key = item.replace(/[?[\]\\ ]/g, '');
                    levels.push(key);
                });
                assign(params, levels, params[prop]);
                delete(params[prop]);
            }
        }
        return params;
    },


    //todo:mea - parse hpl response
    loadVars: async function(url, data, options){

        if(typeof options.type ==='undefined' || !options.type){
            options.type = 'GET';
        }

        // console.log('loadVars',{
        //     url, data, options
        // });
        try {
            let res = await $.ajax({
                url: url,
                type: options.type,
                data: data,
                dataType: 'html',
            });

            return res;
        } catch (error) {
            console.error(error);
            return error.responseText;
        }

        // var result = null;
        // $.ajax(
        //     {
        //         url: url,
        //         type: 'get',
        //         dataType: 'html',
        //         async: false,
        //         cache: false,
        //         success: function(data)
        //         {
        //             result = data;
        //         }
        //     }
        // );
        // return result;

    },

    /**
     * @example $mea.ajax.loadScript(calendarSrc).then(()=>{
     *             this.fullCalender();
     *         });
     * @param url
     * @returns {Promise<unknown>}
     */
    loadScript: async function(url){

        return new Promise(async(resolve, reject)=> {

            let script;
            const scripts = Array.from(document.querySelectorAll('script'));
            const existingScript = scripts.find((script) => script.getAttribute('src') === url);
            if (existingScript) {
                resolve();
                //existingScript not fire onload
                return ;
                // console.log('existingScript',url);
                //script = existingScript;
            } else {
                // console.log('createScript',url);
                script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                document.getElementsByTagName('head')[0].appendChild(script);
            }

            if (script.readyState) {
                // console.log('script.readyState',script.readyState);
                script.onreadystatechange = () => {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null;
                        resolve();
                    }
                };
            } else {
                script.onload = () =>  resolve();
            }

        });
    },


    /**
     * $mea.ajax.load(url).on('onSuccess',(event,parsed)=>{ console.log(parsed.data, parsed.blocks ); })
     * $mea.ajax.load(url).on('onFinish',(data,parsed)=>{ console.log(parsed.data, parsed.blocks ); })
     * @param url
     * @param data
     * @param options
     * @returns {jQuery|HTMLElement}
     */
    load: function(url, data, options) {

        // console.log('mea.ajax load',url);
        if(!options)
            options = {};

        if(options.urltarget ==='_blank'){
            let queryString = '';
            if(data){
                queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
            }

            window.open($mea.ajax.urlAdd(url,queryString), '_blank');
            return ;
        }

        options.indicator = 1;
        options.gettype ='hpl';
        options.data = data;

        return this.run(url,options);
    },

    /**
     * backend do ajax - wykonuje w tle wczytanie używać tylko dla danych wczytywanych w tle niewidocznie
     * pozostałe przez run i dload -> do zintegrowania z tym
     * $mea.ajax.run().on('onSuccess',(event,parsed)=>{ console.log(parsed.data, parsed.blocks ); })
     * $mea.ajax.run().on('onFinish',(data,parsed)=>{ console.log(parsed.data, parsed.blocks ); })
     * $mea.ajax.run().on('onError',(data,parsed)=>{ console.log(parsed.data, parsed.blocks ); })
     */
    run : function(url,params){

        let urltarget;

        if(!params) params={};
        params.url = url; //url bez parametów do pozycjonowania

        if(params.scroolDisableForThisResponse === undefined)
        params.scroolDisableForThisResponse = true;

        //hpl removed - request type determine ajax
        //if(cache) url = urlAdd(url,'gettype=hpl&');

        if(!params.loadingElementDisableForThisResponse){
            let urlObj = new URL('http://www.example.com'+params.url);
            urltarget = urlObj.searchParams.get("urltarget");
            if(urltarget){
                // console.log('loadingElement','#'+urltarget);
                $mea.ajax.loadingElement('#'+urltarget)
            }
        }

        if(params.indicator ===1)
            $mea.messages.indicator(1);

        if(params.gettype ==='hpl')
            url = $mea.ajax.urlReplace(url,'gettype','hpl');


        let cache = $mea.ajax.cacheCheck(url,params.cache);
        if(!cache) url = $mea.ajax.urlReplace(url,'front_uniq',$mea.ajax.randomString());

        let posttype ='get';

        if(params.data) {

            posttype = 'post';

            if( Object.prototype.toString.call( params.data ) === '[object Array]' ) {
                params.data = JSON.parse(JSON.stringify(params.data))
            }

        }
        //if allow parse first use onFinish: ()=>{}
        if(!params.onSuccess ){

            params.onSuccess = (data,textStatus, request)=>{

                if(urltarget){
                    // console.log('loadingElement','#'+urltarget);
                    $mea.ajax.loadingElementEnd('#'+urltarget)
                }

                let parsed = $mea.ajax.responseSuccesParse(data,textStatus, request, params);

                // console.log('core mea.ajax.onSuccess',{
                //     parsed, data,textStatus, request
                // });
                $(request).trigger('onSuccess',[
                    parsed,data,textStatus, request, params
                ]);

                if(params.el){

                    let e = jQuery.Event( "load" ,{mea: {
                            request: request,
                            resp: data,
                            parsed: parsed,
                        } });

                    $(params.el).trigger(e);

                }


                if(params.onFinish){

                    if(typeof params.onFinish === "function") params.onFinish(data,parsed);
                    else {
                        let func = new Function( params.onFinish );
                        func.call( null, data,parsed );

                        this.log('onFinish',func);

                    }

                }

            };
        }

        if(!params.onError )
            params.onError = function(data){

                console.log('mea.run ajax onError',url,params, data);
                // $mea.messages.error("Exception found");

                // if($mea.ajax.debug)
                //     $mea.log('$mea.run ajax error',url,data.responseText);

                $mea.ajax.unregisterAction();

                $mea.ajax.parseResponse(data.responseText, params);
            };

        if(typeof params.async === "undefined"){
            params.async = true;
        }

        let ajaxParams = {
            url: url,
            data : params.data ,
            // complete:()=>{
            //     $mea.messages.indicator(0);
            // },
            // contentType: 'application/json',
            cache: cache,
            headers: params.headers,
            async: params.async,
            type : posttype,
            context: document.body,
            error: params.onError,
            success: params.onSuccess,
            always: ()=>{
                console.log('disable inidateov');
                $mea.messages.indicator(0);
            }

        };

        // console.log('ajaxParams',url,ajaxParams);
        return $($.ajax(ajaxParams));
        // return $.ajax(ajaxParams);

    },

    /**
     * mea.ajax.reload(selector);
     * @param container
     */
    reload: function(container,params){

        if(!params) params = {};

        if(!container){
            window.location.reload();
            return;
        }

        let $container = $(container);

        let url  = $container.attr('data-url');
        let targetId  = $container.attr('id');

        if($container.length===0 || !url ){
            $mea.log('reload params error (container,url)',$container,url);
            return ;
        }

        $mea.ajax.disableElement('#'+targetId);

        if(!params.disableIndicator)
            $mea.messages.indicator(1);

        params.onFinish= function(){
            $mea.ajax.enableElement('#'+targetId);
        };

        $mea.ajax.load(
            $mea.ajax.urlAdd(url,'isMeaReload=1'),
            {
                urltarget: targetId
            },
            params
        );

    },

    decodeEntities: function(str) {
        // this prevents any overhead from creating the object each time
        var element = document.createElement('div');

        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';

            return str;
        }

        return str;
    },

    loadingElement: function(selector){

        // console.log('loadingElement',selector);
        let html = $(this.template_loading);
        html.addClass('mea_ajax_loading_html');

        // this.disableElement(selector);
        $(selector).prepend(html);
    },

    loadingElementEnd: function(selector){
        // console.log('loadingElementEnd',selector);
        // this.enableElement(selector);
        if(!selector)
            $('.mea_ajax_loading_html').remove();
        else
            $(selector).find('.mea_ajax_loading_html').remove();
    },

    /**
     * blokuje diva , lock , disable
     * $mea.ajax.disableElement("#someDiv");
     */
    disableElement: function(selector){

        if(!selector) selector = 'body';

        //dropdown - is absolute
        if($(selector).css('position')!=='absolute')
            $(selector).css('position','relative');


        let html;

        if(typeof $mea.ajax.disableElementBody === 'function'){
            html = $mea.ajax.disableElementBody();
        }else{
            html = $mea.ajax.disableElementBody;
        }

         // console.log('disable html',html);

        if($(selector).find('.disableElementContainer').length>0){

            console.warn('disableElement is already disabled',{
                'selector': selector,
                'disableElementContainer': '.disableElementContainer',
                el: $(selector)
            });
            return ;
        }
        $(selector).append(html);
        // $(selector).append('<div class="disableElementMask">'+$($mea.messages.indicator_html).html()+'</div>');

    },

    /**
     * odblokowuje diva $mea.ajax.enableElement('#div');
     */
    enableElement: function(selector){

        if(selector === undefined) selector ='html';
        //$('#userPanelContent').fadeTo('slow',1);
        $(selector).find('.disableElementMask').remove();

    },


    setCacheDefault: function(state){

        switch (state){

            case 'cache':
            case 'allow':
                $mea.ajax.cacheDefault=state;
                $.ajaxSetup({'cache':true});
                break;

            case 'NoCache':
                $mea.ajax.cacheDefault=state;
                break;

            default:
                $mea.log('setCacheDefault error, unknown '+state);
                break;

        }

    },


    /**
     * sprawdza czy dany url można pozwolic na cachowanie
     * @param url
     * @param cache
     */
    cacheCheck: function(url,cacheRequest){

        let cache = false;

        //jezeli recznie ustawione pozostawiamy
        if(cacheRequest===true || cacheRequest===false){
            cache = cacheRequest;
        }else{
            if($mea.ajax.cachelist[url]==='allow' || $mea.ajax.cacheDefault=='cache'){
                cache = true;
            }else if($mea.ajax.cacheDefault==='cache' || $mea.ajax.cachelist[url]=='noAllow'){
                cache = false;
            }else if($mea.ajax.cacheDefault==='NoCache'){
                cache = false;
            }
        }

        // console.log('cacheCheck',{
        //     url,
        //     cache
        // });

        return cache;

    },

    //wykonane przed każdym ajaxem - stos żądań
    registerAction: function() {
        this.validating++;
    },
    //wykonane po każdym requescie
    unregisterAction: function() {
        this.validating--;
    },

    /**
     * fired auto in  {{ Jsendbody('webpack')|raw }}
     * test html = $.parseHTML( html );
     * $mea.ajax.activateContent(html); for html string. (first parse)
     */
    activateContent: async function(selector,config) {

        //console.log('activateContentStackTrace', console.trace());
        if(this.contentisActivating) {
            this.contentisActivatingReloadRequest = true;
            return false;
        }

        if(!selector) selector = 'body';

        this.contentisActivating = true;

        this.log('activateContent', selector,config);
        if(selector === 'body' && typeof(_jqq) != "undefined" && _jqq.length >0) {

            console.log('ajax execute jqq',_jqq.length);
            // _jqqCopy = clone _jqq;
            // _jqq = [];
            $.when(
                $.each(_jqq, function(i, fn) {
                    let fnExecute = fn;
                    // console.log(_jqq.length, i, fn);
                    // _jqq.splice(i, 1);
                    // console.log('fnExecute',_jqq.length, i, fnExecute);
                    try{
                        if(fnExecute)
                            fnExecute();
                    }catch (e){
                        console.log('mea ajax fnExecute exception',{
                            typeof: typeof fnExecute,
                            fnExecute: fnExecute,
                            exception: e
                        });
                    }

                })
            ).then(()=>{
                _jqq = [];
                // console.log('body _jqq finished');
            });
        }

        _jqq = [];

        let tid = setInterval( ()=>{

                if ( document.readyState !== 'complete' ) {
                    // console.log('activateContent wait for ready');
                    return;
                }
                clearInterval( tid );

                // console.log('activateContent ready');

                if(!config) config = {};

                // this.releasesInit();

                this.contentisActivated = true;

                let log = [];
                let logLoop ;

                for (let type in $mea.ajax.releases)
                {

                    //dont show full exception
                    // try{
                        logLoop = this.activateContentByType(type,selector,false,config);
                        // if(logLoop.length > 0 )
                        if(logLoop )
                            log.push({
                                type,
                                activated: logLoop.length,
                                selector: $mea.ajax.releases[type].selector,
                                elements: $($mea.ajax.releases[type].selector).length,
                                logLoop
                            });

                    // }catch (e){
                    //     console.log('mea release exception',{
                    //         type,
                    //         selector,
                    //         exception: e
                    //     });
                    // }

                }


                if(this.contentisActivatedOnce === false){
                    // console.log('activated',selector, this.contentisActivatingReloadRequest);
                    // console.trace()
                    $('body').trigger($mea.ajax.events.afterBodyReady);
                }
                this.contentisActivatedOnce = true;


                try{

                    this.log('ActivatingContent',{
                        selector: selector,
                        // selectorLength: $(selector).length,
                        releases: $mea.ajax.releases,
                        log: log,
                        content: $(selector).html()
                    });

                }catch (e){

                }

        }, 100 );


        this.contentisActivating = false;
        if(this.contentisActivatingReloadRequest > 0){

            this.contentisActivatingReloadRequest = false;

            this.activateContent(selector);

        }




    },

    activateContentByType: function(type,selector, force,config){

        if(typeof type === 'undefined'){
            console.log('activateContentByType type undefined ',type,selector);
            return false;
        }

        let localSelector = false;
        // console.log('activateContentByType',type,selector);


        //localSelector = selector + " " + $mea.ajax.releases[i].selector;

        try{
            localSelector = $(selector).find($mea.ajax.releases[type].selector);
        }catch (e){
            //if html without wrapper make error for recode
            console.log('activateContentByTypeERR',{type,selector, force,config},$mea.ajax.releases[type].selector,e)
            return false;
        }

        if(!localSelector) {
            console.log('activateContentByTypeERR',{type,selector, force,config},$mea.ajax.releases[type].selector)
            return false;
        }

        let log = [];
        let logRelease=[];

        if($(selector).is($mea.ajax.releases[type].selector)){
            this.activateContentItem(selector,log,localSelector,type,force,config);
        }

        jQuery(localSelector).each((k,el)=> {
            this.activateContentItem(el,log,localSelector,type,force,config);
        });

        if(logRelease.length>0){
            log[type]=logRelease;
        }
         return log;

    },

    activateContentItem(el,log,localSelector,type,force,config){

        //current proccessed
        if($(el).attr("meaReleaseProgress")===1){
            return ;
        }

        let curentArray = $(el).attr('relase');
        let currentIgnored = $(el).attr('relaseIgnore');

        if (!curentArray || curentArray.length < 1)
            curentArray = [];
        else
            curentArray = curentArray.split(" ");

        if (!currentIgnored || currentIgnored.length < 1)
            currentIgnored = [];
        else
            currentIgnored = currentIgnored.split(" ");

        if ($.inArray(type, curentArray) === -1 || force === true  ) {

            if ($.inArray(type, currentIgnored) !== -1 && force !== true  ) {
                return;
            }

            $(el).attr("meaReleaseProgress", 1);

            let evalResponse = $mea.ajax.releases[type].code($(el), localSelector);

            if(evalResponse===false){

                currentIgnored.push(type);

                $(el).attr("relaseIgnore", currentIgnored.join(" "));

            }else{

                curentArray.push(type);

                log.push({
                    type,
                    _this: $(el),
                });

                $(el).attr("relase", curentArray.join(" "));

            }

            $(el).removeAttr("meaReleaseProgress");

        }


    },

    removeRelase(name){
        delete this.releases[name];
    },

    /**
     * Dodaje wyzwalacz danych
     *
     * @example $mea.ajax.registerRelase('hreflightBox','a[rel*=lightbox]', function (el,selector){ setTimeout("$('"+selector+" a[rel*=lightbox]').lightBox().attr('title','ligh');",2000); } );
     */
    registerRelase: function(name, selector, code) {

        //nadpisanie
        if (this.releases[name]) {
            //$mea.log('grek ajax registerRelase update ', 'name ' + name, 'selector' + selector);
        };

        this.releases[name] = {
            'selector': selector
            , 'code': code
        };

        /**
         * Doanie nowego wyzwalacza na aktywowaną już stronę (np. colorbox)
         */
        if(this.contentisActivated) {
            //console.log('reactivate content new relase '+name);
            this.activateContent('body');
        };

    },

    /**
     * wyzwalcze danych , wykonywane przy każdym wczytaniu contetnu - aktywują treść / przyciski,
     *
     * można dodawać nowe, wykona podaną funkcje dla każdego elementu perzesyłając go jako el
     *
     * $mea.ajax.releases['.g_svg'] =  function (el){ 	$(el).attr('id'));  }
     *
     */
    releasesInit: function() {

        if (this.releasesDef)
            return;

        this.releasesDef = true;

        //$mea.ajax.registerRelase('href_lightBox','a[rel*=lightbox]', function (el,selector){ setTimeout("$('"+selector+" a[rel*=lightbox]').lightBox().attr('title','ligh');",2000); } );





    },//END releasesDef


    //Przetwarzanie odpowiedzi ajax dla run dload form submit
    responseSuccesParse: function(data,textStatus, request, params){

        $mea.ajax.unregisterAction();

        $mea.messages.indicator(0);


        // if(request.getResponseHeader('Content-Disposition')==='attachment'){ - dont work have attachment; filename=...

        switch(request.getResponseHeader('Content-Type')){

            case 'application/pdf':

            case 'application/octet-stream':
                $mea.log('ajax return attachment, try show pdf');
                let file = new Blob([data], {type: request.getResponseHeader('Content-Type')});
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                return ;
                break;

            case 'application/zip':
                $mea.log('ajax return attachment zip, not possible');
                $mea.messages.error("Can not send ZIP with ajax");
                //ZIP IS COnverted by jquer = dont work , use redirect
                // var disposition = request.getResponseHeader('content-disposition');
                // var matches = /"([^"]*)"/.exec(disposition);
                // var filename = (matches != null && matches[1] ? matches[1] : 'file.pdf');
                //
                // var file = new Blob([data], {type: request.getResponseHeader('Content-Type')});
                // var link = document.createElement('a');
                // link.href = window.URL.createObjectURL(file);
                // link.download = filename;
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);
                break;

        }

        if(request.getResponseHeader('Hpl-redirect')){

            if(request.getResponseHeader('Hpl-redirect-target')){


                let url = request.getResponseHeader('Hpl-redirect');
                url =$mea.ajax.urlReplace(url,'urltarget',request.getResponseHeader('Hpl-redirect-target'));
                console.log({
                    url: url
                });
                $mea.ajax.run(url,
                    {
                        gettype: 'hpl',
                        // scroolDisableForThisResponse: false,
                    }
                );
            }else{
                let e = $.Event( "beforeunload" );
                $( window).trigger( e );

                $mea.ajax.locationChange(request.getResponseHeader('Hpl-redirect'));
            }

            // if(window.self !== window.top){
            //     window.top.location = request.getResponseHeader('Hpl-redirect');
            // }else{
            //     window.location.href = request.getResponseHeader('Hpl-redirect');
            // }

            return ;
        }

        if(!data) {
            //$mea.log('no response data');
            return ;
        }

        let parsed = $mea.ajax.parseResponse(data, params);

        // console.log('mea.ajax.responseSuccessParse ',parsed);
        //load_parse_switch(data);

        $(document).trigger('hplReady');

        if(typeof $("html").getNiceScroll === 'function')
            $("html").getNiceScroll().resize();


        return parsed;

    },

    /**
     * Parsowanie treści zwrotnej MEA, zastępuje load_parse_switch
     * data - odpowiedź ajax / json
     * params = {
     * url - url requesta - do zapamietania pozycji tresci dla scrolla przy powtórzeniu tego samego
     * ReturnContent - zwróci dane zamiast umieścić je w celu
     * }
     */
    parseResponse: function(data, params) {

        let jsonObject;

        if(!params)params={};
        try
        {

            if(typeof data ==='object'){
                jsonObject = data;
            }else{
                jsonObject = eval('(' + data + ')');
            }


        } catch (err) {

            //unlock all disabled inputs

            $mea.log('ajax parseResponse err en ',{error: err, data: data});

            $mea.ajax.loadingElementEnd();

            setTimeout(()=>{
                $mea.ajax.enableElement('html');
                $('button[type=submit]').removeAttr('disabled');

                $('[disabled]').removeAttr('disabled');
            },2000);

            $mea.dialog.create({
                "title": $mea.translator.trans('Exception detected'),
                'id': 'ResponseError',
                'width': '90%',
                "content":  data
            });

            // $mea.messages.error('Exception detected');

            return false;
        }


        if(jsonObject)
        this.parseResponse_json(jsonObject, params);

        return jsonObject;

    },


    parseResponse_json: function(jsonObject, params) {

        //console.log('parseResponse_json',arguments.callee.caller.toString(),params,jsonObject);

        this.parseResponse_PartContent(jsonObject, params);


    },

    //rodzielenie treści
    parseResponse_PartContent: function(jsonObject, params) {

        // console.log('parseResponse_PartContent',arguments.callee.caller.toString(),jsonObject,params);

        if(!params) params = {};

        this.topPosition = 0;
        this.contentChange = false;

        // console.log('jsonObject',jsonObject);

        $.each(jsonObject, function(type, value) {

            switch (type)
            {

                case 'blocks':
                    //$mea.log('$mea.ajax.parseResponse_blocks',value);
                    $.each(value, function(k, v) {

                        k = k.replaceAll("'",'');

                        if(typeof v === "object" && k !== 'dialog'){

                            //todo:mea połączyć dane jeżeli więcej bloków zwraca ?
                            jsonObject.data = v;

                        }else{
                            $mea.ajax.parseResponse_block(k, v, jsonObject, params);
                        }
                    });

                    break;

                case 'events':
                    //$mea.log('events',value);

                    $.each(value, function(k, details) {
                        let event = $.Event(details.name,details);
                        event.captured = false;
                        //content are in
                        // event.content = {};
                        if(details.target){
                            $(details.target).trigger(event);
                        }else{
                            $('body').trigger(event);
                        }
                        if(event.captured === false)
                            console.log('event not captured ', event);
                    });

                    break;

                case 'messages':
                    $.each(value, function(k, v) {
                        switch (v.type){

                            case 'error':
                                $mea.messages.error(v.content, v.time);
                            break;

                            case 'info':
                            default:
                                $mea.messages.add(v.content, v.time, v.params);
                                break;

                        }

                    });
                    break;

                case 'prepend':
                    $.each(value, function(k, v) {
                        $(k).prepend(v);
                    });
                    break;

                case 'append':
                    $.each(value, function(k, v) {
                        $(k).append(v);
                    });
                    break;

                case 'msg':
                    $mea.messages.add(value);
                    break;

                case 'scripts':
                    break;

                default:
                /**
                 * dopusczenie dowolnych innych danych
                 * @example  response = $mea.ajax.parseResponse(r); - otrzymujemy inydwidualne dane i obsługę systemowych akcji
                 */
                // alert('niezdefiniowana odpowiedz '+type);
            }

        });

        if (jsonObject.scripts) {
            $mea.ajax.parseResponse_PartScripts(jsonObject);
        }


        if($mea.ajax.debug)
            this.log('ajax scrollingLog',[
                params.scroolDisableForThisResponse ,
                this.scroolDisableForThisResponse ,
                params.scroolDisableForThisResponse ,
                this.animateToContent && this.contentChange ,
                this.topPosition,
                this.headerStartScrol,
                (this.topPosition > this.headerStartScrol)
            ]);

        //jeżeli po pętli bloków pozycja strony jest poniżej
        if (!params.scroolDisableForThisResponse &&
             !this.scroolDisableForThisResponse &&
             !params.scroolDisableForThisResponse &&
             this.animateToContent && this.contentChange &&
             this.topPosition > this.headerStartScrol
        ) {


            if(params.url)
                $mea.ajax.hashlocations[params.url]= this.topPosition;

            if($mea.ajax.debug)
                this.log('scrolling to content active',(this.topPosition - this.topPositionMargin),params,this, this.topPositionMargin);

            $('html, body').animate({
                    scrollTop: this.topPosition - this.topPositionMargin},
                'slow');

        }else{

            if($mea.ajax.debug)
                this.log('scrolling not required');


        }


        if(this.contentChange)
            this.onContentLoaded();

        //$mea.log('blockScrollAnalise',this.topPosition,this.headerStartScrol);



    },


    /**
     * Umieszczanie bloków w treści strony
     * @param k
     * @param v
     * @param ReturnContent
     * @returns {boolean}
     */
    parseResponse_block: function(k, v, ReturnContent, params) {

        if(this.debug)
            this.log('parseResponse_block',k, v, ReturnContent, params);

        if (k === 'dialog') {

            // console.log('parseResponse_block dialog',params);

            if (v.content) {

                let dialog_params = v;
                dialog_params.id = v.dialog_id;

              //  console.log('dialog_params',dialog_params);
                $mea.dialog.create(dialog_params);

            } else if((typeof v) =='string') {

                params.content = v;
                $mea.dialog.create(params);


            }

            return true;

        }

        if (k === '') {

            $mea.log('mea hpl error (empty b. name)');

            return false;

        }

        let targetLength = false;
        let target = false;

        try{
            targetLength = $('#' + k).length;
            if(targetLength > 0)
                target = '#'+ k;
        }catch (e){
            //not by id
        }

        if(target === false){

            try{

                targetLength = $(k).length;
                if(targetLength > 0)
                    target = k;

            }catch (e){
                //console.log('try selector exception',e);
            }
        }


        if (target === false) {
            $mea.log('mea info cant find block = ' + k +' ');
            return false;
            // //default block
            // k ='meaContent';
            //
            // if($('#' + k).length <1){
            //     $('body').append('<div id="meaContent"></div>');
            // }
        }

        this.contentChange = true;

        if (params.ReturnContent === 1){

            ReturnContent = v;

            return ReturnContent;

        }else {

            this.parseResponsePlace(target,v);


        }

    },

    responseErrorParse: function(data){

        if(!data) return;

        // $mea.messages.error("d.error. found");

        $mea.log('ajax error',data.responseText);

        //$('body').html(data.responseText);

        $mea.ajax.unregisterAction();

        $mea.ajax.parseResponse(data.responseText);

        $mea.messages.indicator(0);

    },

    isHplResponseData: function(data){

       if(data.type === 'hpl') return true;
       return false;

    },

    isHplResponse: function(str){

        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;

    },


    parseResponse_PartScripts: function(jsonObject) {

        // console.log('parseResponse_PartScripts jsonObject',jsonObject);
        let type;
        let scripts;
        let location;
        this.evalNow = 1;
        let js_load_count = 0;


        jQuery.each(jsonObject.scripts, function(type, scripts) {

            if (type === 'js') {

                let counter = jQuery.param(scripts).split('=').length;

                let callback = $mea.ajax.parseResponse_createCallback(counter - 1, function() {

                    $mea.ajax.parseResponse_eval();
                });
                $mea.ajax.evalNow = 0;
            }

            //$mea.log('sLoader',sLoader,sLoader['aaa']);

            $.each(scripts, function(name, location) {

                 // console.log('eval script ',name, location);
                // console.log(new Error().stack);

                switch (type) {
                    case 'js': //zewnętrzne skrypty

                        if (typeof sLoader[name] == "undefined") {

                            sLoader[name] = 1;

                            if((window.jQuery) && name ==='jquery'){

                            }else{

                                //$mea.log("js url load ",name,sLoader[name]);
                                jQuery.ajax({
                                    url: location,
                                    dataType: 'script',
                                    cache: true,
                                    async: false,
                                    success: callback
                                });

                            }


                        } else {
                            //$mea.log('js url load pomijam '+name,location,sLoader.name);
                            callback();
                        }

                        break;

                    case 'js_body': //body

                        //$mea.log('js_body',location);
                        //eval(location);
                        jQuery('#jstmp').prepend(location);

                        break;

                    case 'js_content': //js onload

                        if (location) {

                            if ($mea.ajax.evalNow === 1) {

                                try {
                                    // $mea.log('evalNow',location);
                                    $(document).ready(function() {
                                        eval(location);
                                    });

                                } catch (error) {
                                    $mea.log('evalNow response script error: ', error, location, jsonObject);

                                }


                            } else {
                                $mea.ajax.eval_loaded = $mea.ajax.eval_loaded + location;
                                //$mea.log('js onload add: ',grek,location,'saved:' ,$mea.ajax.eval_loaded);
                            }
                        }


                        break;

                    case 'css':
                        $("head").append("<link>");
                        var css = $("head").children(":last");
                        css.attr({
                            rel: "stylesheet",
                            type: "text/css",
                            href: location
                        });

                        break;

                }

            });
        });

    },


    onContentLoaded: function(){},


    /**
     * Umieszczenie pojedyńczego bloku w treści, animowanie , przesuwanie
     * @param key
     * @param content
     */
    parseResponsePlace: function(key, content){

        //$mea.log('Pozycjonowanie bloku ',$('#' + k).offset(),$('#' + k),topPosition);

        let params;
        if(typeof content == "object"){

            params = content;
            content = content.content;

        }else params={};


        //blok zawiera dodatkowe parametry

        if(params.showEffect ==='slide'){

            $(key).hide('slide', {direction: 'right',complete : function(){

                if($mea.ajax.writecapture && typeof writeCapture !="undefined")
                    writeCapture.write($(key)[0],content);
                else{
                    $(key).empty();
                    $(key).html(content);
                }


                $(key).show('slide', {direction: 'left'}, 800);

                $mea.ajax.activateContent(key);

            }}, 200);



        }else{

            $(key).empty();

            if($mea.ajax.writecapture && typeof writeCapture !="undefined")
                writeCapture.write($(key)[0],content);
            else
                $(key).html(content);

            $mea.ajax.activateContent(key);

        }


        if ($(key).offset().top > this.topPosition) {

            this.topPosition = $(key).offset().top;

        }

        $(window).trigger( "resize" );

        $(window).trigger({
            type: $mea.ajax.events.afterContentChanged,
            selector: key,
        });

        if(key === $mea.ajax.selector_default){
            $(window).trigger({
                type: $mea.ajax.events.afterMeaContentChanged
            });
        }


    },

    parseResponse_eval: function() {

        var eval_loaded = this.eval_loaded + ';a=1;';
        ;

        //$mea.log('parseResponse_eval',grek,$mea.ajax.eval_loaded);

        try {

            $(document).ready(function() {

                eval(eval_loaded);

                //$mea.log('$(document).ready eval on load',eval_loaded);
            });

        }
        catch (error) {
            $mea.log('eval response script error: ', error, this.eval_loaded);

        }

        this.eval_loaded = '';
        this.evalNow = 1;

    },


    parseResponse_createCallback: function(limit, fn) {
        //$mea.log('createCallback : '+limit);
        var finishedCalls = 0;
        return function() {
            if (++finishedCalls == limit) {
                //$mea.log('end loading : ');
                fn();
            }
        };
    },


    objectToQuerystring: function(obj) {
        return Object.keys(obj).reduce(function (str, key, i) {
            var delimiter, val;
            delimiter = (i === 0) ? '?' : '&';
            key = encodeURIComponent(key);
            val = encodeURIComponent(obj[key]);
            return [str, delimiter, key, '=', val].join('');
        }, '');
    },


    trackEventGA: function (Category, Action, Label, Value) {
        "use strict";
        if (typeof (ga) !== "undefined") {
            ga('send', 'event', Category, Action, Label, Value);
        }
        else if (typeof (_gaq) !== "undefined") {
            _gaq.push(['_trackEvent', Category, Action, Label, Value]);
        }
    },

    scrollTo: function(selector,offset){


        let height = $(selector).offset().top;
        if(parseInt(offset))
            height = height - parseInt(offset);

        $('html, body').animate({
            scrollTop: height
        }, 2000);

    }

};

//override for capture remove element on each child $('table.sonata-ba-list').on("remove", ()=>{
//https://stackoverflow.com/questions/2200494/jquery-trigger-event-when-an-element-is-removed-from-the-dom
$.cleanData = (function(orig) {
    return function(elems) {
        var events, elem, i;
        for (i = 0;
             (elem = elems[i]) != null; i++) {
            try {

                // Only trigger remove when necessary to save time
                events = $._data(elem, "events");
                if (events && events.remove) {
                    $(elem).triggerHandler("remove");
                }

                // Http://bugs.jquery.com/ticket/8235
            } catch (e) {}
        }
        orig(elems);
    };
})($.cleanData);

