
// $mea.ajax.registerRelase('href_ajax', 'a[data-rel=ajax],a[rel=ajax], a[target=ajax],button[rel=ajax]',
//     function(el, selector) {
//         $mea.ajax.hrefAjax(el);
//     }
// );
// $mea.ajax.registerRelase('href_dialog', 'a[data-rel=dialog],a[rel=dialog], a.dialog, a[target=dialog]',
//     function(el, selector) {
//
//         $(el).attr('data-urltarget','dialog');
//         $mea.ajax.hrefAjax(el);
//     }
// );


// $mea.ajax.registerRelase('href_history', 'a[data-rel=history],a[rel=history]',
//     function(el, selector) {
//         $mea.ajax.hrefHistory(el);
//     }
// );


