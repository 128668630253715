import MeaFormCollection from './meaFormCollection';
import MeaFormWidgetMapGeolocation from './meaFormWidgetMapGeolocation.js';

/**
 input depends of other

 ->add('transportAvailable',null,[
 'attr'=>[
     'onchange'=> 'let target = $(this).closest("[data-mea-form]").data("mea-form").getInputContainer("transportPalettes");
        $(this).is(":checked") ? target.slideDown(): target.slideUp();
     '
 ]
 ])


 */

export default class MeaForm {

    constructor(container, uniq )
    {
        this.config={
            'currency':'EUR'
        };

        let $container = $(container);

        if(uniq) this.uniq = uniq;
        else if($container.data('uniq')) this.uniq = $container.data('uniq');
        else this.uniq = $container.data('form-uniq');

//        console.log('MeaForm',container, uniq);

        this.disableAfterSend = true;

        this.name = this.constructor.name;
        this.type = $container.data('mea-form');


        this.$container = $(container);

        if(this.$container.data('mea-form-config')){
            this.config =  JSON.parse(
                window.atob(this.$container.data('mea-form-config'))
            );
        }

        if($(container).is('form')){
            this.$form = $(container);
        }else{
            this.$form = this.$container.find('form');
        }

        // if(this.$form.length!==1){
        //     console.log('MeaForm Selector not found form in ',container);
        // }

    }

    _init() {
        if (this.disableAfterSend) {
            this.$form.on('submit', e => {
                $mea.ajax.disableElement(this.$form);
            });
        }
    }

    static initTypes(){

        $mea.types.add('autosave',function(el){
            console.log('autosave',el);

            let inputs=['input','select'];

            let $el = $(el);

            $.each(inputs, (k,inputType)=>{

                if($el.is(inputType)){
                    $mea.forms.autoSaveInput($el);

                }

            });


        });

        $mea.types.add('googleMapSearchLocation',function(el){

            new MeaFormWidgetMapGeolocation(el);

        });


    }
    /**
     * @example
     data-mea-form="{{ admin.classnameLabel|lower }}" -> $('[data-mea-form="montiteam"]').data('mea-form')
     or
     let form = new MeaForm('form[data-admin="app.admin.social.post"]');
     form.$form.on($mea.forms.events.response_success,(e)=>{
        console.log(e.mea);
     });
     form.submit({
                    disableAfterSend: false,
                    noValidate: true,
                    url: url
                });
     * @param params = { noValidate, url, disableAfterSend }
     * @param submitEvent
     * @returns {boolean}
     */
    submit(params,submitEvent){

        console.log('meaFormSubmit',params, submitEvent);

        if (!params) params = {};

        //console.log('send',params);

        let event = $.Event($mea.forms.events.form_start_validation);
        this.$form.trigger(event, {
            form: this.$form
        });

        if (event.isDefaultPrevented()) {
            return false;
        }

        //html5 form validate
        if (typeof params.noValidate === 'undefined')
            if ($mea.forms.validate.validate(this.$form) === false) return;


        if (this.$form.attr('mea-reload-protect') === 1) {

            let formData = $form.find($mea.forms.protectSelector).serialize();

            this.$form.data('mea-reload-serialize', formData);

        }


        if (!params.url) {
            params.url = this.$form.attr('action');
        }

        /**
         * Updating CKEDITORS on submit baecose it update is fired after form send
         */
        if (typeof (CKEDITOR) != "undefined")
            $.each(CKEDITOR.instances,function (index, el) {

                // console.log(index, el);
                CKEDITOR.instances[index].updateElement();

            });

        if (this.disableAfterSend && params.disableAfterSend !== false ) {

            $mea.ajax.disableElement(this.$form);
        }

        this.sendFileForm(params,submitEvent);

    }

    /**
     * private function
     * @param params
     * @param submitEvent
     */
    sendFileForm(params, submitEvent){

        if (window.FormData === undefined) {
            $mea.messages.error('Please update your internet browser minimal version is ie 10+, ff 7+.');
            $mea.ajax.activateContent(this.$form);
            return;
        }

        let values = new FormData(this.$form[0]);

        //Add button name like has sonata admin - clicked button name
        try{
            if(submitEvent.originalEvent.submitter.name){
                values.append(submitEvent.originalEvent.submitter.name,'');
                console.log('found submiter name adding ',submitEvent.originalEvent.submitter.name);
            }
        }catch (e) {
            //console.log('submitter',e);
        }

        $mea.messages.indicator(1);

        $.ajax({
            url: $mea.tools.urlAdd(params.url, 'gettype=hpl'),
            type: 'POST',
            data: values,
            async: false,
            cache: false,
            mimeType: "multipart/form-data",
            contentType: false,
            processData: false,
            success: (returndata, textStatus, request)=> {

                $mea.ajax.scroolDisableForThisResponse = true;

                let parsedResponse = $mea.ajax.responseSuccesParse(returndata, textStatus, request);

                if(typeof parsedResponse != 'object'){
                    parsedResponse = {
                        parsedResponse: parsedResponse,
                    }
                    parsedResponse.returndata = returndata;
                    parsedResponse.reply= "returndata"
                }else{
                    parsedResponse.returndata = returndata;
                    parsedResponse.reply= "returndata"
                }


                let e = $.Event($mea.forms.events.response_success, {
                    mea: parsedResponse
                });

                $mea.ajax.scroolDisableForThisResponse = false;

                setTimeout(()=> {
                    this.$form.trigger(e);
                    $mea.ajax.enableElement(this.$form);
                }, 500);

                $mea.messages.indicator(0);

            },
            error: function (returndata) {

                $mea.ajax.scroolDisableForThisResponse = true;

                $mea.ajax.responseErrorParse(returndata);

                $mea.ajax.scroolDisableForThisResponse = false;

                $mea.ajax.enableElement(this.$form);
            }
        });

    }

    debug(title, log){
        $mea.debug.add(title, log,this.type);
    }

    findByData(type,value){

        return this.getForm().find('[data-'+type+'="'+value+'"]');
    }

    find(selector){
       return this.getForm().find(selector);
    }

    getAction(){
        let url = this.$form.attr('action');
        if(!url) url = window.location.href;
        return url;
    }

    getInputContainer(name){
        return this.getInput(name).closest('.form-group');
    }

    getInputContainerByData(data,name){
        return this.getForm().find('[data-'+data+'='+name+']').closest('.form-group');
    }

    getFormPrefix(){

        // let form = $('.postbox-template-switch').closest('form');

        //uniqid s5b49a39a66
        return this.uniq;

    }

    getByMeaName(name){
        return this.getForm().find('[data-mea-name='+name+']');
    }

    getInput(id){
        let selector;
        if (this.getFormPrefix() === undefined) {
            selector = '#'+id;
        }else {
            selector = '#'+this.getFormPrefix()+'_'+id;
        }
        //console.log('getInput getInput',selector);
        return $(selector);
    }

    getInputByName(name){
        return this.$form.find('input[name="'+name+'"]');
    }

    getInputByClass(className){
        return this.$form.find('.'+className);
    }

    getForm(){
        return this.$form;
    }
}
