import FilterBoxVisibilitySwitcher from "./FilterBoxVisibilitySwitcher";
import FilterCriteria from "./FilterCriteria";
import ListSearchInline from "./ListSearchInline";
import ListColumnsVisibilitySwitcher from "./ListColumnsVisibilitySwitcher";

let meaSonataAdmin = {

    init: function(){

        $('.main-sidebar .sidebar-menu').find('a').not('.collapsible-header').click((e)=>{
            let $el = $(e.currentTarget);
            $('.main-sidebar .sidebar-menu').find('li.active').not('.treeview').removeClass('active');
            $el.closest('li').addClass('active');
            // console.log(';',$el);
        });

        $mea.editable.register();
         $mea.types.register();


        // $mea.dialog.defaults.engine = 'ui';
        $mea.ajax.dialogTitleSelector = '.panel-heading.card-header';
        // $mea.ajax.dialogTitleSelector = '.panel-title.card-title';


        //list container - one time when list load
        $mea.types.add('sonata_list', (element)=> {

            // console.log('sonata_list',element);

            this.initList(element)



        });

        // $mea.types.add('toggle_filter', (element) => {
        //
        //     console.log('meaSonataAdmin toggle_filter',element);
        //
        //     // let filterBoxVisibilitySwitcher = new FilterBoxVisibilitySwitcher(
        //     //     $(element).closest('[data-mea-filter-container]').data('mea-filter-container')
        //     // );
        //     //
        //     // new FilterCriteria(filterBoxVisibilitySwitcher);
        //     //
        //     // $(element).data('filterBoxVisibilitySwitcher',filterBoxVisibilitySwitcher) ;
        //
        //
        // });

        //table content - every ajax load content
        $mea.types.add('crud_list_table', (element)=> {

            // console.log('crud_list_table',element)

            let $el = $(element).closest('[data-type="admin-list"]');

            let ajaxUrlMode = $el.data('ajax-mode');
            let uniqid = $el.data('uniqid');
            let list_mode =$el.data('list-mode');

            $el.init.prototype.reload = ()=>{
                $('#sonata_list_filters_'+uniqid).find('form.sonata-filter-form').find('button[type=submit]').click();
            }

            // console.log('list-ajax-mode',$el.data('ajax-mode'));

            //sorting
            $el.find('[data-mea-selector="mea-list-sort"]').click((e)=>{

                // e.preventDefault();

                let $filterForm = $('#sonata_list_filters_'+uniqid).find('.sonata-filter-form');

                let sort_params = $(e.currentTarget).data('sort');


                let $target = $el.closest('.modal').find('.modal-body');
                // let targetId = $el.closest('.modal').attr('id')+'_body';
                let targetId = 'mea-list-content-'+uniqid;


                let url = $mea.ajax.urlAdd($filterForm.attr('action'),
                    decodeURI($filterForm.serialize())
                );
                url = $mea.ajax.urlReplace(url,'list_reload','content');
                url = $mea.ajax.urlReplace(url,'urltarget',targetId);

                let form_key;

                //todo:mea: replace url add new same , dont replace
                $.each(sort_params,(k,v)=>{
                    if(v && typeof v === 'object' && v.constructor === Object){
                        // console.log('obj',k,v);
                        $.each(v,(k1,v1)=>{
                            // console.log('obj1',k,k1,v1 );
                            if(v1 && typeof v1 === 'object' && v1.constructor === Object){
                                $.each(v1,(k2,v2)=>{
                                    if(Array.isArray(v2))
                                        form_key = 'filter['+k+']['+k1+']['+k2+'][]';
                                    else
                                        form_key = 'filter['+k+']['+k1+']['+k2+']';

                                    url = $mea.ajax.urlReplace(url,form_key,v2);
                                    // if(v2)
                                    // console.log('SET obj2',form_key, k1,k2,v2 , Array.isArray(v2));
                                });
                            }else{
                                if(Array.isArray(v1))
                                    form_key = 'filter['+k+']['+k1+'][]';
                                else
                                    form_key = 'filter['+k+']['+k1+']';

                                url = $mea.ajax.urlReplace(url,form_key,v1);

                                // if(v1)
                                // console.log('SET obj1',form_key, v1 , Array.isArray(v1));
                            }
                        });
                    }else{
                        form_key ='filter['+k+']';
                        url = $mea.ajax.urlReplace(url,form_key,v);
                        // console.log('SET',form_key, v );
                    }

                })

                // console.log('mea-list-sort click',{
                //     sort_params,
                //     url
                // });

                // return ;
                // console.log('mea-list-sort out',url.split('&'));

                if($el.data('list_mode')){
                    url =+  '&list_mode='+$el.data('list_mode');
                }


                if($el.closest('.meaDialog').length > 0){
                    // console.log('ajax.load');
                    $mea.ajax.load(url);
                }else{
                    console.log('locationChange');
                    $mea.ajax.locationChange(url);
                }

                return false;
            });


            //Links resolved as ajax load
            if ($el.closest('.modal').length > 0 || ajaxUrlMode === 'inline' ) {

                $('select.per-page').change(function(event) {
                    $mea.ajax.locationChange(this.options[this.selectedIndex].value);
                });

                // console.log('pagination dialog'); //[data-mea-selector="mea-list-sort"]
                $el.find('.pagination a').each((k,href)=>{
                    let $href = $(href);
                    if(!$href.attr('href')) {
                        console.log('href empty',href);
                        return ;
                    }

                    $href
                        .attr('data-rel','ajax')
                        .attr('data-href', $mea.ajax.urlAdd($href.attr('href'),
                            'urltarget=mea-list-content-'+uniqid+'&list_reload=content&list_mode='+list_mode
                        ))
                        .attr('href','')
                    ;
                    $mea.ajax.hrefAjax($href);
                });
                $el.find('.pagination select').change((e)=>{
                    let $href = $(e.currentTarget).val();

                    $href = $mea.ajax.urlAdd($href,'gettype=hpl&urltarget=mea-list-content-'+uniqid+'&list_reload=content&list_mode='+list_mode);

                    // console.log({
                    //     t: $(e.currentTarget),
                    //     v: $(e.currentTarget).val(),
                    //     h: $href
                    // });

                    $mea.ajax.run($href);
                });

                // $el.find('a.meaNext').attr('href', $mea.ajax.urlAdd($el.find('a.meaNext').attr('href'), 'list_mode='+$el.data('list-mode')));
                // $el.find('a.meaPrev').attr('href', $mea.ajax.urlAdd($el.find('a.meaPrev').attr('href'), 'list_mode='+$el.data('list-mode')));



                //links change url
            }else{
            // }else if(ajaxUrlMode === 'url'){

                 // console.log('pagination',$el.find('.pagination a'));

                $('select.per-page').change(function(event) {
                    // console.log('change to ',this.options[this.selectedIndex].value);
                    $mea.ajax.locationChange(this.options[this.selectedIndex].value);
                });

                //[data-mea-selector="mea-list-sort"]
                $el.find('.pagination a').each((k,href)=>{
                    let $href = $(href);
                    if(!$href.attr('href')) {
                        console.log('href empty',href);
                        return ;
                    }


                    $href.attr('href', $mea.ajax.urlAdd($href.attr('href'),
                        'urltarget=mea-list-content-'+uniqid+'&list_reload=content&list_mode='+list_mode
                    ));
                    $mea.ajax.hrefHistory($href);
                });


            }
            //--

            this.initBatchActions($el, uniqid);


        });

        $mea.types.add('add_dialog', function (el) {

            $('<i class="fa fa-folder-open-o"></i>&nbsp').click((event)=>{

                event.preventDefault();

                let url = $(el).attr('href') ;

                url = $mea.ajax.urlAdd(url, 'gettype=hpl&urltarget=dialog');

                $mea.ajax.load(
                    url,
                    false,
                    {
                        el: el
                    }
                );
            }).prependTo(el);

        });


        // $mea.types.add('list_inline_search', (element) => {
        //
        //     new ListSearchInline($(element));
        //
        // });

        if($mea.config.dev)
            console.log('MeaAdminSFired');

        if(typeof $mea.user === 'undefined'){
            $('html').on('event_user_login',()=>{
                this.initUserSettings();
            });
        }else{
            this.initUserSettings();
        }

    },

    initUserSettings(){

        let url = Routing.generate('mea_public_action', {
            action: 'getUserDataSettings',
            dataTypes: ['visibilityFilter','filterCriteria', 'listColumnsVisibilitySwitcher' ],
            uniqid: (Math.random() + 1).toString(36).substring(7)
        });
        $.post(url, {}, res => {

            $mea.user.settings_list = res;

            if (res.status === true) {

                let event = $.Event('loadUserSettings', { setings: res });
                event.captured = false;
                $('body').trigger(event);

                console.log('initUserSettings', res);


            }

        });

    },

    initList(element){


        let $el = $(element);
        let ajaxUrlMode = $el.data('ajax-mode');
        let uniqid = $el.data('uniqid');
        let list_mode =$el.data('list-mode');

        this.initAjaxFilters($el, uniqid);

        // let switchContainer = $el.find('[mea-data-type=ColumnsVisibility]');
        let ColumnsVisibilityContainer = $('[data-mea-type=columnsVisibility]')[0];
        // let columnsVisibility = new ListColumnsVisibilitySwitcher(ColumnsVisibilityContainer);
        let columnsVisibility = new ListColumnsVisibilitySwitcher('[data-mea-type="ColumnsVisibility"]');
        $(ColumnsVisibilityContainer).data('columnsVisibility',columnsVisibility) ;

        this.initSticky($el);

        this.initInfinity($el);

    },


    initBatchActions($el, uniqid){
        $el.find('[data-mea-selector="batch_type"]').change((e)=>{
            $el.find('.batch_form').addClass('hidden');
            if($(e.currentTarget).val())
                $('.batch_form.'+$(e.currentTarget).val()).removeClass('hidden')
        });

         // console.log('initBatchActions',$el);

        $el.find('[data-mea-selector="batch_submit"]').click((e)=>{
            e.preventDefault();

            $el.find('.batch-form-pool .batch_form').each(function(){
                if(!$(this).hasClass($('select[name=action]').val())){
                    console.log('remove',this);
                    $(this).remove();
                }
            });

            let $form = $el.find('form');

            // $(this).closest('form').submit();
            // console.log('BatchSubmit '+$form.data('controller'), $form.attr('action'));
            $mea.forms.send($form);
            //standard submit send parent form if form is part outer form
            // $form.submit();

            return false;
        });



        //Batch actions
        $('#list_batch_checkbox').on('ifChanged change', function () {

            let checkboxes = $(this)
                .closest('table')
                .find('td.sonata-ba-list-field-batch input[type="checkbox"], div.sonata-ba-list-field-batch input[type="checkbox"]')
            ;

            if (Admin.get_config('USE_ICHECK')) {
                checkboxes.iCheck($(this).is(':checked') ? 'check' : 'uncheck');
                checkboxes.prop('checked', this.checked);
            } else {
                checkboxes.prop('checked', this.checked);
            }
        });

        // Add a CSS class to rows when they are selected
        $('td.sonata-ba-list-field-batch input[type="checkbox"], div.sonata-ba-list-field-batch input[type="checkbox"]')
            .on('ifChanged change', function () {
                $(this)
                    .closest('tr, div.sonata-ba-list-field-batch')
                    .toggleClass('sonata-ba-list-row-selected', $(this).is(':checked'))
                ;
            })
            .trigger('ifChanged')
        ;
        //end batch actions

    },

    initAjaxFilters($el, uniqid){

        // console.log('initAjaxFilters', $el, uniqid);
        let ajaxUrlMode = $el.data('ajax-mode');
        let $filterForm = $('#sonata_list_filters_'+uniqid).find('.sonata-filter-form');

        $filterForm.unbind( "submit" );

        $filterForm.find('button[type=submit]').click(()=>{

            let $target = $el.closest('.modal').find('.modal-body');
            // let targetId = $el.closest('.modal').attr('id')+'_body';
            let targetId = 'mea-list-content-'+uniqid;

            // console.log(url); return ;
            //moved to headers
            let url = $mea.ajax.urlAdd($filterForm.attr('action'),
                $filterForm.serialize()
                // '&list_reload=content'+
                // '&urltarget='+targetId
            );

            if($el.data('list_mode')){
                url =+  '&list_mode='+$el.data('list_mode');
            }

            if($el.closest('.meaDialog').length > 0 || ajaxUrlMode ==='inline'){
                // console.log('ajax.load');
                url = $mea.ajax.urlReplace(url,'urltarget',targetId);

                console.log('$filterForm SubmitBtnClick',{
                    targetId,
                    url
                });

                $mea.ajax.load(url,{},{
                    headers: {
                        urltarget: targetId,
                        'list-reload': 'content',
                    }
                });
            }else{
                // console.log('locationChange');
                $mea.ajax.locationChange(url,{
                    headers: {
                        urltarget: targetId,
                        'list-reload': 'content',
                    }
                });
            }

            return false;
        });

        //doubled submit - move to btnClick
        $filterForm.on('submit',(e)=>{

            console.log('submit $filterForm', $filterForm.attr('action'));

            e.preventDefault();

            return false ;

            // let $target = $el.closest('.modal').find('.modal-body');
            // // let targetId = $el.closest('.modal').attr('id')+'_body';
            // let targetId = 'mea-list-content-'+uniqid;
            // // $target.attr('id',targetId);
            //
            // // console.log('toArray',{
            // //     action: $filterForm.attr('action'),
            // //     array: $mea.forms.formToArray($filterForm)
            // // })
            //
            // let url = $mea.ajax.urlAdd($filterForm.attr('action'),
            //     $filterForm.serialize()+
            //     '&list_reload=content'+
            //     '&list_mode='+$el.data('list_mode')+
            //     '&urltarget='+targetId
            // );
            // if($el.closest('.meaDialog').length > 0){
            //     console.log('ajax.load');
            //     $mea.ajax.load(url);
            // }else{
            //     console.log('locationChange');
            //     $mea.ajax.locationChange(url);
            // }

            //
        });
        // $filterAction.attr('action',$mea.ajax.urlAdd($filterAction.attr('action'), 'list_mode=select'));


    },

    initSticky($el){

        let $boxFooter = $el.find('.box-footer');

        if ($boxFooter.length && $el.closest('.modal').length === 0 && $boxFooter.data('mea-nosticky') === undefined){

            new Waypoint.Sticky({
                element: $boxFooter[0], //$('[data-mea-type="sonata_list"]').find('.box-footer')[0],
                direction: 'up down',
                offset: 'top-in-view',
                handler: function( direction ) {
                    // console.log(direction);
                    if ($boxFooter.hasClass('stuck')) {
                        $boxFooter.width($boxFooter.closest('.sticky-wrapper'));
                    }
                }
            });
        }
    },

    initInfinity($el){

        if ($el.closest('.modal').length > 0)
            return ;

        // console.log('infinity load ');
        //init infinite for list
        let $tableTd = $el.find('table.sonata-ba-list tbody tr:not(.mea-list-divide):first td');
        let infiniteElement, infiniteItemsSelector, infiniteContexSelector;
        if ($tableTd.length > 1) {
            infiniteElement = $tableTd.closest('tbody')[0];
            infiniteContexSelector = $tableTd.closest('.sonata-ba-list')[0];
            // infiniteContexSelector = $tableTd.closest('.table-responsive')[0];
            // infiniteItemsSelector = 'table.sonata-ba-list tbody tr';
            // infiniteItemsSelector = 'tr';
            infiniteItemsSelector = 'tr.crud_list_tr';
        } else {
            infiniteElement = $tableTd.find('.row')[0];
            infiniteItemsSelector = '.mosaic-box';
        }

        let infinite;
        // console.log('infiniteItemsSelector',{
        //     $tableTd,
        //     infiniteItemsSelector,
        //     infiniteElement
        // });


        let changePagination = function () {
            console.log("paginatorSelect changePagination");
            window.location = $(this).val();
        };

        let createInf = ()=>{
            infinite = new Waypoint.Infinite({
                element: infiniteElement,
                //to działą tylko w task - w innych nieskonczona pętla wgrywania
                // context: infiniteContexSelector,
                more: 'a.meaNext',
                handler: function(e) {
                    console.log('Waypoint triggered',e)
                },
                // offset: function (){
                //     // console.log("OFFSET");
                //     // return this.context.innerHeight() - this.adapter.outerHeight()
                //     return 400;
                //     if ($(infiniteElement).closest('.table-responsive').length > 0 ){
                //          return $(infiniteElement).closest('.table-responsive')[0].clientHeight;
                //         return '50%'; //$(infiniteElement).closest('.table-responsive')[0].clientHeight
                //     }
                //
                //     return  'bottom-in-view';
                // },
                items: infiniteItemsSelector,
                onBeforePageLoad: () => {

                    $('select[data-mea-selector="paginatorSelect"]').off('change', changePagination)
                    $el.find('.fa-road').addClass('fa-beat text-danger');
                    // $mea.messages.indicator(1);
                },
                onAfterPageLoad: items => {

                   // console.log('onAfterPageLoad',items,);

                    // console.log('onAfterPageLoad',$el,)
                    new ListColumnsVisibilitySwitcher('[data-mea-type=ColumnsVisibility]');

                    // $mea.messages.indicator(0);
                    $el.find('.fa-road').removeClass('fa-beat text-danger');

                    $mea.ajax.activateContent(infiniteElement);
                    $(infiniteElement).trigger( "resize" );

                    let currenPage = parseInt($('a.meaNext').data('next-page')) - 1;

                    // console.log('activateContent infiniteElement',infiniteElement);
                    $('select[data-mea-selector="paginatorSelect"] option').prop('selected', false);
                    $('select[data-mea-selector="paginatorSelect"] option[data-current-page="'+currenPage+'"]').prop('selected', true);
                    $('select[data-mea-selector="paginatorSelect"]').trigger('change');
                    $('select[data-mea-selector="paginatorSelect"]').one('change', changePagination)

                    $('[data-mea-selector="currentPage"]').html(currenPage);

                    $(infiniteElement).closest('[data-mea-type="sonata_list"]').trigger( "mea_list_infinity_after_load");

                }
            });
            //console.log('infinite created for ',{ infiniteElement, infinite} );
            window.infinite = infinite;
            // if(infinite.waypoint)
            // infinite.waypoint.disable();
        }

        // createInf();
        window.createInf = createInf;
        // console.log('infinity load ',infinite);
        // $el.find('.box-footer-pagination').hide();
        // $el.find('.box-footer-page-results').hide();
        $el.find('.infinitySwitch').addClass('iactive');


        $el.find('.infinityLoadMore').show().click((ev)=>{
            // console.log('infinityLoadMore click');
            // infinite.waypoint.enable();
            if((typeof infinite) != 'undefined' && (typeof infinite.waypoint) != 'undefined'){
                infinite.waypoint.trigger();
            }else{
                console.log('infinityLoadMore click infinite empty');
                // infinite.waypoint.enable();
            }

            // infinite.waypoint.disable();
        });

        //always visible
        $el.find('.infinitySwitch').click((ev)=>{

            let btnI = ev.currentTarget;

            if($(btnI).hasClass('iactive')){

                $el.find('.box-footer-pagination').find('a').each(function (){
                    $(this).attr('href',
                        $mea.ajax.urlReplace($(this).attr('href'), 'infinity', 'disabled')
                    );
                });
                $el.find('.box-footer-pagination').find('select option').each(function () {
                    $(this).val(
                        $mea.ajax.urlReplace($(this).val(), 'infinity', 'disabled')
                    )
                })

                $el.find('.box-footer-pagination').show();
                $el.find('.select-results-per-page').show();

                $(btnI)
                    .removeClass('iactive')
                    .attr('title',$(btnI).data('title-inactive'))
                ;
                // infinite.disable();
                infinite.destroy();
                $el.find('.infinityLoadMore').hide();

            }else{

                $el.find('.box-footer-pagination').hide();
                $el.find('.select-results-per-page').hide();

                // $el.find('.box-footer-pagination').hide();
                // $el.find('.box-footer-page-results').hide();
                $(btnI)
                    .addClass('iactive')
                    .attr('title',$(btnI).data('title-active'))
                ;
                createInf();
                //console.log('createInf');
                // infinite.enable();

                $el.find('.infinityLoadMore').show();

                $el.find('.box-footer-pagination').find('a').each(function (){
                    $(this).attr('href',
                        $mea.ajax.urlReplace($(this).attr('href'), 'infinity', 'enable')
                    );
                });

                $el.find('.box-footer-pagination').find('select option').each(function () {
                    $(this).val(
                        $mea.ajax.urlReplace($(this).val(), 'infinity', 'enable')
                    )
                })
            }
        });

        $('table.sonata-ba-list').on("remove", ()=>{
            // console.log('infinite destroy');
            if(infinite)
                infinite.destroy();
        });

        if ($mea.ajax.urlParam('infinity') === 'disabled') {
            $el.find('.infinitySwitch').addClass('iactive');
        }else {
            $el.find('.infinitySwitch').removeClass('iactive');
        }

        $el.find('.infinitySwitch').trigger('click');

    },

    listFieldsDivideInit(){


        // $('[data-list-divide="1"] .divide-footer a.expand').click((e)=>{
        //     let $el = $(e.currentTarget);
        //     $el.closest('tr').next().toggle();
        // });

        $('.openAll').click(()=>{
            $('div[data-list-divide="1"] tr.crud_list_tr:not(.mea-list-helper), [data-mea-list-divide="week"]').show();
        });
        $('.closeAll').click(()=>{
            $('div[data-list-divide="1"] tr.crud_list_tr:not(.mea-list-helper), [data-mea-list-divide="day"]').hide();
        });

        $('.openAllMonths').click(()=>{
            $('[data-mea-list-divide="week"]').show();
        });
        $('.closeAllMonths').click(()=>{
            $('[data-mea-list-divide="day"], [data-mea-list-divide="week"], .crud_list_tr:not(.mea-list-helper) ').hide();
        });

        $mea.ajax.registerRelase('listFieldsDivideList','tr.crud_list_tr',(el)=>{

            if($(el).closest('div[data-mea-type="sonata_list"]').data('list-divide')!==1) {
                return ;
            }

            if($(el).hasClass('mea-list-divide')===false){
                // $(el).hide();
                $(el).toggle();
            }else{

                $(el).find('a.expand').click((e)=>{

                    let btnIcon = $(e.currentTarget).find('i');
                    let action;

                    if(btnIcon.hasClass('fa-compress-arrows-alt')){
                        action = 0;
                        btnIcon.removeClass('fa-compress-arrows-alt').addClass('fa-expand-arrows-alt');
                    }else{

                        action = 1;
                        btnIcon.removeClass('fa-expand-arrows-alt').addClass('fa-compress-arrows-alt');
                    }

                    let $el = $(e.currentTarget).closest('tr');

                    let divideType = $(el).data('mea-list-divide');

                    console.log('divideType ',action, divideType);

                    $el.nextAll('tr').each((k,el2)=>{

                        let $el2 = $(el2);
                        let elType = $el2.data('mea-list-divide');

                        if(elType === divideType || (divideType ==='day' && elType) ){

                            console.log('break same type or day', divideType, $el2);

                            return false;

                            // }else if(!$el2.hasClass('mea-list-divide')){
                        }else{

                            if(action===1) {

                                //data row
                                if(!$el2.data('mea-list-divide') ){
                                    if(divideType==='day'){
                                        console.log('show ','data',$el2);
                                        $el2.show();
                                    }

                                }else{
                                    console.log('show ',$el2.data('mea-list-divide'),$el2);
                                    $el2.show();
                                }


                            }else{

                                if(!$el2.data('mea-list-divide') ){
                                    if(divideType==='day'){
                                        console.log('hide ','data',$el2);
                                        $el2.hide();
                                    }

                                }else{
                                    console.log('hide ',$el2.data('mea-list-divide'),$el2);
                                    $el2.hide();
                                }
                            }
                        }

                    });
                    // $el.closest('tr').nextAll(':not(.mea-list-divide)').toggle();

                });

            }





        });

    },

    onMeaDeleteChild: function(e){

        console.log('onMeaDeleteChildOK',e);

    },

    /**
     * $mea.sonataAdmin.disableConfirmExit(selectorForm);
     * @param selectorForm (tagName #id .class)
     */
    disableConfirmExit: (selectorForm) =>{
        $(selectorForm).removeAttr('data-original');
    }

};

$mea.sonataAdmin = meaSonataAdmin;