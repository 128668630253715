import Tools from  '../../webpack/Tools'

//for assync
//npm install --save regenerator-runtime
import 'regenerator-runtime/runtime';

/**
 * find with self
 * Search for selection include container
 * $('[data-prototype]').findBack('[data-prototype]') - this work
 * @param expr
 * @returns {*}
 */
$.fn.findSelf = function(expr) {
    var r = this.find(expr);
    if (this.is(expr)) r = r.add(this);
    return this.pushStack(r);
};

window.$mea = {

    config:{
        // locale: 'en',
        dev: process.env.NODE_ENV === "development"
    },

    app_url: '', //http://mea4.dogmat.eu/

    messages : { version : 'unloaded' },

    ui: {

        highlight: function(el){

            $(el).addClass('red').delay(1000).queue(function(next){
                $(this).removeClass('red');
                next();
            });

            // $(el).addClass('highlight').delay(1000).queue(function(next){
            //     $(this).removeClass('highlight');
            //     next();
            // });
        },
        scrollTo: function(el){
            $(el)[0].scrollIntoView();
        }

    },

    trigger: function(module,name,data){

        try{

            module = eval(module);

            name = eval(name);

            $(module).trigger( name, data);

            console.log('$mea trigger ',module,name,data);

        }catch (e) {

            console.log('$mea trigger error ',module,name,data,e);

        }

    },

    forms: {version : 'unloaded'},

    error : {

        /**
         * todo: rejestrowanie błędów po stronie przeglądarki
         * @param name
         * @param desccription
         */
        register: function(name, desccription){
            console.log('meaError',name, desccription);
        }

    },

    request : {

        /**
         * Pobiera zmienną z URL - GET
         */
        get : function(name){
            return decodeURI(
                (RegExp(name + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]
            );
        }


    },

    /**
     * 'data-mea-type'
     */
    types: {

        list: {},

        register: function(){
            $mea.ajax.registerRelase('mea_type', '[data-mea-type]',
                function(el) {
                    $mea.types.init(el);
                }
            );
        },

        init: function($el){

             //console.log('meaType found ',$el.data('mea-type'),$el);

            let type = $el.data('mea-type');

            if($el.data('init-as') === type ) {
                // console.log('mea-type fired before',type);
                return ;
            }

            //console.log('try mea type',$mea.types.list['task'], type , this.list, typeof this.list['task']);

            if(typeof this.list[type] === 'function'){
                this.list[type]($el);
                // console.log('$meaType fired init type',type,this.list);
                $el.data('init-as',type);
            }else{
                // console.log('meaType found but Not Registered',type);
            }


        },

        add: function(name,func){
            if(this.list[name]){
                //console.log('duplicated register mea type', name);
                return ;
            }
            //console.log('mea register mea type', name);
            this.list[name]=func;
            if($mea.ajax.contentisActivated){
                // this.log('reactivate content for mea-type ',name);
                $mea.ajax.activateContentByType('mea_type','body',true);
            }

        }


    },

    /**
     * Zamiennik dla $mea.log(); - które wywala błąd np w ie bo nie ma console.
     */
    log: function() {

        if (typeof console == "undefined") {
            return;
        }

        let args = Array.prototype.slice.call(arguments);

        function getErrorObject(){
            try { throw Error('') } catch(err) { return err; }
        }

        let err = getErrorObject();
        let caller_line = err.stack.split("\n")[4];
        if(caller_line){
            var index = caller_line.indexOf("at ");
        }else caller_line = '';

        let clean = caller_line.slice(index+2, caller_line.length);
        let title = 'meaLog';

        if(typeof args[0] ==='string')
            title = title+ ' '+args[0]

        console.log(title,{
            arguments: args,
            caller: clean
        });

    },

    debug: {
        modules_enabled: [],
        enableMod: function(mod){
            this.modules_enabled.push(mod);
        },
        add: function(title, log,module){

            if($.inArray(module, this.modules_enabled) !== -1)
                console.log('meaDebug:'+module,title,log);

        },


    },

    browser :{

        /**
         if ($mea.browser.version() < 9) {
      ... // if client is using IE6 or lower, run this code
    }
         * @returns {number}
         */

        version: function() {

            var version = 999; // we assume a sane browser
            if (navigator.appVersion.indexOf("MSIE") != -1)
            // bah, IE again, lets downgrade version number
                version = parseFloat(navigator.appVersion.split("MSIE")[1]);
            return version;
        }


        ,oldlock : function(errorMessage, minimalVersion){

            if(!minimalVersion) minimalVersion = 9;

            if(this.version() <minimalVersion){

                if(!errorMessage) errorMessage = '<p>Twoja przeglądarka jest przestarzała (ie > 9 lub ff / chrome).</p> <p>Ta strona zawiera treści wymagające aktualnej wersji przeglądarki </p>';

                errorMessage += '<p>Polecamy zainstalowanie teraz aktualnej, bezpiecznej i nowoczesnej przeglądarki</p>';
                errorMessage += '<a href="https://www.mozilla.org/pl/firefox/new/" target="blank"  class="ui_btn">INSTALUJE TERAZ</a>';
                errorMessage += '<a href="https://www.mozilla.org/pl/firefox/new/" target="blank"  class="ui_btn">INSTALUJE TERAZ</a>';
                errorMessage += '<p><i>Po kliknięciu INSTALUJE proszę o postępowanie zgodnie ze wskazówkami na ekranie (pobranie i instalacja firefox)</i></p>';

                $('html').append('<div id="browserOldLockDialog"><p class="ui_info">'+errorMessage+'</p></div>');

                $mea.ajax.activateContent('#browserOldLockDialog');

                d_dialog({"contentFromId":"browserOldLockDialog",noclose:true,modal:true, width:400, height:350, title:"WYMAGANA AKTUALIZACJA PRZEGLĄDARKI"});




            }

        }

    },

    maps: {


        /**
         * $mea.maps.loadJs().then(res => {
                this.start();
            });
         * @returns {Promise<unknown>}
         */
        async loadJs(){

            if(window.meaMapsLoaded===2) {
                return new Promise(resolve => {
                    // console.log('maps loaded before');
                    resolve('resolved');
                });
            }

            //loading now
            if(window.meaMapsLoaded ===1){
                console.log('loading now');
                return this.loading;
            }

            window.meaMapsLoaded  = 1;

            this.loading =  new Promise(resolve => {

                //add  data-maps-key="{{ mea.config.getMapsApiKey }}" to <body
                let mapsApiKey = $('body').data('maps-key');
                if(!mapsApiKey) mapsApiKey = 'AIzaSyBwKpvXwVVxzaYNPd27qEMhFVrjeqTPgeI';

                $.ajax({
                    type: "GET",
                    url: "https://maps.googleapis.com/maps/api/js?key="+mapsApiKey+"&libraries=places&callback=$mea.maps.loaded" ,
                    success: ()=>{
                        window.meaMapsLoaded =2;
                        // console.log('maps loaded');
                        resolve('resolved');

                    },
                    async:false,
                    dataType: "script",
                    cache: true
                });
            });

            return this.loading;


        },

        loaded: function(){
            window.meaMapsLoaded = 1;
        }
    },

    time: {

        /**
         * $mea.time.odliczanieDni(2014,05,23)
         * Odliczanie dni do danej daty
         * @param year
         * @param month 1-12
         * @param day
         * @returns {string}
         */
        odliczanieDni : function ileDni(year, month, day)
        {

            var date1 = new Date();
            var date2 = new Date(year, (parseInt(month)-1), day);

            var diff = date2.getTime() - date1.getTime();

            var dni = Math.floor(diff / (1000 * 60 * 60 * 24));
            if (dni == 1) return "1 dzień";
            else return dni + " dni";
        },

        format: function() {

        }

        , dzien: function() {
           let dni = new Array("Poniedziałek"
                , "Wtorek"
                , "Środa"
                , "Czwartek"
                , "Piątek"
                , "Sobota"
                , "Niedziela");
        }

        /**
         * Zwraca następny dzień pon - pt
         * day = 0 .. 5 , 0 = niedziela
         */
        , nextDay: function(day, startDate)
        {

            if (startDate)
                var d = new Date(startDate);
            else
                var d = new Date;
            (day = (Math.abs(+day || 0) % 7) - d.getDay()) < 0 && (day += 7);
            return day && d.setDate(d.getDate() + day), d;

        }


    },

    recursiveFunction: function recursiveFunction(base, key, val) {
        //   actualFunction(key, val);


        if (val instanceof Object) {
            $.each(val, function(key, val) {

                var Localbase = base + '[' + key + ']';

                $mea.recursiveFunction(Localbase, key, val);

            });
        } else {

            $mea.recursiveOut[base] = val;
        }

    },

    tools: Tools,

    /**
     * $mea.class['MeaViewport'] = MeaViewport;
     */
    class :{

    }

};
