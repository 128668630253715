require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/progressbar');
require('jquery-ui/ui/disable-selection');

//x-editable
// import './../jslib/x-editable-develop/dist/bootstrap4-editable/js/bootstrap-editable.min';

// import './../jslib/x-editable-develop/dist/bootstrap3-editable/js/bootstrap-editable';
// import 'mea-core-js/dist/sonata/bootstrap3-editable/js/bootstrap-editable.min.js';

import "./../js2/mea.pack";
import "./mea_sonata_relases";
import "./../../../../LogBundle/Resources/webpack/mealog2";
import "./../../../../PostBoxBundle/Resources/webpack/postbox";
import "./../../../../CoreBundle/Resources/public/js2/mea.editable";
import "./../../../../CoreBundle/Resources/webpack/Sonata/sortable";
// import "./../../../../CoreBundle/Resources/webpack/MeaDraggableViewport";
import "./../../../../ContactBundle/Resources/webpack/mea.form.widget.locations.add";

import 'timeago';

import Cookies from 'js-cookie';
/**
 * terminal
 *      npm i monaco-editor monaco-editor-webpack-plugin
 * webpack.config.js
 *      const MonacoWebpackPlugin = require('monaco-editor-webpack-plugin')
 *
 *        Encore.addPlugin(
 new MonacoWebpackPlugin()
 )
 */
// import * as monaco from 'monaco-editor'
//
// import {MeaMercure} from "./../../webpack/MeaMercure";
// $mea.types.add('mercure', ($el)=>{
//     $mea.mercure =  new MeaMercure($el);
// });

import ListColumnsVisibilitySwitcher from './ListColumnsVisibilitySwitcher';
import FilterBoxVisibilitySwitcher from './FilterBoxVisibilitySwitcher';
import FilterCriteria from './FilterCriteria'
import ListSearchInline from './ListSearchInline';

// import Sticky from 'sticky-js';

import './MeaSonataAdmin'

/**
 * List form
 * data-mea-type='sonata_list'
 * data-admin-code='mea.task.task.admin'
 *
 * todo
 * data-mea-type='sonata_edit'
 * data-admin-code='mea.task.task.admin'
 *
 */

// window.monaco = monaco;

$(()=>{
    $('body').on('shown.bs.modal', '.modal', e => {
        $mea.ajax.activateContent($(e.currentTarget));
    });
});


// $mea.types.add('ColumnsVisibility', (element)=> {
//     new ListColumnsVisibilitySwitcher('[data-mea-type="ColumnsVisibility"]');
// });

$(()=>{

    /**
     * Search in Menu
     */
    $('input[name="q"], input[data-mea-selector="globalSearch"]').on('keyup', event => {
        let value = $(event.target).val().toLowerCase();
        $('.sidebar-menu .menu_level_1 li, [data-mea-selector="navbar-left"] .menu_level_1 li').each((index, item) => {
            if ($(item).text().toLowerCase().indexOf(value) > -1) {
                $(item).toggle(true);
                $(item).closest('.collapsible-body').toggle(true);
            }else {
                $(item).toggle(false);
            }

        });

        $('.sidebar-menu > li, [data-mea-selector="navbar-left"] > ul > li').each((index, item) => {
            $(item).toggle(
                $(item).find('.menu_level_1 li').filter((i, v)=>$(v).css('display') != 'none').length > 0
            );
        });

        if (value !== '') {
            $('.sidebar-menu > li, [data-mea-selector="navbar-left"] > ul > li').filter(':visible').each((i, v) => {
                $(v).addClass('active').find('ul').toggle(true);
            });
        }else {
            $('.sidebar-menu > li, [data-mea-selector="navbar-left"] > ul > li').filter(':visible').each((i, v) => {
                $(v).removeClass('active').find('ul').toggle(false);
            });

            let $activeItem = $('.menu_level_1 li.active');
            $activeItem.closest('ul').closest('li').addClass('active');
            $activeItem.closest('ul').toggle(true);

            $('.sidebar-menu .collapsible-body, [data-mea-selector="navbar-left"] .collapsible-body').toggle(false);
            $('.sidebar-menu .active .collapsible-body, [data-mea-selector="navbar-left"] .active .collapsible-body').toggle(true);

        }
    });
    $('input[name="q"], input[data-mea-selector="globalSearch"]').closest('form').on('submit', e => {return false;});
    /** --- **/

    /**
     * clear modal
     */
    $('body').on('hidden.bs.modal', '.modal', function(e)
    {
        $(this).removeData();
        $(this).find('.modal-body').html('')
    });

    /**
     * event visibility
     * example
     * $('element').on('visibility', function(e) {
     * console.log(e.visible ? 'visible' : 'hidden');
     * });
     *
     * niedziala to tak do konca jak powinno
     * 
     */
    if (window.IntersectionObserver) {
        $.event.special.visibility = {
            setup: function() {
                let element = this;
                let observer = new IntersectionObserver((entries) => {
                    let e = $.Event("visibility");
                    e.visible = !!entries[0].intersectionRatio; //intersectionRatio will not work if the element have position: fixed so if you can use jQuery you can check $(element).is(':visible');
                    ($.event.dispatch || $.event.handle).call(element, e);
                }, {
                    root: document.body
                });
                observer.observe(element);
                $.data(this, 'observer', observer);
            },
            teardown: function() {
                let observer = $.data(this, 'observer');
                if (observer) {
                    observer.unobserve(this);
                    $.removeData(this, 'observer');
                }
            }
        };

        /**
         * animated progress bar is visibility
         */
        let options = {};
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                let $el = $(entry.target);
                let isAnimate = parseInt($el.attr('mea-progres-animate'));

                if (isAnimate) {
                    if (entry.intersectionRatio === 0){
                        $el.css('width', '0');
                    } else {
                        $el.animate({
                            width: $el.attr('aria-valuenow')+'%'
                        }, 1000);
                        $el.attr('mea-progres-animate', '0');
                    }
                }
            });
        }, options);
        document.querySelectorAll('.progress-bar[mea-progres-animate]').forEach((item) => {
            observer.observe(item);
        });

    }

});

