
$mea.ajax.registerRelase(
    'js-sortable-move',
    '.sonata-ba-list:has(.js-sortable-move)',(el)=>{
        $(el).draggableTable();
    });

$.fn.draggableTable = function() {
    $(this).each(function (index, item) {
        item = $(item);
        if (!item.data('DraggableTable')) {
            item.data('DraggableTable', new DraggableTable(item));
        }
    });
};

var recalculateDraggableTable = function (){
    console.log('recalculated');
    let first = 1;
    $('body').find('.js-sortable-move').each(function(index, item) {
        $(item).attr('data-current-position', first + (index ));
        $(item).html(first + (index ));
    });
};

var DraggableTable = function(element) {
    var movers = element.find('.js-sortable-move');
    if (movers.length <= 1) return;

    var $document = $(document);
    var $body = $(document.body);

    var first = parseInt(movers.first().attr('data-current-position'));
    var last = parseInt(movers.last().attr('data-current-position'));
    var direction = first <= last ? 1 : -1;

    element.find('tbody').sortable({
        'handle': '.js-sortable-move',
        'start': function() {
            $body.addClass('is-dragging');
        },
        'stop': function() {
            setTimeout(function() {
                $body.removeClass('is-dragging');
            }, 100);
        },
        'axis': 'y',
        'cancel': 'input,textarea,select,option,button:not(.js-sortable-move)',
        'tolerance': 'pointer',
        'revert': 100,
        'cursor': 'move',
        'zIndex': 1,
        'helper': function(e, ui) {
            ui.css('width', '100%');
            ui.children().each(function() {
                var item = $(this);
                item.width(item.width());
            });
            return ui;
        },

        'update': function(event, ui) {

            var moved = $(ui.item).find('.js-sortable-move');

            var startPos = moved.attr('data-current-position');

            // element.find('.js-sortable-move').each(function(index, item) {
            //     $(item).attr('data-current-position', first + (index * direction));
            //     $(item).html(first + (index * direction));
            // });

            var newPosition = moved.attr('data-current-position');

            let movement = newPosition - startPos ;

            var insertAfter = parseInt($(ui.item).prev().find('.js-sortable-move').attr('data-current-position')) ;

            // console.log('newPosition', {
            //     startPos: startPos,
            //     newPos: newPosition,
            //     movement: movement,
            // });
            // return ;

            $document.trigger('SortableBehaviorBundle.update', [event, ui]);

            moved.attr('data-current-position', insertAfter+1);

            $.ajax({
                'type': 'GET',
                'url': moved.attr('data-url').replace('NEW_POSITION', insertAfter+1),
                'dataType': 'json',
                'error': function(data) {
                    $document.trigger('SortableBehaviorBundle.error', [data]);
                },
                'success': function(data) {
                    $document.trigger('SortableBehaviorBundle.success', [data]);
                },
                'complete': function() {
                    $document.trigger('SortableBehaviorBundle.complete');
                }
            });
        }
    }).disableSelection();
};

// recalculateDraggableTable();
