$mea.ajax.registerRelase('select2','select.select2', function (el,selector){
    $(el).select2();
});

$mea.ajax.registerRelase('teamReminder','.teamReminder', function (el,selector){
    bralion.teamReminder.activate(el);
});


$mea.ajax.registerRelase('taskTimeTracker','.taskTimeTracker', function (el,selector){

    if($(el).attr('data-rel-timetracker')=="1") return ;

    $mea.task.timetracker.relase(el);

    $(el).attr('data-rel-timetracker',"1");

});


// itbMoney.exchangeRate = parseFloat($('#exchangeRate').html());

$mea.ajax.registerRelase('moneyExchange','.moneyExchange',
    function(el, selector) {
        var includeOriginValue = false;
        if(el.attr('data-exchange-compare'))
            includeOriginValue = true;
        itbMoney.exchange(el,includeOriginValue);
    }
);


// $mea.ajax.registerRelase('datatable','.datatable', function (el,selector){
//     $(el).DataTable();
// });

// $mea.ajax.registerRelase('mealink','.mealink', function (el,selector){
//     $mea.ajax.hrefHistory(el);
// });



$mea.ajax.registerRelase('mea_sn_timeago','.timeago', function (el,selector){

    $(el).attr('data-timeago-date-value',$(el).data('datetime'));
    $(el).attr('data-controller','timeago');

    // $.timeago.settings.allowFuture = true;
    // let date;
    // try{
    //
    //     if($(el).data('datetime')){
    //         date = moment($(el).data('datetime'))
    //     }else{
    //         date = moment($(el).html())
    //     }
    //
    //     $(el).timeago('update',date.toDate());
    //
    //     $(el).attr('title',date.format('d MMMM Y H:mm:ss'));
    //
    //     // console.log('timeago', {
    //     //     el,
    //     //     now: new Date(),
    //     //     moment: date.toDate(),
    //     // });
    //
    // }catch (e){
    //     console.log('mea_sn_timeago',e);
    // }

});



$mea.ajax.registerRelase('toggleCheckbox','[data-toggle="toggle"]', function (el,selector){

    //fix boostrap ajax
    if($(el).closest('div').hasClass('toggle'))
        return;
    $(el).bootstrapToggle();

});
$mea.ajax.registerRelase('ui_btn','.ui_btn', function (el,selector){

    if(!$(el).hasClass('btn'))
        $(el).addClass('btn-default btn');

});

// $mea.ajax.registerRelase('href_ajax', 'a[data-rel=ajax],a[rel=ajax], a[target=ajax],button[rel=ajax]',
//     function(el, selector) {
//         $mea.ajax.hrefAjax(el);
//     }
// );


// $mea.ajax.registerRelase('href_history', 'a[data-rel=history],a[rel=history]',
//     function(el, selector) {
//         $mea.ajax.hrefHistory(el);
//     }
// );

// $mea.ajax.registerRelase('href_dialog', 'a[data-rel=dialog],a[rel=dialog], a.dialog, a[target=dialog]',
//     function(el, selector) {
//         $mea.ajax.hrefDialog(el);
//     }
//
// );
