import "./releases_main.js";

// $mea.types.add('sonata_link_action_create',($el)=>{
//     if($el.closest('.meaDialog').length > 0){
//         $el
//             .addClass('noHpl')
//             .click((e)=>{
//
//                 let url  = $el.attr('href');
//                 $mea.dialog.createFromUrl(url);
//
//                 // $mea.ajax.load(
//                 //     $el.attr('url') ,{},{
//                 //         urltarget: 'dialog'
//                 //     }
//                 // );
//                 console.log('clicked',{
//                     url: url
//                 });
//                 return false;
//             })
//         ;
//     }
// });

//xeditable in sonata list
$mea.ajax.registerRelase('x-editable','.x-editable',($el)=>{
    if($el.closest('[data-mea-type="sonata_list"]').length>0) return;
    try{
        $el.editable();
    }catch (e) {
        console.log('editable exception',e);
    }

});

$mea.ajax.registerRelase('select2Snt','select:not(.noSel2):not([data-sonata-select2=false])', function ($el,selector){
    let params ={};
    if($el.attr('path')){
        params.ajax={
            delay: 250,
            url: $el.attr('path'),
            dataType: 'json',
            processResults: function (data) {
                return {
                    results: data
                };
            }
        }
    }

    //right
    if($el.data('dir'))
        params.dir = $el.data('dir');

    if($el.data('default')){
        if(!$el.val()) $el.val($el.data('default'));
    }

    //todo:mea add from data tag
    // params.tags= true;
    $($el).select2(params);

});

// $mea.ajax.registerRelase('sonata_admin_dialog_link_action_create','.modal-dialog .link_action_create',function(el) {
//  $(el).click(()=>{
//      console.log('create click from dialog ');
//  });
// });

$mea.ajax.registerRelase('sonata_admin_dialog_form_delete','.meaDialog .form-actions a.btn.btn-danger',function(el){

    $(el).click(function(e){

        e.preventDefault();

        if($mea.messages.confirm("",function(){

            let url =  $mea.ajax.urlAdd(
                $(el).attr('href'),'ajaxConfirmed=1'
            );

            $mea.ajax.run(
                url
            );

            $mea.dialog.remove($(el).closest('.meaDialog'));

            console.log("deleted",url);
        }));

        return false;

    });

});

$mea.ajax.registerRelase('sonata_admin_dialog_oder_change','.meaDialog .sonata-ba-list-field-header a',function(el){
    let dialog = $(el).closest('.meaDialog');
    let idContent = dialog.find('.meaDialogBody').attr('id')
    if(!idContent){
        idContent = 'admin_dialog_'+Math.floor((Math.random() * 10000) + 1);
        dialog.find('.meaDialogBody').attr('id',idContent);
    }

    $(el).attr('data-rel','dialog');



});
