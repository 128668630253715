/**
 in twig - not for forms
 {{ render_hinclude(controller('MeaLogBundle:LogInline:listEditable',{
    rtype: rtype|default('mea.log.relation.task'),
    rid: rid,
    weight: weight|default(),
    perPage: perPage|default(5),
    editable: editable|default(true)
})) }}

 or

 <hx:include src="{{ mea.router.adminCodeUrl('admin.job','list',{
            filter:{
                status:{
                    value: constant('App\\Entity\\MontiJob::STATUS_DRAFT')
                }
            }
        }) }}"> {{ include('@MeaCore/Default/loading.html.twig') }}
 </hx:include>

 alternative

 {{ meaRenderAdminAsBlock({
    controller: 'Mea\\ArticleBundle\\Sonata\\Controller\\RatingVoteController::listAction',
    admin: 'mea.rating.vote.admin',
    action: 'list',
    attributes: {
        _widget_mode: 'public',
        _mea_type: object.toMeaTypeString,
    }
})|raw }}

php

 $this->getRouter()->embedHincludeFragment(
     MontiProductCrudController::class,
     'jobListAction',[
     'jobId'=>$this->getSubject()->getId(),
     '_sonata_admin'=> 'admin.job.product'
     ]
 )


 $form->add('ekipy',StringType::class,[
 'data'=>'<div data-mea-type="viewport_observer"><hx:include src="'.$teamTableUrl.'"></hx:include></div>'
 ]);

 */

//import MeaViewport from "./meaViewport";

$mea.ajax.registerRelase('hinclude','hx\\:include', function (el, selector){


    let onLoaded = (el)=>{
        $(el).trigger('hinclude_load');
        $(el).data('hinclude-loaded',1);
        let form = $(el).closest('form');
        if($(el).closest('form').length > 0){
            // console.log('hinclude_load form_elements_added',el);
            $(form).trigger(
                $.Event($mea.forms.events.form_elements_added,{
                    mea_field: el
                })
            );
        }else{
            // console.log('hinclude_load form_elements_empty',$(el).closest('form'));
        }
    }

    if($(el).closest('[data-mea-type=viewport_observer]').length>0){

        let container = $(el).closest('[data-mea-type=viewport_observer]');

        let viewport = new $mea.class.MeaViewport(el);

        viewport.onVisible(()=>{

            // console.log('isInVievport',el);

            if($(el).data('refresh-on-visible')!==1)
                $(el).html(null);

            $mea.ajax.loadingElement(container);

            $(el).load($(el).attr('src'), ()=> {

                $mea.ajax.loadingElementEnd(container);

                $mea.ajax.activateContent($(el).parent());

                // console.log('hinclude',$(el).parent());

                if($(el).data('refresh-on-visible')!==1){

                    viewport.disable();
                    viewport = null;

                }

                onLoaded(el);

                // if($(el).find($mea.forms.formElements))
                
                // console.log('isInVievport finished, removig listener');

            });
        });

        // viewport.onHidden(()=>{
        //     console.log('hidden');
        // });



    }else{

        $(el).html(null);
        $mea.ajax.loadingElement(el)

        $(el).load($(el).attr('src'), ()=> {

            $mea.ajax.loadingElementEnd(el);

            $mea.ajax.activateContent($(el));

            onLoaded(el);

        });

    }

});