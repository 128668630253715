$mea.platform={
    EVENT_ENTITY_CREATED: 'entity.created',
    EVENT_ENTITY_REMOVED: 'entity.removed',
    object:{
        created(object){

        },
        removed: function (meaTypeId){

            let meaTypeData = this.meaTypeIdToData(meaTypeId);
            let $objectDetails = $('#'+meaTypeId);

            let $objectListItems = $('[data-mea-list-type-value="'+meaTypeData.type+'"]')
                .find('.crud_list_tr[data-id="'+meaTypeData.id+'"]');


            console.log('mea.platform removing ',{
                meaTypeData: meaTypeData,
                listItems: $objectListItems.length,
                items: $objectDetails.length
            });

            $objectDetails.slideUp();
            $objectListItems.remove();
        },

        /**
         * @example 'meaEntity:c_prod:359' -> c_prod
         * @param meaId
         * @returns {*}
         */
        meaTypeIdToType(meaTypeId){
            return meaTypeId.split(':')[1];
        },

        meaTypeIdToData(meaTypeId){
            let data =  meaTypeId.split(':');
            return {
                'id': data[2],
                'type': data[1],
            };
        }

    }
}