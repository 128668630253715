
//@deprecated - move to list-filters-controller.js
export default class ListSearchInline {
    constructor($containerInput){
        this.$containerInput = $containerInput;
        this.idListSearchInlineSelect = 'ListSearchInlineSelect'+$containerInput.data('id');
        this.$container =  this.$containerInput.closest('.mea-list-filters');
        this.init();
    }


    init(){

        let $form = this.$container.find('form.sonata-filter-form');
        let action = this.$container.find('form.sonata-filter-form').attr('action');
        let search_target_input = this.$container.find('#filter_search_value');

        // console.log('action',action);

        if(search_target_input.length===0){
            // console.log('ListSearchInline not found ignoring');
            return;
        }

        // console.log({
        //     action: action,
        //     container: this.$container,
        // });

        let $select = $('<input id="'+this.idListSearchInlineSelect+'">');
        $select = $('<select class="noSel2 " id="'+this.idListSearchInlineSelect+'">' +
            '<option>'+$mea.translator.trans('Search')+'</option>' +
            '</select>');

        this.$containerInput
            .append($select)
            .show()
        ;

        // return ;
        $select = $('#'+this.idListSearchInlineSelect);

        $select.on('keydown', function() {
          // console.log('event');
        });


        // $('').on('select2-search__field',()=>{
        //     console.log('cjhange');
        // })

    }

}

