// require('jquery');
// require('./mea.core');
// require('./mea.translator');
// require('jquery-validation');
import MeaForm from './../../webpack/form/meaform';
import MeaSonataForm from "./../../webpack/form/forma_sonata";
// npm i jquery-validation --save
import "jquery-validation";
// import 'jquery-validation/dist/jquery.validate.min'
import  './../jquery-serialize-object-master/dist/jquery.serialize-object.min';

// import 'jquery-ui/ui/widget'
$.fn.extend({
    bindEventHandlerAtStart: function ($elements, eventType, handler) {
        let _data;

        $elements.bind(eventType, handler);
        // This bound the event, naturally, at the end of the event chain. We
        // need it at the start.

        if (typeof jQuery._data === 'function') {
            // Since jQuery 1.8.1, it seems, that the events object isn't
            // available through the public API `.data` method.
            // Using `$._data, where it exists, seems to work.
            _data = true;
        }

        $elements.each(function (index, element) {
            let events;

            if (_data) {
                events = jQuery._data(element, 'events')[eventType];
            } else {
                events = jQuery(element).data('events')[eventType];
            }

            events.unshift(events.pop());

            // console.log('events1',jQuery._data(element, 'events')[eventType]);
            // console.log('events',element, events);

            if (_data) {
                jQuery._data(element, 'events')[eventType] = events;
            } else {
                jQuery(element).data('events')[eventType] = events;
            }
        });
    }
});

$mea.forms = {

    version: 2210503,
    disableAfterSend: true,
    params: {},//aktualne paramety formularza
    events: {
        response_success: 'mea.form.response.success',
        form_start_validation: 'mea.form.validate',
        /**
         * allow stop sending after validate and prepare data,
         */
        form_before_send: 'mea.form.send.before',
        form_before_unload_change_found: 'mea.form.unload.changed',
        form_elements_added: 'mea.form.elements.add'
    },
    protectSelector: 'input:not(.mea-protect-disabled),select:not(.mea-protect-disabled),textarea:not(.mea-protect-disabled)',
    formElements: 'input,select,radio,checkbox,textarea',
    config: {
        message_changes_found: 'You have some unsaved changes'
    },
    types: {},

    init: function(){

        $mea.ajax.registerRelase('hplFormSubmit','form[hpl="hpl"],form[data-hpl]',function(el){

            $.fn.bindEventHandlerAtStart( $(el).find('[type=submit]'),'click',(clickEvent)=>{

                let btn = clickEvent.currentTarget;

                // $(btn).attr('disabled','disabled');

                if($mea.forms.test(el,{})===false){

                    clickEvent.stopPropagation();
                    clickEvent.preventDefault();

                    // console.log('click return false');
                    // $(btn).removeAttr('disabled','disabled');
                    return false;

                }
                // $(btn).removeAttr('disabled','disabled');
                // console.log('click OK');
                return true;

            });


            $(el).on('submit',function(submitEvent){


                // console.log('onSubmit',el);
                if($(el).hasClass('noHpl')){
                    console.log('meaForm ignore because .noHpl');
                    return ;
                }


                // if($(el).data('testing')==1){
                //
                //     console.log('submit test');
                //     $mea.forms.send(this,{},submitEvent);
                //
                // }else{

                    submitEvent.preventDefault();

                    if($mea.forms.test(el,{})!==false){
                        console.log('form '+$(el).data('controller')+' submiting fired');
                        $mea.forms.send(this,{},submitEvent);
                    }else{
                        console.log('form '+$(el).data('controller')+' submiting blocked');
                    }

                // }

                return false;
            });

        });

        MeaForm.initTypes();


        $mea.ajax.registerRelase('meaForms','[data-mea-form]',function(el){

            let type= $(el).data('mea-form');
            let obj;
            let is_deditaced;

            if($mea.forms.types[type] !== undefined) {

                obj = $mea.forms.types[type](el);
                if(!obj){
                    console.log('meaError form not obj',type);
                }
                is_deditaced = true;

            }else{

                if($(el).data('type')==='crud-edit-form')
                    obj = new MeaSonataForm(el);
                else
                    obj = new MeaForm(el);

                is_deditaced = false;

            }

            $(el).data('mea_form_obj',obj);
            //$(el).data('mea-form',obj);

            // console.log('found meaForm', {
            //     class: type ,
            //     is_deditaced: is_deditaced,
            //     obj: obj
            // });

            // $(el).meaFormWidget({meaForm: obj});

            // console.log('meaType meaForms',$(el).meaFormWidget("log"));
        });

    },

    add: function(type,fn){
       this.types[type]=fn;
    },

    validate: {

        config: {
            msg_form_has_errors: "validation.form.has.errors",
            msg_field_exist: "validation.field.taken",
            error_class: 'error input_error',
            success_class: 'valid',
            errorPlacement: function (error, element) {
                return $mea.forms.validate.errorPlacement(error, element);
            }
        },

        hasError: false,

         makeid: function(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() *
                        charactersLength));
                }
                return result;
        },

        /**
         * $mea.forms.validate.validate
         * @param form
         * @returns {boolean}
         */
        validate: function (form)
        {
            // console.log('meavalidate fired');

            let $form = $(form);

            if($form.length === 0){

                console.log('mea validating form not found',{
                    given: form
                });
                return;
            }

            let name;

            //validator check is require exist - sometimes we have required=false
            $($form.find('[required="false"]')).removeAttr('required');
            $($form.find('input,select,textarea')).each((k,el)=>{
               if(!$(el).attr('name') && !$(el).hasClass('select-input')){
                   name = $(el).attr('id');
                   if(!name){
                       name = this.makeid(6);
                   }
                   $(el).attr('name',name).attr('data-name-reason','validation');
               }
            });


            var validator = $("form").validate();
            let checkValidity = $form[0].checkValidity();
            let valid = $form.valid();
            console.log('meavalidate fired',{
                checkValidity: checkValidity,
                valid: valid,
                errors: validator.errors(),
                invalidElements: validator.invalidElements(),
                reportValidity: $form[0].reportValidity(),
                notValidInputs: $(':invalid'),
            });

            if (checkValidity === false || valid === false) {

                console.log('meavalidate step 1',{checkValidity, valid });

                if(valid === false){

                    console.log('formINValid testing inputs');

                    let errors = [];
                    let errorsCounter = 0;

                    $($form.find('input,select,textarea')).each((k,el)=>{
                        if(el.checkValidity() === false){
                            errorsCounter++;
                            let label = $(el).closest('.form_row').find('label').text();
                            if(label)
                                errors.push(label);
                            console.log('NOT VALID ',label, $(el));
                        }
                    });

                    // console.log('errors',errorsCounter, errors);
                    if(errorsCounter > 0 && $form.data('msg-box') !== false) {

                        $mea.messages.error(
                            $mea.translator.trans(
                                $mea.forms.validate.config.msg_form_has_errors
                            )+' '+errors.join(', ')
                        );
                    }else{
                        console.log('formINValid testing inputs return OK');
                        return true;
                    }
                }else{

                    console.log('formValid testing html submit');

                    let $tmpSubmit = $('<input class="meaValidateTmpSubmit" style="display: none" type="submit" />');

                    $form.append($tmpSubmit);

                    // $form.data('testing',1);
                    $tmpSubmit.click();
                    // $form.data('testing',0);
                    $('.meaValidateTmpSubmit').remove();


                }

                console.log('not valid');

                return false;

            }

            return true;
        },

        isValidEmailAddress: function (emailAddress) {
            var pattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
            return pattern.test(emailAddress);
        },

        registerReleases: function () {

            $mea.ajax.registerRelase('formValidateAjax', '[data-validate-ajax]',
                function (el, selector) {
                    $mea.forms.validate.validateAjax(el);
                }
            );

        },

        /**
         * for override local
         * $mea.forms.validate.errorPlacement= function(error, element){
                console.log('ok');
                };
         */
        errorPlacement: function (error, element) {

            // console.log('AA error, element',error, element);
            element.after(error); // default error placement

        },

        successPlacement: function(error,input){
            let form = $(input).closest('form');

            error.remove();

            if(form && $(form).validate().errors().length ===0 ){
                $(form).find('[type=submit]')
                    .removeAttr('data-disable-reason')
                    .removeAttr('disabled');
            }
        },

        errorPlacementMdb(){

            $mea.forms.validate.successPlacement= function(label,el){

                $(el).closest('.md-form')
                    .find('input').removeClass('is-invalid')
                ;

                $(el).closest('.md-form').find('.invalid-feedback').remove();

            }

            $mea.forms.validate.errorPlacement= function(error, element){
                // console.log('app errorPlacement');
                $(element).addClass('is-invalid');
                $(element).closest('.md-form').find('label')
                    .attr('data-error', error[0].outerText)
                    .attr('data-success', '')
                ;


                if($(element).closest('.md-form').find('.invalid-feedback').length===0)
                $(element).closest('.md-form').append(
                    '<div class="invalid-feedback">'+error[0].outerText+'</div>'
                );

            };

        },

        //todo direct add listeners
        init: function (el, config) {

       //     console.log('validate init',el);

            if ($(el).data('mea-novalidate') === 'novalidate') {

                // console.log('mea-novalidate form', el, config);

                return;

            } else {

                // console.log('validate form', $(el).data('mea-novalidate'), el, config);

            }

            $mea.translator.onLoadedLibrary(()=>{

                $.extend($mea.forms.validate.config, config);


                //An invalid form control with name='contract.paymentType' is not focusable.
                $.validator.addMethod(
                    "select_mdb",
                    function (value, el) {
                        console.log('select_mdb',value, el);
                        return false;
                        // return $mea.forms.validate.isValidEmailAddress(value);
                    }
                );

                $.validator.addMethod(
                    'phonenumber',
                    function (value, element) {
                        return this.optional(element) || /^\d{9}$/.test(value);
                    },
                    $mea.translator.trans('Invalid phone number')
                );


                $.validator.addMethod(
                    "pattern",
                    function(value, element, regexPattern) {

                        const regex = new RegExp(regexPattern);

                        return this.optional(element) || regex.test(value);
                    },
                    "Please check your input."
                );

                $.validator.addMethod(
                    "email",
                    function (value, el) {
                        return $mea.forms.validate.isValidEmailAddress(value);
                    }
                );

                $.validator.addMethod(
                    "data-validate-ajax",
                    function (value, el) {

                        if($(el).data('validated-ajax') === value){
                            return $(el).data('validated-ajax-result');
                        }

                        let url = $(el).data("validate-ajax");
                        let succes = false;

                        let params = {};
                        if (typeof $(el).data('add-params') == 'object') {
                            params = $(el).data('add-params');
                        }
                        params['value'] = $(el).val();

                        $.ajax({
                            url: url,
                            data: params,
                            dataType: 'json',
                            async: false,
                            success: function (data) {
                                if (!data.valid) {
                                    succes = false;
                                } else {
                                    succes = true;
                                }
                            }
                        });

                        $(el).data('validated-ajax',value);
                        $(el).data('validated-ajax-result',succes);
                        return succes;
                    },
                    $mea.translator.trans(
                        $mea.forms.validate.config.msg_field_exist
                    )
                );

                let lang = $('html').attr('lang');
                let langs = {};
                langs['en'] = {...$.validator.messages};
                require('jquery-validation/dist/localization/messages_pl');
                langs['pl'] = {...$.validator.messages};
                require('jquery-validation/dist/localization/messages_pt_PT');
                langs['pt'] = {...$.validator.messages};

                if (typeof langs[lang] !== 'undefined') {
                    $.validator.messages = langs[lang];
                }else {
                    $.validator.messages = langs['en']
                }
                // console.log('meaValidate',{
                //     lang: lang,
                //     config: $mea.forms.validate.config,
                //     messages: $.validator.messages,
                // });

                $(el).validate({
                    lang: $mea.translator.getLocale(),
                    errorElement: 'label',
                    errorClass: $mea.forms.validate.config.error_class,
                    validClass: $mea.forms.validate.config.success_class,
                    ignoreTitle: true,
                    // wrapper: 'li',
                    debug: false,
                    submitHandler: function (form) {

                        //     console.log('validate submitHandler');
                        if ($(form).attr('hpl') !== 'hpl')
                            form.submit();
                    },

                    // errorPlacement: function (error, element) {
                    //     return $mea.forms.validate.errorPlacement(error, element);
                    // },
                    errorPlacement: $mea.forms.validate.config.errorPlacement,
                    success: $mea.forms.validate.successPlacement,
                    highlight: function (element, errorClass, validClass) {
                        $(element).addClass(errorClass).removeClass(validClass);
                    },
                    unhighlight: function (element, errorClass, validClass) {
                        $(element).removeClass(errorClass).addClass(validClass);
                    },
                });

                // $(el).find('input[type=submit] , button[type=submit]').click(function () {
                //     console.log('validate 1');
                //     return $mea.forms.validate.validate($(el));
                // });

                $(el).on('submit ' + $mea.forms.events.form_start_validation, function (e) {

                    // console.log('submit ' + $mea.forms.events.form_start_validation);

                    if ($(el).valid() === false) {
                        console.log('form validate failure ',{
                            errors: $(el).validate().errorList
                        });
                        // $mea.messages.add(
                        //     $mea.forms.validate.config.msg_form_has_errors
                        // );

                        e.preventDefault();
                        return false;
                    }

                    return true;
                }).data('mea-form-validation',1);



            });



        },

        addMethod(name, method, message) {
            $.validator.addMethod(name, method, message);
        },

        validateAjax: function (el) {

            el.on('blur invalid', (event) => {
                let validityState = el.get(0).validity;
                if (validityState.valid === false && validityState.customError !== false) {
                    $mea.forms.validate.renderStatus(el, validityState.valid, {message: el.get(0).validationMessage});
                }
            });

            $(el).on('input', function () {

                if ($(el).valid() === false) {
                    $mea.forms.validate.renderStatus(el, false);
                    return;
                }

                let url = $(el).data("validate-ajax");

                if(!$(el).val() && !$(el).prop('required')){
                    return true;
                }

                let succes = false;
                $.ajax({
                    url: url,
                    data: {
                        value: $(el).val()
                    },
                    dataType: 'json',
                    //async: false,
                    success: function (data) {
                        if (!data.valid) {
                            $mea.forms.validate.renderStatus(el, false, data);
                        } else {
                            $mea.forms.validate.renderStatus(el, true, data);
                        }
                    }
                });

            });
        },


        /**
         * responseData[valid=false, message="email taken"]
         * @param el
         * @param state
         * @param responseData
         */
        renderStatus: function (el, state, responseData) {

            if (state === false) {

                // console.log('renderStatus',state);
                $(el)
                    .addClass('has-error')
                    .removeClass('has-success')
                ;

                $(el).closest('form').find('[type=submit]')
                    .attr('disabled', 'disabled')
                    .attr('data-disable-reason','has_errors')
                ;

            } else {
                $(el)
                    .addClass('has-success')
                    .removeClass('has-error')
                // .tooltip({
                //     title:'OK'
                // }).tooltip('fixTitle').tooltip("show")
                ;

                $(el).closest('form').find('[type=submit]')
                    .removeAttr('data-disable-reason')
                    .removeAttr('disabled');
            }

        },

        addListenerToForm: function (config) {

            if (mea_contact.config.username_selector) {
                $(mea_contact.config.username_selector).on('input', function () {
                    mea_contact.renderStatus(this, mea_contact.checkAddUserName(this));
                });
            }

            if (mea_contact.config.email_selector) {
                $(mea_contact.config.email_selector).on('input', function () {
                    mea_contact.renderStatus(this, mea_contact.checkAddEmail(this));
                });
            }

            $(mea_contact.config.fieldSelector).closest('form').on('submit', function (e) {

                mea_contact.hasError = false;

                if (mea_contact.config.username_selector) {
                    var el = $(mea_contact.config.username_selector);
                    mea_contact.renderStatus(el, mea_contact.checkAddUserName(el));

                }

                if (mea_contact.config.email_selector) {
                    var el = $(mea_contact.config.email_selector);
                    mea_contact.renderStatus(el, mea_contact.checkAddEmail(el));

                }

                if (mea_contact.hasError) {

                    e.preventDefault();

                    console.log('mea_contact.hasError',mea_contact.hasError);

                }

                e.preventDefault();


            });

        },


        passwordSame: function (str) {

            //todo https://stackoverflow.com/questions/9142527/can-you-require-two-form-fields-to-match-with-html5

        },

    },

    autoSaveInput($selector){

        $selector.on('change',(e)=>{
            let form = $selector.closest('form');
            let url = this.getFormUrl(form);
            url = $mea.ajax.urlAdd(url,'auto_save=1');
            if($mea.forms.test(form,{}) ===true){
                $mea.forms.send(form,{
                    url: url,
                    disableAfterSend: false,
                    parseSuccesResponse: true,
                });
            }
        });

    },

    /**
     * @example $mea.forms.autoSave(form);
     * @param selector
     */
    autoSave: function (selector){
        console.log('autoSave' ,{
            selector: selector,
        });

        if($(selector).data('autosave-listener')==1){
            return ;
        }
        $(selector).data('autosave-listener',1);

        $mea.forms.onExitAskChanges(selector);

        let onChangeFn = (e)=>{
            let $el = $(e.currentTarget);

            //autosave only named inputs
            if(!$el.attr('name')){
                return ;
            }

            //todo: czy to ma sprawdzac atrybut dla poszczegolnego selectora (input, select itp) ? czy ma to sprawdzac czy formularz jest w trybie auto save
            // if($el.data('mea-autosave')!=1){
            //     console.log('mea-autosave disabled');
            //     return ;
            // }
            if (($el.prop('tagName') != 'FORM' && $el.closest('form').data('mea-autosave')!=1) || ($el.prop('tagName') == 'FORM' && $el.data('mea-autosave')!=1)){
                console.log('mea-autosave disabled');
                return ;
            }

            if($el.data('confirm')){
                $mea.messages.confirm($el.data('confirm'));
            }

            let url = this.getFormUrl(selector);
            url = $mea.ajax.urlAdd(url,'auto_save=1');


            if($mea.forms.test(selector,{}) ===true){

                $mea.forms.send(selector,{
                    url: url,
                    disableAfterSend: false,
                    parseSuccesResponse: true,
                });
            }

            // console.log('changed', {
            //     formValid: $mea.forms.test(selector,{}),
            //     el: e.currentTarget,
            //     event: e
            // });
        }

        $(selector)
            .on($mea.forms.events.form_elements_added,(e)=>{
                $mea.forms.onExitAskChangesUpdate(selector);

                console.log('form_elements_added mea-autosave enabled');

                // $(e.mea_field).find(this.formElements).change((e)=>{
                this.getProtectedFormInputs(e.mea_field).each((k,el)=>{
                    $(el).change((e)=>{
                        // console.log('form_elements_added onChangeFn');
                        onChangeFn(e);
                    })
                });
            })
            .on($mea.forms.events.form_before_unload_change_found,(e)=>{


                if($(selector).data('mea-autosave')!=1){
                    console.log('form_before_unload_change_found mea-autosave disabled');
                    return ;
                }
                console.log('form_before_unload_change_found mea-autosave enabled');

                $mea.forms.send(selector,{
                    disableAfterSend: false,
                    parseSuccesResponse: false,
                });
                e.stopPropagation();

                })
            .attr('data-mea-autosave',1);

        // console.log('getProtectedFormInputs',selector,this.getProtectedFormInputs(selector));
        // this.getProtectedFormInputs(selector).each((k,el)=>{
        //     // console.log('onChangeFn',el);
        //     $(el).change((e)=>{
        //          // console.log('onChangeFn');
        //         onChangeFn(e);
        //     })
        // });

        this.getProtectedFormInputs(selector).change((e)=>{
                // console.log('onChangeFn');
                onChangeFn(e);
            });

        //console.log('onChangeFn elements ',$(selector).find(this.formElements));

        $(selector).find('[type="submit"]').hide();
    },

    getDifference: function (a, b) {
        var i = 0;
        var j = 0;
        var result = "";

        while (j < b.length) {
            if (a[i] != b[j] || i == a.length)
                result += b[j];
            else
                i++;
            j++;
        }
        return result;
    },

    isChanged: function (selector) {

        if (typeof selector != 'string') {
            selector = '#' + $(selector).attr('id');
        }

        //$mea.log('$mea.form testing selector: '+selector);

        if (!$(selector).data('mea-reload-serialize')) {
            $mea.log('$mea.form isChanged testing error - but form don`t set initial data');
            return false;
        }

        let formData = this.getProtectedFormData(selector);
        let savedData =  $(selector).data('mea-reload-serialize');

        let difrence = (formData !== savedData);

        if (difrence){

            $mea.log('form ' + selector + ' diffrence is: ', {
                // unserializedData: this.unserialize(savedData),
                // unserializedForm: this.unserialize(formData ),
                savedData: savedData,
                formData: formData,

                // isDifrence: difrence,
                // getDifrence: $mea.forms.getDifference(formData, savedData),
                // formArray: $mea.forms.toArray($(selector).find($mea.forms.protectSelector))
            });

        }

        return difrence;

    },

     unserialize: function(data) {
        data = data.split('&');
        var response = {};
        for (var k in data){
            var newData = data[k].split('=');
            response[newData[0]] = newData[1];
        }
        return response;
    },

    onExitAskChangesUpdate: function (selector){
        let formData = this.getProtectedFormData(selector);
        $(selector).data('mea-reload-serialize', formData); // On load save form current state
        // console.log('mea-reload-serialize updated');
    },

    getProtectedFormInputs: function(selector){

        let elements=[] ;
        $(selector).find($mea.forms.protectSelector).each((l,el)=>{
            if($(el).closest('.sub-form').length===0){
                elements.push(el);
            }
        });
        // console.log('getProtectedFormInputs',elements);
        return $(elements);
    },

    /**
     * this.getProtectedFormData(selector)
     * @param selector
     * @returns {*}
     */
    getProtectedFormData: function(selector){

        let clone =  $(selector).clone();
        clone.find('.sub-form').remove();
        let elements =  clone.find($mea.forms.protectSelector);

        return elements.serialize();
    },
    /**
     *$mea.ajax.registerRelase('formReloadProtect', 'form:not(.mea-protect-disabled)',
     function(el, selector) {
                    $mea.forms.onExitAskChanges(el);
                }
     );
     * @param selector
     */
    onExitAskChanges: function (selector) {

        if (typeof $(selector) == 'undefined') {
            console.warn('onExitAskChanges selector undefined',selector)
            return '';
        }

        if ($(selector).hasClass('mea-protect-disabled')) {
            console.log('meaForms ignore mea-reload-protect has mea-protect-disabled');
            return;
        }

        $(selector).attr('mea-reload-protect', 1);

        let formData = this.getProtectedFormData(selector);


        $(selector).data('mea-reload-serialize', formData); // On load save form current state

        //console.log('onExitAskChanges set initials ',formData);
        // $mea.log('formProtect set data',mea.forms.toArray(
        //     $(selector).find($mea.forms.protectSelector)
        // ),formData);

        if (!$(selector).attr('id'))
            $(selector).attr('id', 'protectedForm_' + $(selector).attr('name'));

        let id = '#' + $(selector).attr('id');

        $(document).on("submit", "form", function (event) {
            // disable warning
            $(window).off('beforeunload');
        });

        $(window).bind('beforeunload', function (e) {

            console.log(e.isPropagationStopped(), e, 'forms capture');

            if ($(id).length === 0) {
                $(window).off('beforeunload');
                return;
            }

            if ($mea.forms.isChanged(selector)) {

                let e = jQuery.Event($mea.forms.events.form_before_unload_change_found);

                $(selector).trigger(e);

                if (e.isPropagationStopped()) {
                    console.log('form_before_unload_change_found PropagationStopped = continue');
                    return;
                }

                return $mea.forms.config.message_changes_found;

            } else {

                return;

            }
        });

    },



    /**
     * $mea.forms.initHpl("#moderatorSearchUser");
     * podłączneie podanego formlarza do obsługi przez hpl
     */
    initHpl: function (selector) {

        $(selector).submit(function (e) {

            e.preventDefault();

            $mea.forms.send(selector);

            return false;
        });

    },

    /**
     * Add class on input is changed
     * @param form
     * @param elements
     * @example $mea.forms.addChangeListener(
     '.ctrackerfile_datatable_external_filter',
     $mea.forms.formElements
     );
     */
    addChangeListener: function (form, elements) {

        $(form).find(elements).each(function () {

            var val = $(this).val();

            if (!val) val = 'v:null';

            $(this).attr('data-default-value', val);
        });

        $(form).find(elements).each(function () {

            $('#meaContent').on('change', this, function (e) {

                var taget = e.target;

                if (!$(taget).attr('data-default-value')) {

                    var val = $(this).val();

                    if (!val) val = 'v:null';

                    $(taget).attr('data-default-value', val);
                }

                var val = $(taget).val();

                if (!val) val = 'v:null';

                if ($(taget).attr('data-default-value') != val) {

                    if ($(taget).hasClass('select2-hidden-accessible')) {
                        $(taget).next('.select2').find('.select2-selection').addClass('meaInputChanged');
                    } else {
                        $(taget).addClass('meaInputChanged');
                    }


                } else {

                    if ($(taget).hasClass('select2-hidden-accessible')) {
                        $(taget).next('.select2').find('.select2-selection').removeClass('meaInputChanged');
                    } else {
                        $(taget).removeClass('meaInputChanged');
                    }

                }
            });

        });

    },

    /**
     * Brefore send and on click - check is valid
     */
    test: function(form, params){
        
        // console.log('testing form ',params);

        if(!params) params = {};

        if (typeof params.noValidate === 1
            || $(form).attr('mea-novalidate')==='novalidate'
            || parseInt($(form).attr('mea-novalidate'))===1
        ){

            console.log('noValidateFlag ignoring',{
                noValidateParam: params.noValidate,
                noValidateAttr: $(form).attr('novalidate'),
            });

            return true;

        }

        try{

            if (this.validate.validate(form) === false) {

                console.log('not valid , enabling for resubmit', $(form).validate().errorList);
                $('[type=submit]').removeAttr('disabled');
                $mea.ajax.enableElement(form);
                return false;

            }else{
                console.log('form '+$(form).data('controller')+' is valid ');
            }

        }catch(e){

            console.log('validating exception',e);
            return false;
        }

        return true;

    },

    /**
     * Bezposrednia akcja formularza z wysłaniem realnych nazw (jak post)
     * @param form
     * @param params
     * @param submitEvent
     */
    send: function (form, params, submitEvent) {

        // console.log('meaFormSend',params);

        let $form = $(form);

        if (!params) params = {};

        let event = $.Event(this.events.form_start_validation);
        $(form).trigger(event, {
            form: form
        });

        if (event.isDefaultPrevented()) {
            return false;
        }

        if(this.test(form, params, submitEvent) === false){
            return false;
        }

        if (this.disableAfterSend && params.disableAfterSend !== false ) {
            $mea.ajax.disableElement(form);
        }

        if ($form.attr('mea-reload-protect') === 1) {

            let formData =  this.getProtectedFormData($form);

            $form.data('mea-reload-serialize', formData);

        }

        if (!params.url || params.url === 'undefined') {
            params.url = this.getFormUrl($form);
        }



        /**
         * Updating CKEDITORS on submit baecose it update is fired after form send
         */
        if (typeof (CKEDITOR) != "undefined")
            $.each(CKEDITOR.instances,function (index, el) {

                // console.log(index, el);
                CKEDITOR.instances[index].updateElement();

            });

        event = $.Event($mea.forms.events.form_before_send);
        $(form).trigger(event, {
            form: form
        });
        if (event.isDefaultPrevented()) {
            console.log('form_before_send isDefaultPrevented returning');
            return false;
        }

        if (window.FormData !== undefined) {

            return $mea.forms.sendFileForm($form, params,submitEvent);

        } else {

            //var values = {};
            //chosen nie zapisuje sie poprawnie  - do usuniecia
            // $.each( $form.serializeArray(), function(i, field) {
            //     values[field.name] = field.value;
            // });
            //$mea.messages.add(params.url,values);

            return $mea.forms.sendFileForm($form, params, submitEvent);

        }

        return false;
    },

    /**
     * Wysyła formularz z plikami ajaxem - wymaga obsługi new FormData ie10+, ff7+
     * todo: rozwiązanie dla starszych przeglądarek http://hayageek.com/jquery-ajax-form-submit/
     * @param $form
     * @param params
     * @param submitEvent
     */
    sendFileForm: function ($form, params, submitEvent) {

        if (window.FormData === undefined) {
            $mea.messages.error('Please update your internet browser minimal version is ie 10+, ff 7+.');
            $mea.ajax.activateContent($form);
            return;
        }

        //console.log('sendFileForm',$form,params, submitEvent);

        //not work example shop basket
        // let clone = $form.clone();
        // clone.find('.sub-form').remove();

        let values;

         // values = new FormData(clone[0]);
         values = new FormData($form[0]);

        for (let pair of values.entries()) {
            let name = pair[0];
            let $input = $('input[name=\''+name+'\']');
            if( $input.closest('.sub-form').length > 0 ){
               if($input.data('allow-as-subform')!==1){
                   values.delete(pair[0]);
                   console.log('removed subform val ',pair[0] + " - " + pair[1]);
               }else{
                   console.log('allowed subform val ',pair[0] + " - " + pair[1]);
               }

            }else{
                // console.log('ok',pair[0] + " - " + pair[1]);
            }
        }


        if($form.closest('[role="dialog"]').length > 0){
            values.append('_form_dialog','1');
        }

        //Add button name like has sonata admin - clicked button name
        try{
            if(submitEvent.originalEvent.submitter.name){
                values.append(submitEvent.originalEvent.submitter.name,'');
                console.log('found submiter name adding ',submitEvent.originalEvent.submitter.name);
            }
        }catch (e) {
            //console.log('submitter',e);
        }


        $form.find('[type=submit]').attr('disabled','disabled');

        $mea.messages.indicator(1);
        $.ajax({
            url: $mea.tools.urlAdd(params.url, 'gettype=hpl'),
            type: 'POST',
            data: values,
            async: true,
            cache: false,
            mimeType: "multipart/form-data",
            contentType: false,
            processData: false,
            success: (returndata, textStatus, request)=> {

                $mea.messages.indicator(0);

                if(parseInt($form.attr('mea-reload-protect'))===1){
                    $mea.forms.onExitAskChangesUpdate($form);
                }

                //autosave ignore redirect, messages ..
                if(params.parseSuccesResponse === false){
                    console.log('autosave parseSuccesResponse');
                    return ;
                }

                $mea.ajax.scroolDisableForThisResponse = true;

                let parsedResponse = $mea.ajax.responseSuccesParse(returndata, textStatus, request);

                let e = jQuery.Event($mea.forms.events.response_success, {
                    reply: "returndata",
                    returnData: returndata,
                    hplResponse: parsedResponse
                });


                $mea.ajax.scroolDisableForThisResponse = false;

                setTimeout( ()=> {
                    $($form).trigger(e);
                    $mea.ajax.enableElement($form);
                    $form.find('[type=submit]').removeAttr('disabled');
                    console.log('form submit success  enabling');
                }, 500);

                $mea.messages.indicator(0);

            },
            error:  (returndata)=> {

                $mea.messages.indicator(0);

                $mea.ajax.scroolDisableForThisResponse = true;

                $mea.ajax.responseErrorParse(returndata);

                $mea.ajax.scroolDisableForThisResponse = false;

                $mea.ajax.enableElement($form);

            }
        });

    },

    getFormUrl: function(form){
        let url;
        if($(form).attr('data-action'))
            url = $(form).attr('data-action');
        else if($(form).attr('action'))
            url = $(form).attr('action');
        else url = location.href;
        return url;
    },

    submitLock($form){
        $form.find('[type=submit]').attr('disabled','disabled');
    },

    submitLockEnd($form){
        $form.find('[type=submit]').removeAttr('disabled','disabled');
    },

    /**
     * Restore form from previous stored on change data-restore=$mea.forms.formToArray('#docList_filter_funnel') then restore it
     * @param $form
     * @param data
     */
    restoreForm: function (form, data) {


        $(form)[0].reset();

        $.each(data, function (k, v) {


            //todo: other input types, multidimension array (maybe add ids od save ?)
            //checkbox/radio
            if (typeof v == 'object') {
                $.each(v, function (ok, ov) {
                    var sel = '[name="' + k + '[]"][value=' + ov + ']';
                    $(form).find(sel).attr('checked', true).change();
                });
            } else {
                var $el = $(form).find('[name="' + k + '"]');

                if ($.inArray($el.attr('type'), ['text', 'select']) != -1 || $el.is("textarea")) {


                    $el.val(v).change();

                } else if ($.inArray($el.attr('type'), ['radio', 'checkbox'])) {


                    $el.prop('checked', true).change();

                }
            }

        });

    },

    /**
     * wysyła formularz z przekształceniem danych
     * Na zwrocie jest tablica[id_formularza]=>dane
     * mozliwe przesyłane inne parametru poza formularzowe
     * lub kilka formularzy jednocześnie
     * form = selector
     *
     * params { method : 'get', ajax: true } //default post
     */
    submit: function (form, params) {

        console.log('submiting form ', form, params);

        //html5 form validate
        if (!$(form)[0].checkValidity()) {
            $tmpSubmit = $('<input type="submit" />');
            $form.append($tmpSubmit);
            $tmpSubmit.click().remove();
            alert('not valid');
            return;
        }

        if (!params) params = {};

        this.params = params;

        var formdata = {};

        var id = $(form).attr('id');


        if (!this.params.url) {

            //kiedys bylo action ale to standardowy tag więc lepiej podmienić, formualrz ma działać tylko przesłany przez js
            this.params.url = $(form).attr('action');

            if (!this.params.url) {

                id = 'formSend'; //id zawsze taki sam do domyślnego parsera
                this.params.url = '/formSend'; //domyślny parser formularzy kontaktowych
            }

        }

        formdata[id] = $mea.forms.data(form, this.params);

        if ($(form).hasClass('validate') && !$('#' + id).valid({debug: true})) {

            $mea.log('form not valid');

            return false;

        }


        if (this.params.method === "GET") {

            //dload($mea.tools.urlAdd(url,formdata.mainSearch));

            this.params.urlSend = $mea.tools.urlAdd(this.params.url, formdata[id]);

            if (params.ajax === false)
                window.location = this.params.urlSend;
            else
                window.location.hash = this.params.urlSend;


            //return ;
            //
            //if(params.locationchange==true){
            //
            //    dload(params.url);
            //
            //}else{
            //
            //    //TYMCZASOWE / Jeżeli iframe - wysyłamy formularz - bo adress nie widzi zmian w iframe
            //    if(window.location != window.parent.location)
            //        $mea.messages.add(params.url, formdata);
            //
            //}


            //console.log('GREKfORM hashRun',params.url,window.location.hash);

            // $mea.log('DLOAD params ',params,$mea.tools.urlAdd(params.url,formdata.mainSearch));

        } else {


            $mea.messages.add(this.params.url, formdata);
        }

    },


    /**
     * Zwraca aktywne pola formularza
     * jeżeli params.elementsRemoveEmpty tylko nie puste
     * @param {type} selector
     * @returns {unresolved}
     */
    activeElements: function (selector, params) {

        if (!params)
            params = {};

        this.ckupdate();

        //var formElements = $(selector).find('input[type=text][value!=""],input[type=password],input[type=hidden][value!=""],select[value!=""],input[type=radio]:checked,input[type=checkbox]:checked,textarea');
        var formElements = $(selector).find('input[name][value!=""][type!=radio],select[name][value!=""],input[name][type=radio]:checked,input[name][type=checkbox]:checked,textarea,input[type=file]');
        var noEmptyResults = [];

        if (params.elementsRemoveEmpty == true) {

            $(formElements).each(function () {
                if ($(this).val() != "")
                    noEmptyResults.push(this);
            });

            return noEmptyResults;
        }

        return formElements;


    },

    /**
     * Aktualizuje pola edytorów ck, wykonywane przez zapisem js formularza
     */
    ckupdate: function () {

        if (typeof (CKEDITOR) == "undefined") {

        } else {
            for (instanceEd in CKEDITOR.instances) {

                CKEDITOR.instances[instanceEd].updateElement();

                CKEDITOR.remove(CKEDITOR.instances[instanceEd]);

            }
        }

    },

    /**
     * Odczytuje zawartość formularza
     *
     *
     *
     */
    data: function (selector, params) {

        //formElements = $(formElements).map(function(){return $(this).val();}).get();

        //formElements = $(formElements).serializeObject();

        // $(selector).submit();

        // formElements = this.activeElements(selector, params);

        let formElements = $(formElements).serialize();

        //formElements = $(selector).serialize();


        //$mea.log('formSelector',selector,'formElements',formElements);


        //formElements = this.toArray(formElements);

        return formElements;

    },

    /**
     * get values from input array
     * @param elements selector or collections
     * @returns {*}
     */
    toArrayValues(elements){
        return $(elements).map(function(){return $(this).val();}).get();
    },

    /**
     * Zamienia formularz w tablice
     * $mea.forms.toArray()
     *
     * $mea.forms.toArray($('#massServiceForm').find('select,input,textarea'))
     *
     * @param selector
     */
    toArray: function (el) {

        //var array = new Array();
        var array = {};

        $(el).each(function (key, $input) {

            if ($(this).attr('type') === 'radio' || $(this).attr('type') === 'checkbox') {

                if ($(this).is(':checked')) {

                    if ($(this).attr('name')) {

                        if ($(this).attr('name').search('\\[\\]') !== -1) {

                            var iname = $(this).attr('name').replace('[]', '');

                            if (typeof array[iname] != 'object') {
                                array[iname] = [];
                            }
                            array[iname].push($(this).val());

                        } else {
                            array[$(this).attr('name')] = $(this).val();
                        }

                    }
                }


            } else if ($(this).val() && $(this).val() !== '' && $(this).attr('name'))
                array[$(this).attr('name').replace('[]', '')] = $(this).val();

        });

        return array;
    },

    // serializeToArraySelectors: function(el){
    //
    //     var array = {};
    //
    //     $(el).each(function(key, $input) {
    //
    //         if($(this).attr('type')=='radio' || $(this).attr('type')=='checkbox' ){
    //
    //             if($(this).is(':checked') ){
    //
    //                 if($(this).attr('name')) {
    //
    //                     if($(this).attr('name').search('\\[\\]') != -1){
    //
    //                         var iname = $(this).attr('name').replace('[]','');
    //
    //                         if(typeof array[iname] != 'object'){
    //                             array[iname] = [];
    //                         }
    //                         array[iname].push($(this).val());
    //
    //                     }else{
    //                         array[$(this).attr('name')] = $(this).val();
    //                     }
    //
    //                 }
    //             }
    //
    //
    //         }else if ($(this).val() && $(this).val() != '' && $(this).attr('name'))
    //             array[$(this).attr('name').replace('[]','')] = $(this).val();
    //
    //     });
    //
    //     return array;
    // },

    //
    /**
     *return json .... :)
     * Better use  $(dbname).find('select,input').serialize();
     * or JSON.parse($('[name*="meaFileData"]').serializeJSON())
     * and in PHP $extraSearch = array();
     * parse_str(html_entity_decode($requestData['extra_search']),$extraSearch);
     * this give assoc array
     * @param selector
     * @returns {*}
     */
    formToArray: function (selector) {
        return $mea.forms.toArray($(selector).find('select,input,textarea'))
    },

    /**
     * selector - form or collection input
     * @param selector
     */
    formToMultiDimensionJson: function(selector) {
        return $(selector).serializeJSON();
    },

    /**
     * selector - form or collection input
     * @param selector
     */
    formToMultiDimensionObject: function(selector) {
        return $(selector).serializeObject();
    },

    /**
     * Create assoc like json
     * @param selector
     */
    toJson: function (selector) {

        var data = {};

        function buildInputObject(arr, val) {
            if (arr.length < 1) {
                return val;
            }
            var objkey = arr[0];
            if (objkey.slice(-1) == "]") {
                objkey = objkey.slice(0, -1);
            }
            var result = {};
            if (arr.length == 1) {
                result[objkey] = val;
            } else {
                arr.shift();
                var nestedVal = buildInputObject(arr, val);
                result[objkey] = nestedVal;
            }
            return result;
        }

        function gatherMultipleValues(that) {
            var final_array = [];
            $.each(that.serializeArray(), function (key, field) {
                // Copy normal fields to final array without changes
                if (field.name.indexOf('[]') < 0) {
                    final_array.push(field);
                    return true; // That's it, jump to next iteration
                }

                // Remove "[]" from the field name
                var field_name = field.name.split('[]')[0];

                // Add the field value in its array of values
                var has_value = false;
                $.each(final_array, function (final_key, final_field) {
                    if (final_field.name === field_name) {
                        has_value = true;
                        final_array[final_key]['value'].push(field.value);
                    }
                });
                // If it doesn't exist yet, create the field's array of values
                if (!has_value) {
                    final_array.push({'name': field_name, 'value': [field.value]});
                }
            });
            return final_array;
        }

        // Manage fields allowing multiple values first (they contain "[]" in their name)
        var final_array = gatherMultipleValues($(selector + ' input ,' + selector + ' select'));

        // Then, create the object
        $.each(final_array, function () {
            var val = this.value;
            var c = this.name.split('[');
            var a = buildInputObject(c, val);
            $.extend(true, data, a);
        });
        return data;

    },

    toJsonString: function (selector) {

        return JSON.stringify(this.toArray(
            this.activeElements(selector)
        ));


    },

    ajaxchoice_populate: function (elid, valid, valtitle) {

        if ($('#' + elid).find('option[value=' + valid + ']').length > 0) {
            $('#' + elid).find('option[value=' + valid + ']').attr("selected", true);
        } else {
            $("#" + elid).append($('<option>', {
                value: valid,
                text: valtitle,
                selected: 'selected'
            }));
        }

        var form = $('#' + elid).closest('form');
        if (form.attr('mea-reload-protect') === 1) {
            form.data('mea-reload-serialize', this.getProtectedFormData(form));
        }

    },

    select2AjaxCascade: function (parent, paramName, children) {

        children = children.split(',');

        parent.on("change", function (e) {

            $.each(children, function (k, child) {

                child = $(child);

                var urlOld = child.attr('dyn_params');
                if (urlOld == undefined)
                    urlOld = '';

                var value = $(parent).val();

                if (urlOld == "") {
                    var urlAdd = $mea.ajax.urlAdd(urlOld, paramName + '=' + value + '&');
                } else {
                    var urlAdd = $mea.ajax.urlReplace(urlOld, paramName, value);
                }

                child.attr('dyn_params', urlAdd);
                child.val(null).trigger("change");

                //$mea.log('parent change for '+child.attr('id'),urlAdd);

            });

        });
        if ($(parent).val())
            $(parent).trigger("change");

    },

    /**
     * usage: $mea.forms.formToString('.sonata-filters-box form', '.advanced-filter');
     * @param selector
     * @param ignoreInSelector
     */
    formToString: (selector, ignoreInSelector = false) => {
        let stringOut = [];

        $(selector).find('select, input[type!="hidden"]').each((index, value) => {
            let $item = $(value);

            if (ignoreInSelector && $item.closest(ignoreInSelector).length > 0) {
                return true;
            }

            let $formGroup = $item.closest('.form-group');

            if ($formGroup.css('display') != 'none') {
                let labelString = $formGroup.find('label[for="' + $item.attr('id') + '"]').text();

                switch ($item.prop('tagName').toLowerCase()) {
                    case 'select':
                        let selectedString = [];
                        $item.find('option').filter(':selected').each((index, value) => {
                            if ($(value).text() != '') {
                                selectedString.push($(value).text());
                            }
                        });

                        if (selectedString.length > 0) {
                            let out = labelString + ': ' + selectedString.join(', ');
                            stringOut.push(out)
                        }
                        break;

                    case 'input':
                        if ($item.val() != '') {
                            stringOut.push(labelString + ': ' + $item.val());
                        }
                        break;
                }
            }
        });

        return stringOut.join('; ');
    }

};

$mea.forms.init();
