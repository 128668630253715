// var Mustache = require('mustache')
// window.Mustache = Mustache;

import Handlebars from 'handlebars/dist/cjs/handlebars'
window.Handlebars = Handlebars;

var InfiniteScroll = require('infinite-scroll');

$mea.types.add('log_list',($el)=>{

    if(typeof CKEDITOR !== 'undefined'){
        CKEDITOR.disableAutoInline = true;
    }

    mealog.infinity($el);

    // console.log('log_list',$el);

})

$mea.ajax.registerRelase('log_item','[data-mea-selector="log_item"]',($el)=>{

    // console.log('log_item',$el);

    let $container = $el.closest('.editableLogs');
    let $footer = $el.find('.log-box-footer');
    let $tools = $el.find('.log-box-tools');

    mealog.visibility.init($el,$tools );

    $footer.find('.btn.removeLog').click((e)=>{
        $mea.messages.confirm('Proszę potwierdzić',()=>{
            $mea.ajax.run(
                Routing.generate('MeaLogs_dispatch',{
                    action: 'removeMessage',
                    log: $el.data('id')
                })
            ).on('onFinish',()=>{
                $el.slideUp();
            });
        });
    });

    $footer.find('.responseCreate').click((e)=>{

        let $target =  $(e.currentTarget);
        let $itemContainer = $target.closest('.listItem');
        let id = $target.closest('.listItem').attr('data-id')

        let $responseContainer = $itemContainer.find('.responseContainerCreate').first();
        $responseContainer.toggle();

        $responseContainer.find('.responseForm').on('hinclude_load',()=>{
            // console.log('hinclude_load');
            // mealog.attachments.init(containerSelector);
            // mealog.editable.editor(containerSelector, '#listItem'+id,id, id);
            $itemContainer.find('.responseContainer').first().slideDown();
        });

    });

    $el.find('.log-box-footer .responseCreateParent').click((e)=>{

        let $target =  $(e.currentTarget);
        let $parentContainer = $target.closest('.responseContainer').closest('[data-mea-selector="log_item"]');

        let $responseContainer = $parentContainer.find('.responseContainerCreate').first();

        if($responseContainer.css('display')==='none'){
            $parentContainer.find('.responseCreate').first().trigger('click');
        }

        $mea.ui.scrollTo($parentContainer.find('.responseCreate').first());

        // console.log('responseCreateParent',{
        //     parentRes: $parentContainer.find('.responseCreate').first(),
        //     $parentContainer, $target, $itemContainer,
        //
        // });

    });

    $el.find('.log-box-footer .responsesOpen').click((e)=>{


        let $target =  $(e.currentTarget);
        let $itemContainer = $target.closest('.listItem');
        let $respContainer = $itemContainer.find('.responseContainer');
        // console.log('responsesOpen',{
        //     $target: $target,
        //     $itemContainer: $itemContainer,
        //     $respContainer: $respContainer,
        // });

        if(parseInt($target.attr('data-loaded'))===1){
            $respContainer.toggle();
            return ;
        }

        $target.attr('data-loaded',1);

        $respContainer.slideDown();

        let id = $target.closest('.listItem').attr('data-id')

        if(parseInt($target.attr('data-count'))>0){

            let url = $container.attr('data-answers-url');

            let data = mealog.editable.filtersData($container);

            url = $mea.ajax.urlAdd(url,'parent='+id);
            url = $mea.ajax.urlAdd(url,'urltarget='+id+'_responses');

            $mea.ajax.load(url,data,{}).on('onFinish',()=>{
                consoe.log('onFinish');
                mealog.infinity($respContainer);
            });

        }

    });

    mealog.attachments.init($el);


});

$mea.ajax.registerRelase('log_create','[data-mea-selector="log_create"]',($container)=>{

    let $parent;
    let parent_log;
    $parent = $container.closest('[data-mea-selector="log_item"]');
    if($parent.length!==1){
        $parent = $container.closest('[data-mea-type="log_list"]');
    }else{
        parent_log = $parent.data('id');
    }

    let idString = $container.data('id');
    let el =  $container.find('.logNewText');
    let id = $(el).attr('id');

    // console.log('log_create',{
    //     $container,
    //     parent_log,
    //     $parent,
    //     idString,
    //     el,
    //     id
    // });

    mealog.attachments.init($container);

    // $(el).attr('contenteditable','true');

    $(el).click(()=>{


        $(el).attr('data-controller','editor-quill');
        $(el).attr('data-editor-quill-theme-value','bubble');
        $(el).attr('data-editor-quill-media-upload-url-value',$container.attr('data-media-upload-url'));
        $(el).attr('data-editor-quill-save-url-value',$('#'+id).attr('data-editable-url'));

        window.addEventListener('editor-quill:content-changed', (event) => {

            // console.log('change editor ',$container.find('#logOptions').is(":visible"));
            mealog.protectUnload(id, null, event);
            $container.find('#logOptions').show();

        });


        // console.log('edit lister');
        // CKEDITOR.instances[id].on('change', ()=>{
        //
        //     let ckid = 'ckedit'+id;
        //     console.log('change ck ',$container.find('#logOptions').is(":visible"));
        //     mealog.protectUnload(id);
        //
        //     if(!$container.find('#logOptions').is(":visible")){
        //
        //
        //
        //         $container.find('#logOptions').show();
        //
        //         $('#'+ckid).click(function(){
        //             console.log('ck click');
        //             $mea.ajax.load(
        //                 $('#'+id).attr('data-editable-url'),{
        //                     'value': CKEDITOR.instances[id].getData()
        //                 },{
        //                     onFinish: function(){
        //
        //                         mealog.protectUnload(id,'unregister');
        //                         $('#'+id).remove();
        //                     }
        //                 }
        //             );
        //         });
        //     }
        // });




    });

    $container.find('a.submit').click(function(e){

        // console.log('click',{
        //     parent: parent,
        //     containerSelector: containerSelector,
        //     baseContainerSelector: baseContainerSelector,
        //     el: $container.find('a.submit'),
        //      container:   $container,
        // });
        // return ;

        // if(typeof CKEDITOR.instances['logNewText'+idString] === 'undefined'){
        //
        //     console.error('editorNotFound',{
        //         idString: idString,
        //         selector: 'logNewText'+idString
        //     });
        //     $mea.messages.error('Error occurred');
        //     return false;
        // }

        let data = {
            'parent': parent_log,
            // 'text': CKEDITOR.instances['logNewText'+idString].getData(),
            'text': $(el).data('quill').root.innerHTML,
            'contact': $container.find(' #newLogContact').attr('data-selected-value'),
            'attachments_new': $mea.forms.toArrayValues($container.find('input[name="attachments_new[]"]')),
            'notifyUsers': $container.find('[name="newLogNotificationSend"]').val(),
            'requireResponses': $container.find('[name="newLogRequireResponses"]').val(),
            'pauseUntilResponses': $container.find('[name="newLogPauseUntilResponses"]').val(),
            'tags': $container.find('#newLogTags').attr('data-selected-value'),
            'weight': $container.find('#newLogWeight').attr('data-selected-value'),
            'visibility': $container.find('[name="visibility"]').val(),
        };

        // if($container.find('select[name=newLogNotificationSend]').val()){
        //     data.notifyObservers = $(containerSelector+' select[name=newLogNotificationSend]').val();
        // }

        let url = $container.data('create-url');

        let options = {

            onSuccess: (data,textStatus, request)=>{

                // $container.find('a.submit').trigger('click');

                let out = $mea.ajax.responseSuccesParse(data,textStatus, request);

                if($parent.data('mea-type')==='log_list'){
                    $parent.find('.listContainer').prepend(
                        out.scripts.var.html
                    );

                }else{
                    $parent.find(' .responses').prepend(
                        out.scripts.var.html
                    );

                }

                $mea.ajax.activateContent($parent);

                // window.mealog.editable.responses.init(idString,baseContainerSelector);

                // console.log('activeate ',baseContainerSelector);

                // CKEDITOR.instances['logNewText'+idString].setData(null);
                $(el).data('quill').root.innerHTML = null;
                $container.find('#newLogContact').attr('data-selected-value',null);
                $container.find('#newLogNotification').attr('data-selected-value',null);
                $container.find('#newLogTags').attr('data-selected-value',null);
                $container.find('#newLogWeight').attr('data-selected-value',null);
                $container.find('input[name=newLogNotificationSend]').val(null);
                $container.find('.log_attachment_container').html(null);

            }

        };

        $mea.ajax.load(url, {log: data}, options);

        //hide response create after send
        $(e.currentTarget).closest('.listItem').find('.log-box-footer').first().find('.responseCreate').click();

    });

});

window.mealog = {

    locale: 'pl',

    protectUnload(id,action, event){

        let fn = ()=>{

            console.log('mealog on form_start_validation protectUnload',
                id,
                $('#'+id).closest('form'),
                event
            );

            if (typeof CKEDITOR !== 'undefined') {

                let content = CKEDITOR.instances[id].getData();

                $('#'+id).closest('form').find('[data-type="updateReason"]').attr('type','textarea').val(content);

            }else if(event.detail.elementId){

                let content = $('#'+event.detail.elementId).data('quill').root.innerHTML;
                $('#'+id).closest('form').find('[data-type="updateReason"]').attr('type','textarea').val(content);
            }

            // $('#'+id).append('<input type="text" data-allow-as-subform="1" name="meaCommentRestored" value="'+content+'">');



        };

        if(action === 'unregister'){

            $('#'+id).closest('form').off('submit',fn);

            return ;
        }

        // CKEDITOR.instances['logNewText'+idString].getData()
        //

        // console.log('LogConnectToForm',{
        //     'id': id,
        //     'form': $(id).closest('form'),
        // });
        //

        $('#'+id).closest('form').on($mea.forms.events.form_start_validation,fn);

        // window.onbeforeunload = function() {
        //     // console.log('onbeforeunload');
        //     $('#'+id).after('<input name="comment" value="comment">');
        //     // return false;
        // }
        //
        // let unloadProtect = function(e) {
        //     console.log('unloadProtect');
        //     $('#'+id).after('<input name="comment" value="comment">');
        //     return false;
        // };

        // $(window).on("unload", unloadProtect );

    },

    infinity: function($container){


        let $moreBtn = $container.find('.moreBtn');
        let containerSelector = '#'+$container.attr('id');

        let $nextBtn;
        let checkLastPageSelector = '.pagerfanta .next a';
        $nextBtn = $container.find(checkLastPageSelector);

        checkLastPageSelector = '.pagerfanta .next a';
        if($nextBtn.length===0){
            $nextBtn = $container.find(checkLastPageSelector);
        }

        if($nextBtn.length === 0){

            // console.log("infinity Log disable ininity more empty",{
            //     checkLastPageSelector,
            //     containerSelector,
            //     $moreBtn
            // });
            $moreBtn.hide();
            return ;
        }


        if($container.find('.moreBtn').length ===0){
            let btnTemplate = $container.closest('[data-mea-type="log_list"]')
                .find('.moreBtn').first().clone(true).off().show();
            $container.append(btnTemplate);
        }

        // console.log("infinity Log ininity start",{
        //     list: $container.find('.listContainer'),
        //     listlength: $container.find('.listContainer').length,
        //     buttonMore: $(containerSelector+' .moreBtn'),
        //     next: containerSelector+' .pagerfanta .next a',
        //     containerSelector,
        //     $moreBtn
        // });

        let options = {
            // path: $(containerSelector).data('url'),
            path: function(a,b){

                let url;

                if($moreBtn.data('next-url')){
                    url = $moreBtn.data('next-url');
                     console.log('next-url',url);
                }else{
                    url = $container.find(checkLastPageSelector).attr('href');
                     console.log('next-url checkLastPageSelector',url);
                }

                let data = mealog.editable.filtersData($container);
                data.search = 2;
                url = $mea.ajax.urlAdd(url, data);

                url = $mea.ajax.urlRemove(url, 'gettype','hpl');
                url = $mea.ajax.urlRemove(url, 'hpl',1);

                // console.log('infiniteScroll generate url',   url);

                return url;
            },
            // path: containerSelector+' .pagerfanta li.next a',
            append: '[data-mea-selector="log_item"]',
            history: false,
            checkLastPage: checkLastPageSelector,
            button: containerSelector+' .moreBtn',
            scrollThreshold: false,
            loading: {
                finished: function(){
                    console.log('infiniteScroll finished');
                    // $mea.ajax.activateContent(containerSelector+' .listContainer');
                },
                finishedMsg: "<em>These are all.</em>",
//                        img: null,
//                        msg: null,
                msgText: $mea.ajax.template_loading,
                // msgText: "<em>Loading...</em>",
//                        selector: null,
//                        speed: 'fast',
//                        start: undefined
            },
            // state: {
            //     isPaused: true
            // },
            // binder: $(window), // used to cache the selector for the element that will be scrolling
            nextSelector: containerSelector+' '+checkLastPageSelector,
            navSelector: containerSelector+" .pagerfanta",

        };


        console.log('log infinity options',options);

        let infScroll = new InfiniteScroll(containerSelector+' .listContainer', options)
            .on('request.infiniteScroll', function( event, response ) {
                 console.log('infiniteScroll request',response);
                $mea.messages.indicator(1);
            });

            $(containerSelector+' .listContainer')
            .on( 'append.infiniteScroll', function( event, response ) {

                // get posts from response
                // var $posts = $( response ).find('.post');
                // // append posts after images loaded
                // $posts.imagesLoaded( function() {
                //     $container.infiniteScroll( 'appendItems', $posts );
                // });

                $mea.messages.indicator(0);

                $mea.ajax.activateContent(containerSelector+' .listContainer');

                // console.log('infiniteScroll append response', {
                //     activate: containerSelector+' .listContainer',
                // });

                //
                if($(response).find(checkLastPageSelector).length > 0 ){

                    $(containerSelector+' .moreBtn').data('next-url',
                        $(response).find(checkLastPageSelector).attr('href'));


                }

        });


    },

    editable: {
        init: function(){

        },

        filtersData: function($container){

            let data = {};

            data.search = 1;

            $container.find('.filter').each(function(){
                if($(this).val())
                    data[$(this).data('type')] = $(this).val();
            });

            return data;

        },
    },

    visibility: {

        init: function($actions,$tools){

            $actions.find('.btn.visibility').click((e)=>{
                $tools.find('[data-mea-selector="visibility_conatainer"]').toggle();
            });

        }
    },

    attachments: {

        init: function($container){
            let $addAttachBtn = $container.find('.log-box-footer [data-mea-selector="log_add_attachment"]');
            $addAttachBtn.click((e)=>{

                let $attContainer;
                let $logItemContainer;
                let $btn = $(e.currentTarget);

                $logItemContainer = $btn.closest('[data-mea-selector="log_item"]');
                if($logItemContainer.length === 1){
                    $attContainer = $logItemContainer.find('.log_attachment_container').first();
                }else{
                    $logItemContainer = $btn.closest('[data-mea-selector="log_create"]');
                    $attContainer = $logItemContainer.find('.log_attachment_container').first();
                }


                //create

                let id_upload_container = $btn.data('target');

                // console.log('log_add_attachment click',{
                //     $btn,
                //     $attContainer,
                //     $logItemContainer,
                //     id_upload_container
                // });

                if($('#'+id_upload_container).html().length > 0){
                    $('#'+id_upload_container).html(null);
                    return ;
                }

                let url = $mea.ajax.urlAdd($btn.data('url'),'urltarget='+id_upload_container);


                $mea.ajax.load(url,null,{
                    onFinish: ()=>{

                        $( document ).ready(function() {

                            let $uploadInput = $('#'+id_upload_container).find('[data-mea-type=upl-file-dnd]');

                            $uploadInput.on('mea_fileuploaded',(e, json)=>{

                                let file;
                                $.each(json.data,(k,item)=>{

                                    file = item;
                                    let template = Handlebars.compile($('#attachment_file_tpl')[0].innerHTML);
                                    let output = template(item);;
                                    // let output = Mustache.render($('#attachment_file_tpl')[0].innerHTML, item);
                                    $attContainer.find('[data-mea-selector="log_list_attachment"]').append(
                                        output
                                    );
                                    $('#'+json.index).slideUp();

                                    // console.log('mea_fileuploaded',{
                                    //     json: json,
                                    //     $attContainer: $attContainer,
                                    //     log_list_attachment: $attContainer.find('[data-mea-selector="log_list_attachment"]'),
                                    //
                                    // });

                                });

                                setTimeout(()=>{

                                    if($uploadInput.fileinput('getFileStack').length ===0){
                                        $addAttachBtn.click();
                                    }

                                    // console.log('attach autoAdd',{
                                    //     id: $logItemContainer.data('id'),
                                    //     $logItemContainer,
                                    // });

                                    if($logItemContainer.data('mea-selector') === 'log_item'){
                                        $mea.ajax.run(
                                            Routing.generate('MeaLogs_dispatch',{
                                                action: 'addAttachment',
                                                log: $logItemContainer.data('id'),
                                                file: file.id,
                                            })
                                        );
                                    }

                                }, 1000);



                            });

                        });

                    }
                });

            });
        },

        remove: async function(el){

            await $mea.ajax.run(
                Routing.generate('MeaLogs_dispatch',{
                    action: 'removeAttachment',
                    log: $(el).closest('[data-mea-selector="log_item"]').data('id'),
                    file: $(el).closest('.attachment').data('id'),
                })
            ).on('onFinish',()=>{
                console.log('111 ');
                $(el).closest('.attachment').slideUp();
            });
        },

    },
}
