//select must be provided by app in any way
// require('select2');
var postbox = {

    debug: false,
    //
    // trigger:  function(name,parameters){
    //     $( postbox).trigger( name, parameters);
    // },

    events:{
        managerAdd:'managerAdd',
        managerRemove:'managersRemove',
        privateSet:'setPrivate',
        statusChanged:'statusChanged',
        starAdd:'statusChanged',
        starRemove:'statusChanged',
        threadRemove:'statusChanged',
        messageRemove:'messageRemove',
        messageCreated:'messageCreated',
        messageUpdated:'messageUpdated',
        messageReplyCreated:'messageReplyCreated',
    },

    init: function(){

        if(postbox.debug)
            console.log('postbox init');

        $( postbox ).on( postbox.events.messageCreated , function(e,params) {
            $mea.dialog.remove('postBoxNewMessage');
        });

        $mea.types.add('postbox-message-new',function(){
            if(postbox.debug)
                console.log('postbox-message-new init');
            postbox.new.init();

        });

        $mea.types.add('postbox-thread-one',function(){
            if(postbox.debug)
                console.log('postbox-thread-one init');
            postbox.one.init($(this).data('id'));
        });

        $mea.types.add('postbox-thread-list',function(){
            if(postbox.debug)
                console.log('postbox-thread-list init');
            postbox.list.init();
        });

        //create container
        $mea.ajax.onChangeAddress = function(hash){
          if(
              hash.search('/meapostbox/reader/') != -1 || hash.search('/meapostbox/reader/list,') != -1 ){
              postbox.container();
          }
        };
    },

    deleted : function(id){
        $("[data-id="+id+"]").closest("tr").remove();
        $("#threadfull_"+id).remove();
        $("#messagesList").show();
    },

    list: {

        //postbox.list.selectTask
        selectTask: function(el,threadId){

            $('.ui-dialog-content').dialog('close');

            var route = Routing.generate('postbox_reader_action',{
                name: 'task',
                value: $(el).attr('data-id') ,
                id: threadId
            });
            $.ajax(route ).done(function(data  ) {
                $mea.messages.add('przypisano do zadania '+data);
            });
            $mea.ajax.load(window.location.hash.replace('#',''));

        },

        load: function(){

            $("#messagesList").show();
            $("#oneMessage").hide();

            return ;
            // postbox.container();
            //
            // if(window.location.hash=="")
            //     $mea.ajax.locationChange(
            //         Routing.generate('postbox_reader_list')
            //     );
            //
            // //$mea.ajax.load(Routing.generate('postbox_reader_view_content',{
            // //    id: id
            // //}));
            //
            // return false;

        },

        init: function(){

            if(postbox.debug)
                console.log('postbox list init');

            $('.thread-one-link').click(function(){
                postbox.one.load($(this).data('id'));
                return false;
            });
            $("#messagesList").attr('data-url',Routing.generate('postbox_reader_list'));

            $('.btn-prv-toogle').change(function(){

                var url =  Routing.generate('postbox_reader_action', {
                    name: 'private',
                    id: $(this).attr('data-id'),
                    value: $(this).prop('checked')
                });

                $mea.ajax.run(
                    url
                );

            });
        }

    },

    //One Thread
    one : {

        init: function(id) {

            if(postbox.debug)
                console.log('postbox one init');

            $( postbox ).on( postbox.events.messageRemove , function(e,params) {
                $('#threadMessage'+params.id).slideUp();
            });

            $('#messagesList').hide();

            $('#oneMessage').show();

            $('.threadfull .messReplyTo').click(function(){

                var id = $(this).data('message-id');
                if(!id){
                    $mea.messages.error('Reply To Empty');
                    return ;
                }

                $mea.ajax.load('/mea/postbox/postboxmessage/create',{
                    urltarget: 'dialog',
                    extra: {
                        replyto: id
                    }
                    }
                );

                // $mea.ajax.load(
                //     Routing.generate('mea_postbox_manager_create',{
                //         // urltarget: 'threadMessageReply',
                //         urltarget: 'dialog',
                //         generator: 'mea_post_box.gmail_post_box_generator',
                //         extra: {
                //             replyto: id
                //         }
                //     })
                // );

                $('#threadMessageReply'+id).closest('.replyContainer').slideDown();

            });

            $('.threadfull input.threadPrv').change(function(){
                $mea.ajax.run(Routing.generate('postbox_reader_action',{
                    id: id,
                    name: 'private',
                    value: $(this).prop("checked")
                }));
            });

            $('.threadfull .panel-default').find('.collapseMessages').click(function(){

                console.log('slide');
                $(this).closest('.threadfull').find('.thread-messages-list .panel-body').slideToggle();

                if($(this).find('.fa').hasClass('fa-minus')){
                    $(this).find('.fa').addClass('fa-plus').removeClass('fa-minus');
                }else{
                    $(this).find('.fa').addClass('fa-minus').removeClass('fa-plus');
                }

            });

            $('.oneClose').click(function(el){
                postbox.one.close(el);
            });

            $('#threadfull_'+id+' .messMore').each(function(){
                $(this).click(function(){
                    $(this).after('<iframe class="messMoreFrame" src="'+$(this).attr('data-src')+'"></iframe>');
                    $(this).remove();
                });
            });

        },

        unselectPack: function(id){

            var value = $('#PostBoxMessage_packId').val().split();
            var newValue = [];
            $(value).each(function(k,v){
                if(id!=v) newValue.push(v);
            });

            $('#PostBoxMessage_packId').val(newValue.join(','));

            $('#'+'PostBoxGroupInfo'+id).slideUp(300, function() {
                $(this).remove();
            });

        },

        selectPack:function(el){


            $('.pb_to_group').removeClass('col-md-4').addClass('col-md-12');

            var id = 'PostBoxGroupInfo'+$(el).attr('data-id');

            if($('#'+id).length>0){

                $mea.messages.error("Ta grupa jest już dodana");
                return;
            }

            $('#PostBoxGroupInfo').append('<div id="'+id+'"><div id="'+id+'_content"></div></div>');

            $mea.ajax.load(Routing.generate('mea_pack_basket_info',{
                'urltarget': id+'_content',
                id: $(el).attr('data-id')
            }));

            var remove = '<A class="unselectPack btn btn-danger" onClick="postbox.one.unselectPack('+$(el).attr('data-id')+');"><i class="fa fa-trash-o"></i></a>';

            $('#'+id).append(remove).addClass('alert alert-success');

            $mea.log(id,remove);

            // $('.pb_to_group').append();

            $('#PostBoxMessage_packId').val(
                $('#PostBoxMessage_packId').val()+','+$(el).attr('data-id')
            );

            $('#mea_pack_basket_selectList').dialog('close');

        },

        close: function(el){

            var id = $(el).closest('.threadfull').attr('data-id');

            $('#messagesList').show();
            $('#oneMessage').html(null);

            if($('#messagesList').attr('data-url')==''){
                $mea.ajax.locationChange(
                    Routing.generate('postbox_reader_list',{
                        type: 'active'
                    })
                );
            }


            return ;

            // $('#threadfull_'+id).remove(); $(window).trigger('resize');
            //
            // if($('#messagesList').html()==""){
            //     $mea.ajax.load(
            //         Routing.generate('postbox_reader_list')
            //     );
            // }


        },

        load: function(id){

            postbox.container();

            $mea.ajax.load(Routing.generate('postbox_reader_view',{
                id: id
            }));

            return false;

        }


    },

    sonataForm: {

        routeValidateRecipients: null,

        init: function(routeValidateRecipients){
            if(postbox.debug)
                console.log('postbox sonata form init');

            this.routeValidateRecipients = routeValidateRecipients;
            this.getInput('sendAs').change();
            this.getMeaInput('sendToContacts').change();
        },

        getMeaInput: function(name){
            return this.getForm().find('[data-mea-name='+name+']');
        },

        getInput: function(name){
            return $('#'+this.getFormName()+'_'+name);
        },

        getForm: function(){
            return $('.postbox-template-switch').closest('form');
        },

        getFormInputContainer: function(name){
            return this.getInput(name).closest('.form-group');
        },

        getFormInputContainerByData: function(data,name){
            return this.getForm().find('[data-'+data+'='+name+']').closest('.form-group');
        },

        getFormName: function(){

            // let form = $('.postbox-template-switch').closest('form');

            //uniqid s5b49a39a66
            return  $('.postbox-template-switch').closest('form').data('uniq');
            // return  formName = $('.postbox-template-switch').attr('id').split('_')[0];

        },

        changeRecipients: function(el){

            if(!$(el).val()) return;

            $mea.ajax.load(this.routeValidateRecipients,{
                'type': this.getInput('sendAs').val(),
                'recipients': $(el).val(),
            });

        },

        changeMessageType: function(el){

            this.getMeaInput('sendToContacts').change();

            switch ($(el).val()) {

                case 'sms':
                    this.getForm().find('[data-mea-group=mailSettings]').closest('.form-group').slideUp();
                    this.getFormInputContainerByData('mea-group','sms').closest('.form-group').show();
                    this.getFormInputContainerByData('mea-group','mailEmail').closest('.form-group').hide();
                    break;


                case 'mail':
                    this.getForm().find('[data-mea-group=mailSettings]').closest('.form-group').slideDown();
                    this.getFormInputContainerByData('mea-group','mailEmail').closest('.form-group').show();
                    break;

                case 'email':
                    this.getForm().find('[data-mea-group=mailSettings]').closest('.form-group').slideUp();
                    this.getFormInputContainerByData('mea-group','mailEmail').closest('.form-group').show();
                    break;

            }



        },

        changeBodyTemplate: function(el){

            if(postbox.debug)
                console.log('changeBody Template Sonata');

            let id = $(el).val();

            if(!id) return ;

            let route = Routing.generate("mea_postbox_manager_loadBodyTemplate",{
                id: id,
                query_params: $mea.ajax.QueryStringToArray($('[data-mea-form="postboxmessage"]').attr('action'))
            } );


            $.ajax({
                url: route
            }).done(function(data) {
                 postbox.new.insertTemplate(
                     false,
                     data,
                     null,
                     postbox.sonataForm.getFormName()
                 );

            }).error(( xhr, textStatus, errorThrown)=>{

                try{

                    let err = eval("(" + xhr.responseText + ")");

                    if(err.e.message)
                        $mea.messages.error('Template '+err.id+' loading error '+err.e.message);
                    else
                        $mea.messages.error('Template loading error');

                    console.log(err);


                }catch (e) {

                    $mea.messages.error('Template loading error '+e.message );

                }

            });

        },

        changeTemplate: function(el){

            if(postbox.debug)
                console.log('change content Template Sonata');

            if(!$(el).val()) return ;

            let form = $('.postbox-template-switch').closest('form');

            //uniqid s5b49a39a66

            // let formName = $('.postbox-template-switch').attr('id').split('_')[0];
            let formName = $('.postbox-template-switch').closest('form').data('uniq');

            let generatorName = $(form).find('[data-mea-type=generatorName]').val();

            if(!generatorName){
                console.log('mea err no get name');
                return ;
            }

            let id = $(form).find("[data-mea-type=subformId]").val();

            // if(!id){
            //     console.log('no id in [data-mea-type=subformId]');
            //     return ;
            // }

            let params = {
                generator: generatorName,
                template: encodeURIComponent($(el).val()),
                id: id,
                formName: formName,
                query_params: $mea.ajax.QueryStringToArray($('[data-mea-form="postboxmessage"]').attr('action')),
                sendAs: $("#PostBoxMessage_sendAs").val()
            };


            let route = Routing.generate("mea_postbox_manager-loadTemplate",params );
            $mea.ajax.load(route,null,{
                onFinish: function(data,parsed){
                    if(parsed.data && parsed.data.status==='error'){
                        $mea.messages.error(parsed.data.e.message);
                    }
                    $mea.messages.indicator(0);
                    //console.log('onFinish',parsed);
                }
            });

        }

    },

    new: {

        loadContentCounter: 0,
        loadContentBody: '',

        init : function(){

            if(postbox.debug)
                console.log('postbox new init');

            $('#PostBoxMessage_subform_template').select2();

            $('form[name=PostBoxMessage]').find('[type=submit]').click(function(){


                var valid = true;

                if(!$('#PostBoxMessage_title').val() && $('#PostBoxMessage_sendAs').val()==='email'){
                    $mea.messages.error($mea.translator.trans("message.title.empty",{default: 'No title of the message'}));
                    $('#PostBoxMessage_title').closest('.form-group').effect("highlight", {}, 3000);
                    valid = false;
                }

                if(!$('#PostBoxMessage_content').val() && !CKEDITOR.instances["PostBoxMessage_content"].getData()){
                    $mea.messages.error("Wiadomość nie zawiera żadnej treści");
                    $('#PostBoxMessage_content').closest('.form-group').effect("highlight", {}, 3000);
                    valid = false;
                }

                if(!$('#PostBoxMessage_sendFromContact').val()){
                    $mea.messages.error("Uzupełnij nadawcę");
                    $('#PostBoxMessage_sendFromContact').closest('.form-group').effect("highlight", {}, 3000);
                    valid = false;
                }


                if(
                    !$('#PostBoxMessage_sendToEmail').val() &&
                    !$('#PostBoxMessage_sendToContact').val() &&
                    !$('#PostBoxMessage_packId').val() &&
                    !$('#PostBoxMessage_sendToContacts').val()
                ){
                    $('#PostBoxMessage_sendToContacts').closest('.form-group').effect("highlight", {}, 3000);
                    $mea.messages.error("Brak odbiorcy w części do wpisz email lub wybierz kontakt lub kontakty");
                    valid = false;
                }

                if(valid){
                    $('#validate').val('1');
                }


            });

            //sendFromContact

            $('#PostBoxMessage_sendFromContact').on("change", function (evt) {

                if($("#PostBoxMessage_signature").val()==null)
                    postbox.new.loadSingnature($('#PostBoxMessage_sendFromContact').val());

            });


            $(".signatureSelect").on("change", function (evt) {
                postbox.new.loadSingnature(evt.delegateTarget.value);
            });


            $("#PostBoxMessage_sendAs").change(function(){

                function visibility(withEl,what){

                    if(postbox.debug) $mea.log('postBox:visibility',withEl,what);
                    $(withEl.split(",")).each(function(id,el){
                        if(what=="show")$("#PostBoxMessage_"+el).closest(".form-group").show();
                        else $("#PostBoxMessage_"+el).closest(".form-group").hide();

                    });

                }

                if($(this).val()=="mail"){

                    visibility("subform,mailConfig,smsNotification,content","show");
                    visibility("title,sendToEmail","hide");

                }else if($(this).val()=="sms"){

                    visibility("smsNotification","show");
                    visibility("subform,attachments,content,title","hide");

                }else if($(this).val()=="email"){

                    visibility("subform,attachments,title,content,mailConfig,smsNotification,sendToEmail","show");
                    visibility("mailConfig","hide");

                }

            }).change();

            $("#PostBoxMessage_smsNotification").keypress(function() {


                var counter = $("#PostBoxMessage_smsNotification").val().length;
                var smsL = 1;
                if(counter > 70)
                    smsL = counter/70;

                $("#smsCounter").html( "sms: "+smsL+" znaków: "+counter+" " );

            });





        },

        loadSingnature: function (id){

            console.log('sign load',id);

            $.ajax({
                url: Routing.generate("mea_postbox_manager_loadSignature",{id: id}),
                cache: true
            })
                .done(function( content ) {
                    postbox.new.insertSignature(content);
                });

        },

        insertSignature: function(content){

            let formName = postbox.sonataForm.getFormName();
            // let ckId = formName+"_content";
            let ckId = formName+"_content";
            if($('#'+formName+'_content_ckeditor').length===1){
                ckId = formName+"_content_ckeditor";
            }

            if(typeof CKEDITOR )
            if(CKEDITOR.instances[ckId] && CKEDITOR.instances[ckId].status !=="ready"){

                CKEDITOR.instances[ckId].on( 'loaded', function( evt ) {
                    postbox.new.insertSignature(content);
                } );

                return ;
            }
            if(postbox.debug) $mea.log('signature loaded ');
            let data = CKEDITOR.instances[ckId].getData();

            if(data.search("<!--SIGNATURE-->")!== -1){

                if(postbox.debug) $mea.log('found signature pleace');

                data = data.replace(/\<\!\-\-SIGNATURE\-\-\>[\s\S]*?\<\!\-\-ENDSIGNATURE\-\-\>/mg, "<!--SIGNATURE-->"+content+"<!--ENDSIGNATURE-->");

            }else{
                data += content;
                if(postbox.debug) $mea.log('not found signature pleace');
            }
            CKEDITOR.instances[ckId].setData(data);

        },

        insertTemplate: function(title, content, sms, formName){

            if(!formName) formName = 'PostBoxMessage';

            if(title && !$("#"+formName+"_title").attr('readonly')){

                var elem = document.createElement('textarea');
                elem.innerHTML = title;
                var decoded = elem.value;

                $("#"+formName+"_title").val(decoded);

            }

            let editorActive = formName+"_content";
            if($('#'+formName+'_content_ckeditor').length===1){
                editorActive = formName+"_content_ckeditor";
            }

            if(!CKEDITOR.instances[editorActive]){

                if(postbox.debug) $mea.log('PostBox:insertTemplate: ckeditor not exits '+editorActive);
                setTimeout(function(){ postbox.new.insertTemplate(title, content,sms, formName); }, 3000);
                return ;

            }
            if(CKEDITOR.instances[editorActive].status !="ready"){

                if(postbox.debug) $mea.log('PostBox:insertTemplate: ckeditor not ready');
                setTimeout(function(){ postbox.new.insertTemplate(title, content,sms, formName); }, 3000);
                return ;

            }
            if(postbox.debug) $mea.log('PostBox:insertTemplate: to form '+editorActive+" "+' load content',content);

            CKEDITOR.instances[editorActive].setData(content);

            if(content.search("<!--SIGNATURE-->") !== -1 && $(".signatureSelect").val()){
                $(".signatureSelect").change();
            }

            if(sms){
                $('#PostBoxMessage_smsNotification').val(sms);
            }

            $mea.messages.indicator(0);
        },

        appendTemplate: function(title, content, sms, formName){

            try{
                // if(postbox.debug)
                console.log('appendTemplate',{
                    title: title,
                    content: content,
                    sms: sms,
                    formName: formName
                });

                if(!formName) formName = 'PostBoxMessage';

                if(title && !$("#"+formName+"_title").attr('readonly')){

                    let elem = document.createElement('textarea');
                    elem.innerHTML = title;
                    let decoded = elem.value;

                    $("#"+formName+"_title").val(decoded);

                }

                //if meaHtmlBuilder enabled
                let editorActive = formName+"_content";
                if($('#'+formName+'_content_ckeditor').length===1){
                    editorActive = formName+"_content_ckeditor";
                }

                if(!CKEDITOR.instances[editorActive]){
                    if(postbox.debug) $mea.log('PostBox:insertTemplate: ckeditor not exits '+editorActive);
                    setTimeout(function(){ postbox.new.appendTemplate(title, content,sms, formName); }, 3000);
                    return ;
                }
                if(CKEDITOR.instances[editorActive].status !=="ready"){
                    if(postbox.debug) $mea.log('PostBox:insertTemplate: ckeditor not ready');
                    setTimeout(function(){ postbox.new.appendTemplate(title, content,sms, formName); }, 3000);
                    return ;
                }
                if(postbox.debug)
                    $mea.log('PostBox:appendTemplate: load content',{
                        focus: $(document.activeElement),
                        content: content,
                    });

                // let formName = postbox.sonataForm.getFormName();
                //
                // let ckId = formName+"_content_ckeditor";

                document.getElementById(editorActive).focus();

                if(CKEDITOR.instances[editorActive].getSelection().getNative().anchorOffset > 0 ){

                    console.log('Content is focused - inset where you want');
                    CKEDITOR.instances[editorActive].insertHtml(content);

                }else{
                    console.log('Content is not focused - inserting to template content');

                    if(!CKEDITOR.instances[editorActive]){
                        console.log('ckeditor not found',{
                            instances: CKEDITOR.instances,
                            looking: editorActive
                        });
                        return ;
                    }

                    let data = CKEDITOR.instances[editorActive].getData();

                    if(data.search("<!--CONTENT-->")!== -1){

                        if(postbox.debug) $mea.log('found content place adding',content);

                        data = data.replace(/\<\!\-\-CONTENT\-\-\>/mg, "<!--CONTENT--> "+content);

                    }else{

                        data += content;

                    }

                    CKEDITOR.instances[editorActive].setData(data);

                }


                if(content.search("<!--SIGNATURE-->") !== -1 && $(".signatureSelect").val()){
                    $(".signatureSelect").change();
                }

                if(sms){
                    $('#PostBoxMessage_smsNotification').val(sms);
                }


                $('.postbox-template-switch').val(null).trigger('change.select2');
                $mea.messages.snackbar();

            }catch (e) {
                console.log('appendTemplate Error',{
                    e: e,
                    title: title,
                    content: content,
                    sms: sms,
                    formName: formName
                });
            }

            $mea.messages.indicator(0);

        },

        loadContent: function(url,formName){

            if(!formName) formName = 'PostBoxMessage';

            $mea.messages.indicator(1);
            $.ajax({
                url: url
            }).done(function(response) {
                $mea.messages.indicator(0);
                CKEDITOR.instances[formName+"_content"].insertHtml(response);
            });
        },

        loadPackGroupAction: function(el){

            var ids = [];

            $('.postboxPacks input[type=checkbox]:checked').closest('.packRow').find('input[type=checkbox]').each(function(){
                ids.push($(this).attr('data-rel-id'));
            });

            $mea.messages.indicator(1);

            $.ajax({
                url: $(el).attr('data-url'),
                data: {ids: ids},
            }).done(function(r) {

                CKEDITOR.instances["PostBoxMessage_content"].insertHtml(r);

                $mea.messages.indicator(0);

            });;



            $mea.log(ids,el);
        },

        loadContentChecked: function(){

            postbox.new.loadContentCounter = $('.postboxPacks input[type=checkbox]:checked').closest('.packRow').find('.PostBoxLoadContentBtn').length;
            $mea.messages.indicator(1);

            postbox.new.loadContentBody = [];

            $('.postboxPacks input[type=checkbox]:checked').closest('.packRow').find('.PostBoxLoadContentBtn').each(function(){


                var id = $(this).attr('data-id');

                postbox.new.loadContentBody[id]='';


                $.ajax({
                    url: $(this).attr('data-url'),
                }).done(function(r) {

                    postbox.new.loadContentBody[id] +=r;
                    postbox.new.loadContentCounter --;

                    if(postbox.new.loadContentCounter==0){

                        $mea.log( postbox.new.loadContentBody);

                        $mea.messages.indicator(0);

                        postbox.new.loadContentBody = postbox.new.loadContentBody.join();

                        $mea.log( postbox.new.loadContentBody);
                        CKEDITOR.instances["PostBoxMessage_content"].insertHtml(postbox.new.loadContentBody);

                        postbox.new.loadContentBody ='';
                    }

                });;

            });



        }
    },

    container: function(){

        if($('.postbox_reader').length!=0) {
            return;
        }

        var html ='<div class="postbox_reader">' +
            '<div id="pb_read_menu"></div>' +
            '<div id="oneMessage"></div>' +
            '<div id="messagesList" data-url=""  class="panel panel-default"></div>' +
            '</div>';

        $('#meaContent').html(html);

        // $mea.ajax.load(
        //     Routing.generate('postbox_reader_menu',{
        //         'urltarget':'pb_read_menu'
        //     })
        // );

    },

    activateMenu: function(){
        $('#pb_read_menu a').click(function(e){
            $('#pb_read_menu a.active').removeClass('active');
            $(this).addClass('active');
            $mea.ajax.locationChange($(this).attr('href'));
            $('#messagesList').show();
            $('#oneMessage').html(null);
            e.preventDefault();
            return false;
        });
    }

};
    
window.postbox = postbox;
