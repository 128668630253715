//SONATA PAGE add to app if is used
// import 'jquery.scrollto';
// import 'jquery-ui/ui/widgets/draggable';
// import 'jquery-ui/ui/widgets/droppable';
// import './../../../public/bundles/meacore/sonata/sonata-page.back.js';
//SONATA PAGE

import 'jquery.cookie';
import 'bootstrap-colorpicker';
import 'jquery-form';
import 'jquery-ui/ui/widget';
import "uniform.js";


import moment from 'moment';
window.moment = moment;
if($('html').attr('lang'))
    moment.locale($('html').attr('lang'))

// import './../../../../../public/bundles/sonataadmin/jquery/jquery.confirmExit';
// import 'mea-core-js/dist/sonata/bootstrap3-editable/js/bootstrap-editable.min.js';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/js/i18n/pl';
import './../../../../../public/bundles/sonataadmin/vendor/iCheck/icheck.min';
import './../../../../../public/bundles/sonataadmin/vendor/slimScroll/jquery.slimscroll.min';
import './../../../../../public/bundles/sonataadmin/vendor/waypoints/lib/jquery.waypoints.min';
import './../../../../../public/bundles/sonataadmin/vendor/waypoints/lib/shortcuts/sticky.min';
import './../../../../../public/bundles/sonataadmin/vendor/waypoints/lib/shortcuts/infinite.min';
// import './../../../../../public/bundles/sonataadmin/vendor/admin-lte/dist/js/app.min';
import './../../../../Mea/CoreBundle/Resources/public/jslib/lteadmin2/app.js';
import './../../../../Mea/CoreBundle/Resources/public/sonata/SonataAdmin';
// import './../../../../../public/bundles/sonataadmin/Admin';
import './../../../../../public/bundles/sonataadmin/treeview';
import './../../../../../public/bundles/sonataadmin/sidebar';

import "./../../../../Mea/CoreBundle/Resources/public/sonata/mea.sonata.admin";
import "./../../../../Mea/CoreBundle/Resources/public/js2/mea.pack";
import "./../../../../Mea/CoreBundle/Resources/public/js2/relases_sonata_mdb";
import "./../../../../Mea/CoreBundle/Resources/public/js2/relases_mdb";

import "./../../../../Mea/GalleryBundle/Resources/webpack/js/uploadDNDWidget";


$(() => {

    $mea.sonataAdmin.init();

    $mea.messages.html_ok = '<div class="modal-content"><div class="modal-header"><div class="icon-box btn-success"><i class="fa fas fa-check"></i></div><h5 class="modal-title font-weight-bold fw-bold" data-mea-type="translator">Success</h5></div><div class="modal-body"><p class="text-center text-break" data-mea-type="translator">Your action has been successfully completed.</p></div><div class="modal-footer"><button onclick="{close}" class="btn btn-success btn-block" data-dismiss="modal">OK</button></div></div>';
    $mea.messages.message_html = '<div {uuid} class="modal-content"><div class="modal-header"><div class="icon-box btn-primary"><i class="fa fas fa-info"></i></div><h5 class="modal-title font-weight-bold fw-bold" data-mea-type="translator">Information</h5></div><div class="modal-body"><p class="text-center text-break">{mess}</p></div><div class="modal-footer"><button class="btn btn-primary btn-block"  onclick="{close}" data-dismiss="modal">OK</button></div></div>';
    $mea.messages.error_html = '<div class="modal-content"><div class="modal-header"><div class="icon-box btn-danger"><i class="fa fas fa-exclamation"></i></div><h5 class="modal-title font-weight-bold fw-bold" data-mea-type="translator">Error</h5></div><div class="modal-body"><p class="text-center text-break">{mess}</p></div><div class="modal-footer"><button class="btn btn-danger btn-block"  onclick="{close}" data-dismiss="modal">OK</button></div></div>';
    $mea.messages.indicator_html = '<div id="meaindicator" class="position-fixed meaindicator mainIndicator" style="top:0;left:50%; z-index: 1040;"><div class="sk-cube-grid"><div class="sk-cube sk-cube1"></div><div class="sk-cube sk-cube2"></div><div class="sk-cube sk-cube3"></div><div class="sk-cube sk-cube4"></div><div class="sk-cube sk-cube5"></div><div class="sk-cube sk-cube6"></div><div class="sk-cube sk-cube7"></div><div class="sk-cube sk-cube8"></div><div class="sk-cube sk-cube9"></div></div></div>';
// $mea.ajax.dialogTitleSelector = '.panel-heading:first';
    $mea.messages.message_time = 5000;
    $mea.dialog.defaults.engine = 'bs';
    $mea.ajax.dialogTitleSelector = '.panel-heading.card-header';

    $mea.dialog.defaults.footerSelector = '.form-actions';
    // $mea.dialog.parseFooter = ($dialog,params)=>{
    //     return $dialog.find('.form-actions');
    // };

    $(window).on('beforeunload', function () {
        $mea.messages.indicator(1);
    });

    // $(".button-collapse").sideNav({breakpoint: 500});

    if (typeof $.cookie('menuState') == 'undefined') {
        $.cookie('menuState', 'show')
    }

    $('#toggle').data('state', $.cookie('menuState'));

    $('#toggle').on('click', function () {
        let state = $(this).data('state');
        $(this).data('state', state === 'hide' ? 'show' : 'hide');
        $.cookie('menuState', state === 'hide' ? 'show' : 'hide');
    });

    if ($.cookie('menuState') === 'hide' && $('#toggle').data('state') === 'hide'){
        $('#toggle').trigger('click');
    }

    // let sideNavScrollbar = document.querySelector('.custom-scrollbar');
    // let ps = new PerfectScrollbar(sideNavScrollbar);

    // $('select.mdb-select').materialSelect();
    // $('.select2').select2();


    //from old
    $('*[required="required"], label.required').attr('title','Required');

    if ($('li').hasClass('current')) {
        $('.collapsible-accordion > li.current_ancestor').addClass('active');
        $('.collapsible-accordion > li:not(.current_ancestor)').removeClass('active');
    }

    //TODO: TO chyba jest juz nie potrzebne
    // $(".custom-file input").on("change", function() {
    //     var fileName = $(this).val().split("\\").pop();
    //     $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    // });

    // $('input[type!="submit"], textarea').each(function (i, element) {
    //     if ((element.value !== undefined && element.value.length > 0) || $(this).attr('placeholder') !== undefined || $(element).attr('type') === 'datetime-local') {
    //         $(this).siblings('label').addClass('active');
    //     }
    //     else {
    //         $(this).siblings('label').removeClass('active');
    //     }
    // });
    $(".dropdown.notifications-menu").addClass("nav-item");
    $(".dropdown.notifications-menu > a").addClass("nav-link");

    $(".mosaicThumbVideo").removeAttr("controls");
    $(".mosaicThumbVideo").hover(function(event) {
        if(event.type === "mouseenter") {
            $(this).attr("controls", "");
        } else if(event.type === "mouseleave") {
            $(this).removeAttr("controls");
        }
    });
    // $('.mosaicThumbVideo').hover(function toggleControls() {
    //     if (this.hasAttribute("controls")) {
    //         this.removeAttribute("controls")
    //     } else {
    //         this.setAttribute("controls", "controls")
    //     }
    // });

    setTimeout(function() {
        let $input=$('input:-webkit-autofill');
        $input.next("label").addClass('active');
    }, 200);

    // $mea.ajax.activateContent('body');
});
