/**
 * Add to varsJs.js.twig
 *
let lib = "{{   mea.router.generate('bazinga_jstranslation_js', {'_format': 'json', 'domain': 'messages', 'locales': app.request.locale })|raw }}";
 if(typeof $mea.translator === 'undefined'){
         window.addEventListener('meaTranslatorInit',()=>{
         $mea.translator.loadLibrary( lib);
        });
    }else{
        $mea.translator.loadLibrary( lib);
    }
 *
 */

// require('jquery');
// require('./mea.core');
// require('./mea.ajax');
import MeaMoney from "../../webpack/MeaMoney";
//https://github.com/willdurand/BazingaJsTranslationBundle/blob/master/Resources/doc/index.md
//npm install bazinga-translator --save

window.Translator = require('bazinga-translator');

export default class MeaTranslator {

    /**
     * <span data-mea-type="translator">content for trans</span>
     * @param config
     */
    constructor(config) {

        this.config = {
            path: '',
        };

        this.executeOnLibraryLoaded=[];

        this.libraryLoaded=false;

        this.config = $.extend( this.config,config );

        // console.log('MeaTranslator ',this.config);

        $mea.types.add('translator',(el)=>{

            // console.log('tranlator ',{
            //     'origin': $(el).text(),
            //     'translation': this.trans($(el).text()),
            // });

            let source = $(el).text();
            let translated = this.trans(source);
            if(translated!==source)
            $(el).text(translated);
        });

        const event = new Event('meaTranslatorInit');
        window.dispatchEvent(event);



    }

    /**
     *
     * @returns string example pl
     */
    getLocale(){

        let lang = $('html').attr('lang');

        if(lang)
            return lang;

        else return $mea.translator.converter.locale;

    }

    loadLibrary(url){

        // console.log('loadLobrary',url);

        $.ajax({
            dataType: "json",
            headers: {
                'Cache-Control': 'max-age=86400'
            },
            cache: true,
            url: url,
            error:(response, textStatus , errorThrown)=>{
                console.log('meaTranslator loading library failed '+textStatus,{url ,errorThrown , response });
            },
            success: (response)=>{

                this.converter = Translator.fromJSON(response);

                //console.log('meaTranslator loaded library', this.executeOnLibraryLoaded);

                this.libraryLoaded = true;

                if(this.executeOnLibraryLoaded.length>0){
                    $.each(this.executeOnLibraryLoaded,(k,v)=>{
                        v();
                    });
                    this.executeOnLibraryLoaded=[];
                }

                const event = new Event('meaTranslatorReady');
                window.dispatchEvent(event);

                /**
                 *  window.addEventListener('meaTranslatorReady', () => {
                    $mea.forms.validate.init(this.$form, {});
                });
                 */


                // console.log('MeaTranslator Loaded',this.config);
            }
        });

    }
    /**
     * todo:mea: translate text without translation keys
     * Mass replace text occurences (first) in string , with translate or just replace
     * @example
     $mea.translator.transContent(
     '<div class="pull-right" id="taskDocEditActions">' +
     '%Notify%  <label title="%Notify the worker of the change%">%Worker%<input id="taskDocSendNotificationWorker" title="" type="checkbox"></label>' +
     '<label title="%Notify observers%">%Observers%<input  id="taskDocSendNotificationObservers" title="" type="checkbox"></label>' +
     '<button class="btn btn-success" id="ckedit">%Save%</button>' +
     '</div>',{
            '%Notify%':null,
            '%Notify the worker of the change%':null,
            '%Worker%':null,
            '%Notify observers%':null,
            '%Observers%':null,
            '%Save%':'Zapisz',
        }
     )
     * @param string
     * @param data
     * @returns {*}
     */
    transContent(string,data){

        $.each(data,(k,v)=>{
            if(v === null){
                let translateKey = k.replace(/\%/g,'');
                string = string.replace(k,this.trans(translateKey));
            }else{
                string = string.replace(k,v);
            }
        });

        return string;
    }

    trans(string,params){
        if(!string) return '';
        if(!this.converter){
            console.log('mea.translator converter empty. text: '+string);
            return string;
        }
        return this.converter.trans(string,params);
    }

    decodeHtmlEntities(value){
        let txt = document.createElement("textarea");
        txt.innerHTML = value;
        return txt.value;
    }

    onLoadedLibrary(fn){
        // console.log('add onLoadedLibrary',{
        //     libraryLoaded: this.libraryLoaded
        // });
        if(this.libraryLoaded){
            fn();
        }else{
            this.executeOnLibraryLoaded.push(fn);
        }

    }

    /**
     * @example $mea.translator.transCurrency('USD','symbol') => $
     * @param input
     * @param format
     * @returns {string}
     */
    transCurrency(input,format){

        input = input.toLowerCase();

        switch (format){
            case 'symbol':
                switch (input){
                    case 'usd':
                        return '$';
                        break;
                    case 'gbp':
                        return '£';
                        break;
                    case 'eur':
                        return '€';
                        break;
                    case 'pln':
                        return 'zł';
                        break;
                }
                break;

        }

    }
}

$mea.translator = new MeaTranslator();
// window.MeaTranslator = MeaTranslator;

// $mea.translator = {
//
//     trans: function(string,params){
//
//         if($mea.lang && $mea.lang[string])
//             return $mea.lang[string];
//
//         if(typeof params !== 'undefined' && params.default)
//             return params.default;
//
//         return string;
//     }
//
// };




