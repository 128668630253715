// require('jquery');
// require('./mea.core');

/**
 wait for translations

 $mea.messages.paused = true;
 $mea.translator.onLoadedLibrary(()=>{
    $mea.messages.html_ok = '<div class="modal-content"><div class="modal-header"><div class="icon-box btn-success"><i class="icon-checkmark3"></i></div><h4 class="modal-title">'+$mea.translator.trans('messages.dialog.success_title')+'</h4></div><div class="modal-body"><p class="text-center text-break"></p></div><div class="modal-footer"><button onclick="{close}" class="btn btn-success btn-block" data-dismiss="modal">'+$mea.translator.trans('messages.dialog.ok')+'</button></div></div>';
    $mea.messages.message_html = '<div {uuid} class="modal-content"><div class="modal-header"><div class="icon-box btn-primary"><i class="icon-info3"></i></div><h4 class="modal-title">'+$mea.translator.trans('messages.dialog.info_title')+'</h4></div><div class="modal-body"><p class="text-center text-break">{mess}</p></div><div class="modal-footer"><button class="btn btn-primary btn-block"  onclick="{close}" data-dismiss="modal">'+$mea.translator.trans('messages.dialog.ok')+'</button></div></div>';
    $mea.messages.error_html = '<div {uuid} class="modal-content"><div class="modal-header"><div class="icon-box btn-danger"><i class="fa fa-exclamation"></i></div><h4 class="modal-title">'+$mea.translator.trans('messages.dialog.error_title')+'</h4></div><div class="modal-body"><p class="text-center text-break">{mess}</p></div><div class="modal-footer"><button class="btn btn-danger btn-block"  onclick="{close}" data-dismiss="modal">'+$mea.translator.trans('messages.dialog.ok')+'</button></div></div>';
    $mea.messages.unPause();
});

//Boostrap4
 $mea.messages.indicator_html = '<div id="meaindicator" style="position: fixed; top:50%; left:50%; z-index: 1040; "><i style="text-shadow: 0px 0px 10px #f7f6f6;" class="fa fa-spinner fa-pulse fa-4x"></i></div>';

 //Boostrap3
 $mea.messages.html_ok = '<div class="modal-content"><div class="modal-header"><div class="icon-box btn-success"><i class="icon-checkmark3"></i></div><h4 class="modal-title">Success</h4></div><div class="modal-body"><p class="text-center text-break"></p></div><div class="modal-footer"><button onclick="{close}" class="btn btn-success btn-block" data-dismiss="modal">Zamknij</button></div></div>';
 $mea.messages.message_html = '<div {uuid} class="modal-content"><div class="modal-header"><div class="icon-box btn-primary"><i class="icon-info3"></i></div><h4 class="modal-title">Information</h4></div><div class="modal-body"><p class="text-center text-break">{mess}</p></div><div class="modal-footer"><button class="btn btn-primary btn-block"  onclick="{close}" data-dismiss="modal">Zamknij</button></div></div>';
 $mea.messages.error_html = '<div class="modal-content"><div class="modal-header"><div class="icon-box btn-danger"><i class="fa fa-exclamation"></i></div><h4 class="modal-title">Error</h4></div><div class="modal-body"><p class="text-center text-break">{mess}</p></div><div class="modal-footer"><button class="btn btn-danger btn-block"  onclick="{close}" data-dismiss="modal">Zamknij</button></div></div>';
 $mea.messages.messages_css = 'position: fixed;   right: 20px;   padding: 0 10px;   z-index: 11000;   height: 0px;   top: 100px;   width: 200px;';
 $mea.messages.message_css = 'float:left; width:200px;';
 $mea.messages.indicator_html = '<div id="meaindicator"><i class="fa fa-spinner fa-pulse fa-2x"></i></div>';
 $mea.messages.error_time = 6000;
 * @type {{indicator_html: string, indicator: $mea.messages.indicator, error_html: string, message_confirm: string, sound: $mea.messages.sound, show: $mea.messages.show, ok_time: number, toTop: $mea.messages.toTop, error: $mea.messages.error, timeout: $mea.messages.timeout, remove: $mea.messages.remove, message_time: number, cleanUp: $mea.messages.cleanUp, parse_template: (function(*=, *, *): string), html_ok: string, ok: $mea.messages.ok, add: (function(*=, *=): number), messages_html: string, init: $mea.messages.init, error_time: number, debug: boolean, count: number, always_on_top: boolean, soundEmbed: null, confirm: $mea.messages.confirm, message_css: string, message_html: string, hide: $mea.messages.hide, messages_css: string}}
 */

$mea.messages = {
    count : 1,
    paused : false,
    soundEmbed : null ,
    debug: false,
    route_dispatch: 'admin_app_memento_autocorrespondence_dispatch',
    queue: [],
    history: [],
    visible: [],
    messages_css: 'position: fixed;   right: 20px;   padding: 0 10px;   z-index: 11000;   height: auto;   top: 100px;   width: 300px;' ,
    message_css: 'width:100%;',
    messages_html: "<div></div>",
    error_html: '<p {uuid} id="msg'+this.count+'" class="p alert alert-error"><span style="float: left; margin-right: 0.3em;" class="fa fa-exclamation"></span> {mess}</p>',
    message_confirm:  ()=>{
        return '<div class="modal fade" role="dialog"> <div class="modal-dialog modal-dialog-centered" role="document"><div class="modal-content">' +
            '<div class="modal-header"><div class="icon-box btn-primary"><i class="fa fa-info-circle"></i></div>' +
            '<h4 class="modal-title">{title}</h4></div>' +
            '<div class="modal-body"><p class="text-center">{mess}</p></div>' +
            '<div class="modal-footer">' +
            '<button class="mea-confirm-btn btn btn-success btn-block"  data-dismiss="modal">{labelConfirm}</button>' +
            '<button class="mea-cancel-btn btn btn-danger btn-block"  onclick="{cancel}" data-dismiss="modal">{labelCancel}</button>' +
            '</div></div></div></div>';
    },
    message_html: '<p {uuid} id="msg'+this.count+'" ><span style="float: left; margin-right: 0.3em;" class="fa fa-info"></span>{mess}' +
        '<a style="float: right" onClick="{close}"><i class="fa fa-times" ></i></a>' +
        '</p>',
    indicator_html: '<div id="meaindicator"> <i class="fa-3x fa fas fa-spinner fa-spin"></i></div>',
    html_ok: '<div style="display: none; position: fixed; z-index: 1000000; top:80px; right: 80px" id="mea-ok">' +
        '<button onclick="{close}" type="button" class="btn-rounded btn-info btn-notify"><i class="fa fa-check fa-2x"></i></button>' +
        '</div>',
    ok_time: 2250,
    error_time: 0,
    message_time: 2250,
    always_on_top: true,

    add: function(mes,time,params){
        this.show(mes,time,params);
        return this.count;
    },

    error:function(mes,time){

        this.addToHistory('error',mes);

        if(this.paused)
            return this.addToQueue('error',{
                mes,time
            });

        if(!mes) mes = '';

        if(this.isVisible(mes) === true){
            console.log('message visible');
            return;
        }

        this.init();

        this.count ++;

        this.addToVisible(mes);

        if(typeof $mea.translator !== 'undefined')
            mes = $mea.translator.trans(mes);

        $('#messages').prepend(
            this.parse_template(mes,'error')
        );

        //children(0) - if modal show
        $('#mea-message-'+this.count).children(0).show();

        if(typeof time == 'undefined')
            time = this.error_time;

        $mea.log({
            type: 'ERROR',
            message: mes,
            time: time,
            count: this.count
        });

        if(time)
            this.timeout(time,this.count, mes);

        $('#messages').show();
    },

    ok: function(time){

        if(this.paused)
            return this.addToQueue('ok',{
                time
            });

        $mea.messages.init();

        this.count ++;

        if($('#messages .meaMessage.ok').length>0){
            $('#messages .meaMessage.ok').remove();
        }

        let content =  $(this.parse_template('','ok'));
        $mea.ajax.activateContent(content);

        $('#messages').prepend(
            content
        );



        if(isNaN(time) && $mea.messages.ok_time > 0) {
            time = $mea.messages.ok_time;
        }

        if(parseInt(time) > 0){
            this.timeCounter($('#messages .meaMessage.ok'),time);
            this.timeout(time,this.count,'ok');
        }



    },

    indicator: function(state)  {

        $mea.messages.init();

        if(parseInt(state)===1){

            if($('#meaindicator').length===0)
                $('body').append(this.indicator_html);
            else $('#meaindicator').show();

        }else{
            $('#meaindicator').hide();

        }

    },

    //config: { title: '', labelConfirm: '', labelCancel: '' }
    /**
     *  await $mea.messages.confirm('aaa?').then((confirmed)=>{
     *                 console.log('confirmed',confirmed); }
     * todo: onClose without decision stop
     * @param mes
     * @param confirmAction
     * @param cancelAction
     * @param config
     * @returns {Promise<unknown>}
     */
    confirm: async function(mes,confirmAction,cancelAction, config = {}){

        // console.log('config',{
        //     mes,confirmAction,cancelAction, config
        // });

        if(!mes){
            mes = '';
        }else{
            mes = $mea.translator.trans(mes);
        }

        this.addToHistory('confirm','mes');
        if(this.paused)
            return this.addToQueue('confirm',{
                mes,confirmAction,cancelAction, config
            });

        if($mea.messages.debug) $mea.log(mes);

        $mea.messages.init();

        this.count ++;

        $('body').append(
            $(this.parse_template(mes,'confirm',{
                confirm: confirmAction,
                cancel: cancelAction,
                text: config
            }))
        );


        $('#mea-message-'+this.count+' .modal').modal('show');

        //children(0) - if modal show
        // $('#mea-message-'+this.count).children(0).show();


        let out;

        let hasDecision = false;

        if(confirmAction){

            let id= this.count;
            $('#mea-message-'+this.count).find('.mea-confirm-btn').click(()=>{
                $mea.messages.hide(id);
                confirmAction();
                out = true;
                hasDecision = true;
            });
        }else{

            $('#mea-message-'+this.count).find('.mea-confirm-btn').click(()=>{
                out = true;
                hasDecision = true;
            });
        }

        if(cancelAction){

            $('#mea-message-'+this.count).find('.mea-cancel-btn').click(()=>{
                cancelAction();
                out = false;
                hasDecision = true;
            });

        }else{
            $('#mea-message-'+this.count).find('.mea-cancel-btn').click(()=>{
                out = false;
                hasDecision = true;
            });
        }


        let waitForDecistion = (resolve)=>{
            setTimeout(()=> {
                if(hasDecision){
                    // console.log('has  decusion');
                    resolve(out );
                }else{
                    // console.log('wait for decusion');
                    waitForDecistion(resolve);
                }

            }, 500);
        }

        return new Promise((resolve)=> {
            waitForDecistion(resolve);
        });
        return out;

    },

    //Internal

    init: function(){

        if($('#messages').length===0)$('body').append(
            $(this.messages_html)
                .attr('id','messages')
                .attr('style',this.messages_css)
        );

    },

    unPause: function(){
        this.paused = false;

        if(this.queue.length>0){
            $(this.queue).each((k,v)=>{
                let p =  Object.keys(v.params).map((key) => v.params[key]);
                let method = 'this.'+v.type;

                //not work
                // eval(method + '(' + (...p) + ')');
                // this.add(...p); - not work
                let type =  v.type;
                v = v.params;
                switch (type){
                    case 'show':
                    case 'add':
                        this.add(v.mes, v.time);
                        break;
                    case 'error':
                        this.error(v.mes, v.time);
                        break;
                    case 'confirm':
                        this.confirm(v.mes,v.confirmAction,v.cancelAction, v.config);
                        break;
                    case 'ok':
                        this.ok(v.time);
                        break;
                    default:
                        console.log('meaMessages Unpause unknown ',type, v);
                        break;
                }

            });

             this.queue = [];
        }

    },

    addToQueue: function(type,params){
        // console.log('messages addToQueue');
        this.queue.push({
            type,params
        });
    },

    addToVisible: function(message){
        this.visible.push({
            id: this.count,
            content: message
        });
    },
    removeFromVisible: function(countNum){
        countNum = parseInt(countNum);
        $.each(this.visible, (k,item)=>{
            if( typeof item != 'undefined' && countNum === item.id){
                this.visible.splice(k, 1);
            }
        });
    },

    isVisible: function (mes){
        let visible = false;
        $.each(this.visible, (k,item)=>{
            if(item && mes === item.content) {
                visible = true;
            }
        });

        return visible;
    },

    addToHistory: function(type,message){

        if(this.history.length > 10) {
            let removed = this.history.shift();
        }

        this.history.push({
            type,message
        });
    },

    cleanUp: function(){

        if( $('#messages .meaMessage').length ===0)
            $('#messages').remove();

        $('.modal-backdrop').remove();

    },

    parse_template: function(mess, type, config){

        var template;
        switch (type){
            case 'ok':
                if(typeof this.html_ok === 'function') template = this.html_ok();
                else template = this.html_ok;
                break;
            case 'error':
                if(typeof this.error_html === 'function') template = this.error_html();
                else template = this.error_html;
                break;
            case 'info':
                if(typeof this.message_html === 'function') template = this.message_html();
                else template = this.message_html;
                break;
            case 'confirm':
                if(typeof this.message_confirm === 'function') template = this.message_confirm();
                else template = this.message_confirm;
                template = template.replace('{cancel}','$mea.messages.hide(\''+this.count+'\');');
                // template = template.replace('{confirm}','$mea.messages.hide(\''+this.count+'\');');

                let title = config.text.title === undefined ? $mea.translator.trans('action_please_confirm') : config.text.title;
                let labelConfirm = config.text.labelConfirm === undefined ? $mea.translator.trans('iConfirm') : config.text.labelConfirm;
                let labelCancel = config.text.labelCancel === undefined ? $mea.translator.trans('Cancel') : config.text.labelCancel;

                template = template.replace('{title}', title);
                template = template.replace('{labelConfirm}', labelConfirm);
                template = template.replace('{labelCancel}', labelCancel);

                //template = template.replace('{mess}',mess);
                //return '<div class="meaMessage '+type+'" id="mea-message-'+this.count+'">'+template+'</div>';
                break;

        }

        if(config && config.uuid){
            template = template.replace('{uuid}','data-uuid="'+config.uuid+'"');
        }
        template = template.replace('{mess}',mess);
        template = template.replace('{close}','$mea.messages.hide(\''+this.count+'\',true);');

        if(this.always_on_top)
            this.toTop();

        return '<div style="'+this.message_css+'" class="meaMessage '+type+'" id="mea-message-'+this.count+'">'+template+'</div>';
    },

    toTop: function(){

        var maxZ = 0;
        $('*').each(function ()
        {
            var thisZ = $(this).css('zIndex');
            thisZ = (thisZ === 'auto' ? (Number(maxZ) + 1) : thisZ);
            if (thisZ > maxZ) maxZ = thisZ;
        });

        $('#messages').css('z-index',maxZ+1);

    },

    snackbar: function(text){
        if(!text)
            this.ok(1000);
        else
            this.add(text,1000);
    },


    show: function(mes,time,params){

        if(this.paused)
            return this.addToQueue('show',{
                mes,time
            });

        if(this.isVisible(mes) === true){
            console.log('message visible, ignore showing');
            return;
        }

        this.addToHistory('add',mes);

        this.count ++;

        this.addToVisible(mes);

        if($mea.messages.debug) $mea.log(mes);

        $mea.messages.init();


        if(typeof $mea.translator !== 'undefined')
            mes = $mea.translator.trans(mes);

        if(params){
            if(params.encode==='htmlentities'){
                mes = $mea.translator.decodeHtmlEntities(mes);
            }
        }

        let content =  $(this.parse_template(mes,'info',params));
        $mea.ajax.activateContent(content);

        $('#messages').prepend(
            content
        );

        //children(0) - if modal show
        $('#mea-message-'+this.count).children(0).show();


        if(isNaN(time) && $mea.messages.message_time >0) {
            time = $mea.messages.message_time;
        }

        if(parseInt(time) > 0){
            this.timeCounter($('#mea-message-'+this.count),time);
        }


        $('#messages').show();

    },

    timeCounter: function ($el,time){

        let $okBtn = $el.find('.modal-footer').find('[data-dismiss="modal"]');

        let timeleft = time/1000;

        if($okBtn.length ===1){
            let okTimer = setInterval(()=>{

                if(timeleft <= 0){
                    clearInterval(okTimer);
                }
                if($okBtn.find('.countDown').length === 0){
                    $okBtn.html($okBtn.html()+' (<span class="countDown">'+timeleft.toFixed(0)+'</span>)');
                }else{
                    $okBtn.find('.countDown').html(timeleft.toFixed(0));
                }
                timeleft -= 1;
            }, 1000);
        }
        this.timeout(time,this.count);

    },

    sound : function(){

        which = '/media/sounds/info.wav';

        if (!this.soundEmbed)
        {
            this.soundEmbed = document.createElement("embed");
            this.soundEmbed.setAttribute("src", ""+which+"");
            this.soundEmbed.setAttribute("hidden", false);
            this.soundEmbed.setAttribute("autostart", true);
            this.soundEmbed.setAttribute("width", 1);
            this.soundEmbed.setAttribute("height", 1);
        }
        else
        {
            document.body.removeChild(this.soundEmbed);
            this.soundEmbed.removed = true;
            this.soundEmbed = null;
            this.soundEmbed = document.createElement("embed");
            this.soundEmbed.setAttribute("src", ""+which+"");
            this.soundEmbed.setAttribute("hidden", false);
            this.soundEmbed.setAttribute("autostart", true);
            this.soundEmbed.setAttribute("width", 1);
            this.soundEmbed.setAttribute("height", 1);
        }
        this.soundEmbed.removed = false;
        document.body.appendChild(this.soundEmbed);


    },

    timeout:function(time,countNum){

        if(!time)setTimeout('$mea.messages.hide('+countNum+')',5250);
        else{
            if(time!='stay')
                setTimeout('$mea.messages.hide('+countNum+')',time);
        }
    },

    remove: function(countNum){
        this.hide(countNum);
    },

    hide: function(countNum, confirmRead, message){

        let $msg = $('#mea-message-'+countNum);

        this.removeFromVisible(countNum);

        if(confirmRead===true){
            let uuid = $msg.find('[data-uuid]').data('uuid');
            if(uuid){
                try{
                    let href = Routing.generate(this.route_dispatch,{
                        action: 'messageWebRead',
                        channel: 'msg_web',
                        recipient: $mea.user.config.id,
                        uuid: uuid,
                    });
                    $mea.ajax.run(href,{
                        disableIndicator: true
                    });
                    console.log('confirmRead',$msg.find('[data-uuid]').data('uuid'));
                }catch (e) {
                    console.log('uuid message read uuid rote fail ',this.route_dispatch)
                }
            }
        }

        $msg.slideUp( ()=> {
            if($msg.find('.modal').length>0){

                $msg.find('.modal').modal('hide');
            }else{
                $(this).remove();
                $mea.messages.cleanUp();
            }

        });
    },

    readedInOtherWindow(uuid){

        let el =  $('[data-uuid='+uuid+']');
        if(el.length > 0){
            let id = $(el).closest('.meaMessage').attr('id').replace('mea-message-','');
            this.hide(id);
        }

    }


};

