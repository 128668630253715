import "./relases_sonata";
$mea.ajax.removeRelase('select2Snt');

//xeditable in sonata list
$mea.ajax.registerRelase('x-editable','.x-editable',($el)=>{
    if($el.closest('[data-mea-type="sonata_list"]').length>0) return;
    try{
        $el.editable();
    }catch (e) {
        console.log('editable exception',e);
    }
});

$mea.ajax.registerRelase('sonata_admin_dialog_form_submit_mdb','.meaDialog .form-actions button[type=submit]',function(el){


    $(el).click(function(e){

        //allow override action - example in dialog model list type - edit_many_script
        if($(el).hasClass('noHpl')){
            console.log('meaFormDialog ignore because .noHpl');
            return ;
        }

        e.preventDefault();

        let dialog = $(this).closest('.meaDialog');
        let form = new MeaForm($(this).closest('form'));
        form.$form.on($mea.forms.events.response_success,(e)=>{
            // $mea.dialog.createFromString(e.mea.blocks.meaContent);
            console.log('$mea.forms.events.response_success');
            $mea.dialog.remove(dialog);
        });
        form.submit({
            initator: 'sonata_admin_dialog_form_submit_mdb',
            url: $mea.ajax.urlAdd(form.getAction(),'urltarget=dialog')
        });

        return false;
    });

});

$mea.ajax.registerRelase('sonata_admin_dialog_form_delete','.meaDialog .form-actions a.btn.btn-danger',function(el){

    $(el).addClass('noHpl');
    $(el).click(function(e){

        e.preventDefault();

        if($mea.messages.confirm("",function(){

            let url =  $mea.ajax.urlAdd(
                $(el).attr('href'),'ajaxConfirmed=1'
            );
            console.log('url',url);
            $mea.ajax.run(
                url
            );

            $mea.dialog.remove($(el).closest('.meaDialog'));

            console.log("deleted",url);
        }));

        return false;

    });

});
