import "./releases_main.js";
import "./relases_hpl.js";
// $mea.ajax.registerRelase('select2mdb', 'select:not(.noselect2)', (el, selector) => {
//     $(el).materialSelect();
// });

jQuery.fn.extend({
    autoHeight: function () {
        function autoHeight_(element) {
            return jQuery(element)
                .css({ 'height': 'auto', 'overflow-y': 'hidden' })
                .height(element.scrollHeight);
        }
        return this.each(function() {
            autoHeight_(this).on('input', function() {
                autoHeight_(this);
            });
        });
    }
});

$('textarea.md-textarea').autoHeight();

$mea.ajax.registerRelase('checkbox2mdb', 'input[type=checkbox]', (el, selector) => {
    $(el).addClass('form-check-input');
    $(el).closest('.form-check').find('label').addClass('form-check-label');
});

/**
 * was before select.mdb-select
 */
$mea.ajax.registerRelase('select2mdb', 'select:not(.select2):not(.mea-manual-select):not([data-type="autocomplete"])', (el, selector) => {
    // if (!$(el).prop('multiple')) {
    //     $(el).materialSelect();
    // }else {
    //     $(el).select2();
    // }

    //if need use when translator loaded
    // $(el).attr('data-label-no-search-results', $mea.translator.trans('mdb.select.no.results'));
    // $(el).attr('data-label-select-all', $mea.translator.trans('mdb.select.select.all'));
    

    //filter dont work as mdb
    if (el.closest('.sonata-filter-form').length > 0) {
        el.select2({
            // width: 'resolve',
            dropdownAutoWidth : true
        });
        return;
    }

    if (el.hasClass('select-initialized')) {
        return;
    }

    if(!$(el).val() && $(el).data('default')){
        // $(el).val($(el).data('default'));
        $(el).find('option[value="'+$(el).data('default')+'"]').attr('selected','selected');
        // $(el).closest('.form_row').find()
        //console.log('set def',$(el).data('default'), $(el).val() ,  $(el).find('option[value="'+$(el).data('default')+'"]'));
    }

    if (typeof $(el).material_select === "function") {
        $(el).material_select();
        $(el).hide();
    }else if (typeof $(el).materialSelect === "function") {
        $(el).materialSelect((e)=>{
            //callback not work
            // console.log('materialSelect callback',e);
            //fix show input text
            // $(el).closest('.form-control').find('[type="text"].select-dropdown').hide();
        });
        $(el).hide();
    }else{
        console.log('material_select and materialSelect is not function');
    }



    //stop work in contract ?
    // $(el).materialSelect();

    //fixed label in select multiple
    // if ($(el).prop('multiple')){
    //     $(el).closest('.select-wrapper').find('.filtrable label').each((i, e)=>{
    //         $(e).append(e.nextSibling.nodeValue);
    //         e.nextSibling.remove();
    //     });
    // }


});

$('body').on('shown.bs.modal', '.modal', e => {

    $mea.ajax.activateContent($(e.currentTarget));

    $(e.currentTarget).find('input[type!="submit"], textarea').each((i, element) => {
        if ((element.value !== undefined && element.value.length > 0) || $(element).attr('placeholder') !== undefined || $(element).attr('type') === 'datetime-local') {
            $(element).siblings('label').addClass('active');
        }
        else {
            $(element).siblings('label').removeClass('active');
        }
    });

    // $('select.mdb-select').materialSelect();
});


$(document).ajaxComplete(function( event, xhr, settings ) {
    $('input[type!="submit"], textarea').each((i, element) => {
        if ((element.value !== undefined && element.value.length > 0) || $(element).attr('placeholder') !== undefined || $(element).attr('type') === 'datetime-local') {
            if (!$(element).siblings('label').hasClass('active'))
                $(element).siblings('label').addClass('active');
        }
        else {
            $(element).siblings('label').removeClass('active');
        }

        setTimeout(function() {
            let $input=$('input:-webkit-autofill');
            $input.next("label").addClass('active');

        }, 200);
    });
});

// $mea.ajax.registerRelase('activeLabel', 'input[type!="submit"], textarea', (el, selector) => {
//     $(el).each((i, element) => {
//         if ((element.value !== undefined && element.value.length > 0) || $(element).attr('placeholder') !== undefined || $(element).attr('type') === 'datetime-local') {
//             $(element).siblings('label').addClass('active');
//         }
//         else {
//             $(element).siblings('label').removeClass('active');
//         }
//     });
// });
