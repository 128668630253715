// require('bootstrap');
// require('popover');
// require('bootstrap-editable');

$mea.editable = {

    register: function(){

        $mea.ajax.registerRelase('editableMeaCore2', '.editable:not(.codex-editor .editable)', function (el, selector) {
            $mea.editable.init(el);
        });
    },

    init: function(el){


        var id = $(el).attr('id');
        if(!id){
            id = Math.random().toString(36).replace(/[^a-z]+/g, '');
            el.attr('id',id);
        }

        var saveUrl = $(el).attr('data-editable-url');
        var type = $(el).attr('data-editable-type');
        var data = $(el).attr('data-editable-data');
        var loadurl = $(el).attr('data-editable-loadurl');
        var placeholder = $(el).attr('data-editable-placeholder');
        if(!placeholder) placeholder = 'Edit';
        if(!type)  type='text';

        var submitdata = $(el).attr('data-editable-submitdata');
        var getParams;

        if (submitdata) {
            getParams= function params(params){

                if(submitdata){

                    var extradata = eval(submitdata+'()');

                    $.extend(true,params,extradata);
                }

                return params;
            };
        }else{
            getParams= function params(params){
                return params;
            };
        }


        // console.log('mea editable ',type, typeof $('.editable').editable); return ;

        switch (type) {
            case 'datepicker':
                type='datetime';
                break;
            case 'ckeditor':
                // console.log('mea ckeditor ',el);


                $(el).attr('data-controller','editor-quill');
                $(el).attr('data-editor-quill-theme-value','bubble');
                $(el).attr('data-editor-quill-save-url-value',saveUrl);
                $(el).attr('data-editor-quill-media-upload-url-value',$(el).attr('data-media-upload-url'));

                // let oldCk = CKEDITOR.instances[id];
                // if(oldCk){
                //     CKEDITOR.instances[id].destroy();
                //     CKEDITOR.remove(oldCk);
                // }
                //
                // // $(el).after('<p>MeaEditable</p>');
                // //ajax remove elements
                // if($(el).length===0 || $('#'+id).length===0){
                //     console.log('no editable element');
                //     return ;
                // }else{
                //    // console.log('ckEditable element',$(el),id, el);
                // }
                //
                // $(el).attr('contenteditable',true);
                // // .addEventListener("input", function() {
                // //     console.log("input event fired");
                // // });
                // CKEDITOR.disableAutoInline = true;
                // let ckOptions = {
                //     language: $mea.config.locale ,
                //     extraPlugins: "openlink,autosave,autolink,basewidget,templates,youtube,widget,autogrow",
                //     toolbarGroups: [
                //         {"name":"basicstyles","groups":["basicstyles"]},
                //         {"name":"links","groups":["links"]},
                //         {"name":"paragraph","groups":["list",]},
                //         // {"name":"basicstyles","groups":["basicstyles"]},
                //         // {"name":"links","groups":["links"]},
                //         // {"name":"paragraph","groups":["list","blocks"]},
                //         {"name":"document","groups":["mode"]},
                //         {"name":"insert","groups":["insert"]},
                //         {"name":"styles","groups":["list","indent"]},
                //         // {"name":"styles","groups":["styles","colors"]},
                //         // //{ name: 'colors',"groups":["colors"] },
                //         // {"name":"about","groups":["about"]}
                //     ],
                //     // Remove the redundant buttons from toolbar groups defined above.
                //     //removeButtons: 'Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar'
                //     removeButtons: 'Flash,Font,Anchor,Div'
                // };
                //
                //
                // CKEDITOR.inline( id, ckOptions);
                //
                //
                // CKEDITOR.instances[id].on('change', function() {
                //
                //     let ckid = 'ckedit'+id;
                //     // console.log('changed',id);
                //     if($('#'+ckid).length===0){
                //
                //         $('#'+id).after('<button title="Save" class="btn btn-success" id="ckedit'+id+'"><i class="fa fa-paper-plane"></i></button>');
                //
                //         $('#'+ckid).click(function(e){
                //
                //             $mea.ajax.load(
                //                 $('#'+id).attr('data-editable-url'),{
                //                     'value': CKEDITOR.instances[id].getData()
                //                 },{
                //                     onFinish: function(){
                //                         $('#'+ckid).remove();
                //                     }
                //                 }
                //             );
                //
                //             e.preventDefault();
                //             return false;
                //
                //         });
                //
                //     }
                //
                // });
                break;
            case 'select2':

                try{
                    // $.fn.editable.defaults.mode = 'inline';
                    //$.fn.popover.defaults={},$.fn.popover.defaults.template;
                    var source = $(el).attr('data-suggest-url') ;

                    // $mea.log('type2',type,saveUrl, data,source,loadurl,el);

                    if($(el).attr('data-select2-multiple')=='true')
                        var multiple = true;
                    else multiple = false;

                    $(el).editable({
                        type: 'select2',
                        url:   saveUrl,
                        savenochange: true,
                        send: 'always',
                        tpl: '<select></select>',
                        display: $mea.editable.displayResponse,
                        params: getParams,

                        select2: {
                            placeholder: placeholder,
                            allowClear: true,
                            multiple: multiple,
                            quietMillis: 500,
                            //tags: "true",
                            tpl: '<select style="width:150px;">',
                            type: 'select2',
                            minimumInputLength: 3,

                            ajax: {
                                url: source,
                                dataType: 'json',

                                processResults: function(data) {

                                    return {results: data};
                                }
                            },

                        }
                    })

                    ;

                    if(!saveUrl)
                        $(el).on("shown", function(e) {

                            var editable = $(this).data('editable');
                            if (editable.input.$input) {
                                editable.input.$input.on("change", function(ev) {

                                    $(el).html(ev.currentTarget.textContent);

                                    if($(ev.currentTarget).val()){

                                        var selval = $(ev.currentTarget).val();

                                        $(el).attr('data-selected-value', selval, JSON.stringify(selval));

                                    }

                                });
                            }
                        });

                }catch(e){
                    $mea.log('error',e);
                }

                break;
            case 'date':
                console.log('date',el);

                $.fn.bdatepicker.dates['pl'] = {
                    days: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
                    daysShort: ["Niedz.", "Pon.", "Wt.", "Śr.", "Czw.", "Piąt.", "Sob."],
                    daysMin: ["Ndz.", "Pn.", "Wt.", "Śr.", "Czw.", "Pt.", "Sob."],
                    months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
                    monthsShort: ["Sty.", "Lut.", "Mar.", "Kwi.", "Maj", "Cze.", "Lip.", "Sie.", "Wrz.", "Paź.", "Lis.", "Gru."],
                    today: "Dzisiaj",
                    weekStart: 1,
                    clear: "Wyczyść",
                    format: "dd.mm.yyyy"
                };

                $(el).editable({
                    type:  type,
                    //pk:    1,
                    //format: 'yyyy-mm-dd hh:ii',
                    //viewformat: 'dd/mm/yyyy hh:ii',
                    params: getParams,
                    display: $mea.editable.displayResponse,
                    send: 'always',
                    url:   saveUrl,
                    language: 'pl',
                    datepicker: {
                        weekStart: 1,
                        todayBtn: "linked",
                        language: "pl",
                        todayHighlight: true,

                    },
                    title: placeholder
                });
                break;
            case 'datetime':
                try{
                    $.fn.datetimepicker.DPGlobal = $.fn.bdatepicker.DPGlobal;
                }catch(e){

                }

                $(el).editable({
                    type:  type,
                    //pk:    1,
                    format: 'yyyy-mm-dd hh:ii',
                    viewformat: 'dd/mm/yyyy hh:ii',
                    display: $mea.editable.displayResponse,
                    params: getParams,
                    send: 'always',
                    url:   saveUrl,
                    title: placeholder
                });
                break;
             case 'hour':
                 $(el).editable({
                     type:  'text',
                     display: $mea.editable.displayResponse,
                     send: 'always',
                     url:   saveUrl,
                     params: getParams,
                     title: placeholder
                 });
            break;
            case 'select':
                if(typeof data !== typeof undefined && data !== false) source = data;
                else if(typeof loadurl !== typeof undefined && loadurl !== false) source = loadurl;
                else{
                    type='text';
                    $mea.log('editable select no data', data,loadurl,el);
                }
                // $mea.log('typeSelect',id,type,source,loadurl,el);
                if(source)

                    try{
                        $('#'+id).editable({
                            type:  type,
                            send: 'always',
                            display: $mea.editable.displayResponse,
                            source: source,
                            params: getParams,
                            url:   saveUrl,
                            title: placeholder
                        });
                    }catch(e){
                        $mea.log('error',e);
                    }

                break;

         default:
             try{
                 $(el).editable({
                     type:  type,
                     //pk:    1,
                     send: 'always',
                     url:   saveUrl,
                     params: getParams,
                     display: $mea.editable.displayResponse,
                     title: placeholder
                 });
             }catch(e){
                 $mea.log('captured error for type: '+type,e);
             }

            break;

        }


    },

    displayResponse: function (value, sourceData, response){

        if(value){
            $(this).attr('data-selected-value',value);
        }

        if(response){


            if($mea.ajax.isHplResponse(response)){

                var r = $mea.ajax.parseResponse(response);

                if(r && r.scripts && r.scripts.data.response)
                    $(this).html(r.scripts.data.response);

            }else{
                $(this).html(response);
            }


        }else if(sourceData){
            // $mea.log('r sourceData');

            if(typeof sourceData =="string"){

                if($mea.ajax.isHplResponse(response)){

                    var r = $mea.ajax.parseResponse(sourceData);

                    if(r && r.scripts && r.scripts.data.response)
                        $(this).html(r.scripts.data.response);
                }else{

                    $(this).html(sourceData);

                }

            }else{

                var text =false;
                $.each(sourceData,function(k,v){

                    if(parseInt(v.value)==parseInt(value)) text = v.text;
                });

                $(this).html(text);
            }

        }

    }

};


